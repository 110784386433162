<template>
  <v-card>
    <v-expansion-panels
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="v-card__subtitle font-weight-bold">
          <div class="d-flex justify-space-between">
            Allocated Transits
            <div class="d-flex">
              <div class="px-2">
                {{ totalWeight | addCommas }} lbs
              </div>
              <div
                class="px-2"
                v-for="(value, key) in grandTotals"
                :key="key"
              >
                {{ value | formatCurrencyV(key) }}
              </div>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="transits"
          >
            <template v-slot:item.transit_id="{ item }">
              <router-link-id
                name="transit-view"
                :id="item.transit_id"
              />
            </template>
            <template v-slot:item.transit_pickup_date="{ item }">
              {{ item.transit_pickup_date | formatDate }}
            </template>
            <template v-slot:item.weight="{ item }">
              {{ item.weight | addCommas }} lbs
            </template>
            <template v-slot:item.allocation_percent="{ item }">
              {{ Math.round(item.allocation_percent * 100 ) }}%
            </template>
            <template v-slot:item.allocation_value="{ item }">
              <fx-tag
                :fx="item.allocation_value_fx"
              />
            </template>
            <template v-slot:item.fees_value="{ item }">
              <fx-tag
                :fx="item.fees_value_fx"
              />
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="3">
                  Totals
                </td>
                <td class="text-right">
                  {{ totalWeight | addCommas }} lbs
                </td>
                <td />
                <td class="text-right">
                  <div
                    v-for="(value, key) in totalCurrencies"
                    :key="key"
                  >
                    {{ value | formatCurrencyV(key) }}
                  </div>
                </td>
                <td class="text-right">
                  <div
                    v-for="(value, key) in totalFeesCurrencies"
                    :key="key"
                  >
                    {{ value | formatCurrencyV(key) }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    lotId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    transits: [],
    headers: [
      { text: 'Transit', sortable: true, value: 'transit_id' },
      { text: 'Type', sortable: true, value: 'transit_type' },
      { text: 'Pickup Date', sortable: true, value: 'transit_pickup_date' },
      // { text: 'lot_name', sortable: true, value: 'lot_name' },
      { text: 'Weight', sortable: true, value: 'weight', align: 'right' },
      { text: 'Allocated', sortable: true, value: 'allocation_percent', align: 'right' },
      { text: 'Cost Value', sortable: true, value: 'allocation_value', align: 'right' },
      { text: 'Fee Value', sortable: true, value: 'fees_value', align: 'right' }
    ]
  }),
  computed: {
    totalWeight () {
      return this.transits.reduce((c, x) => c + Number(x.weight), 0)
    },
    grandTotals () {
      let accum = {}
      this.transits.forEach(t => {
        if (!accum[t.allocation_denomination]) accum[t.allocation_denomination] = 0
        accum[t.allocation_denomination] += Number(t.allocation_value_fx)
        if (!accum[t.fees_denomination]) accum[t.fees_denomination] = 0
        accum[t.fees_denomination] += Number(t.fees_value_fx)
      })
      return accum
    },
    totalCurrencies () {
      let accum = {}
      this.transits.forEach(t => {
        if (!accum[t.allocation_denomination]) accum[t.allocation_denomination] = 0
        accum[t.allocation_denomination] += Number(t.allocation_value_fx)
      })
      return accum
    },
    totalFeesCurrencies () {
      let accum = {}
      this.transits.forEach(t => {
        if (!accum[t.fees_denomination]) accum[t.fees_denomination] = 0
        accum[t.fees_denomination] += Number(t.fees_value_fx)
      })
      return accum
    }
  },
  mounted () {
    axios.get(`/api/lots/${this.lotId}/transit_allocations/`).then(res => {
      this.transits = res.data
    })
  }
}
</script>
