<template>
  <div>
    <v-card-subtitle class="subtitle-1 pb-0 font-weight-bold">
      Summary of Charges
    </v-card-subtitle>
    <div
      v-for="(lot, index) in shipmentSummaries"
      :key="index"
    >
      <div v-if="shipmentSummaries.length > 1">
        <b class="ml-3 mt-5">{{ `Lot ${lot.internal_lot_id}` }}</b>
        <v-divider class="ma-2" />
      </div>
      <v-row>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Treatment $/LBS"
            no-cols
          >
            <span class="text-no-wrap">
              <fx-tag
                :fx="lot.treatment_charge_per_lb_fx"
                :value="lot.treatment_charge_per_lb"
                :cur="lot.fx_currency"
              />/lb
            </span>
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Treatment Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.treatment_charge_total_fx"
              :value="lot.treatment_charge_total"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Handling Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.small_lot_charge_fx"
              :value="lot.small_lot_charge"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Total Treatment Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.total_treatment_charge_fx"
              :value="lot.total_treatment_charge"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Total Refining Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.total_refining_charge_fx"
              :value="lot.total_refining_charge"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Assay Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.assay_charge_fx"
              :value="lot.assay_charge"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Total Processing Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.total_processing_charge_fx"
              :value="lot.total_processing_charge"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          sm="4"
        >
          <card-list-item
            title="Total Freight Charge"
            no-cols
          >
            <fx-tag
              :fx="lot.freight_charge_fx"
              :value="lot.freight_charge"
              :cur="lot.fx_currency"
            />
          </card-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryOfCharges',
  props: {
    shipmentSummaries: {
      type: Array,
      required: true
    }
  }
}
</script>
