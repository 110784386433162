var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"label":"Show Values","dense":""},model:{value:(_vm.showValues),callback:function ($$v) {_vm.showValues=$$v},expression:"showValues"}}),_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":"Show Date","dense":""},model:{value:(_vm.showCreatedDate),callback:function ($$v) {_vm.showCreatedDate=$$v},expression:"showCreatedDate"}}),_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":"Show Intake","dense":""},model:{value:(_vm.showIntake),callback:function ($$v) {_vm.showIntake=$$v},expression:"showIntake"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headersFiltered,"items":_vm.rows,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.purchase",fn:function(ref){
var item = ref.item;
return [(item.purchase)?_c('router-link-id',{attrs:{"id":item.purchase,"target":"_blank","name":"purchase"}},[_vm._v(" "+_vm._s(item.purchase)+" ")]):_vm._e()]}},{key:"item.check_in",fn:function(ref){
var item = ref.item;
return [(item.check_in)?_c('router-link-id',{attrs:{"id":item.check_in,"target":"_blank","name":"check-in"}},[_vm._v(" "+_vm._s(item.check_in)+" ")]):_vm._e()]}},{key:"item.intake",fn:function(ref){
var item = ref.item;
return [(item.intake)?_c('router-link-id',{attrs:{"id":item.intake,"target":"_blank","name":"intake"}},[_vm._v(" "+_vm._s(item.intake)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.transits",fn:function(ref){
var item = ref.item;
return _vm._l((item.transits),function(id){return _c('div',{key:id},[_c('router-link-id',{attrs:{"id":id,"target":"_blank","name":"transit-view"}},[_vm._v(" "+_vm._s(id)+" ")])],1)})}},{key:"body.append",fn:function(){return [_c('tr',_vm._l((_vm.headersFiltered),function(header){return _c('th',{key:header.value,staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.totalsRow[header.value])+" ")])}),0)]},proxy:true}])}),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c('div',[(!_vm.loading)?_c('v-btn',{attrs:{"color":"primary"}},[_c('download-excel',{attrs:{"data":Object.values(_vm.checkIns),"fields":_vm.jsonFields,"worksheet":"My Worksheet","name":"lot_check_ins.xls"}},[_vm._v(" Download Data ")])],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }