<template>
  <unauthorized
    :authorized="$store.getters.can('create-assay-result')"
  >
    <v-select
      label="Assay Origin"
      v-model="source"
      :items="sourceOptions"
      item-value="id"
      item-text="name"
    />
    <v-select
      label="Assay Status"
      v-model="status"
      :items="statusOptions"
    />
    <v-select
      label="Assay Type"
      v-model="assay_type"
      :items="typeOptions"
    />
    <rules-text
      validation-rules="required|max_value:99999999|decimal:3"
      label="Pt PPM"
      v-model="pt_ppm"
      type="number"
    />
    <rules-text
      validation-rules="required|max_value:99999999|decimal:3"
      label="Pd PPM"
      v-model="pd_ppm"
      type="number"
    />
    <rules-text
      validation-rules="required|max_value:99999999|decimal:3"
      label="Rh PPM"
      v-model="rh_ppm"
      type="number"
    />
    <rules-text
      validation-rules="required|max_value:9999|decimal:3"
      type="number"
      step="any"
      label="Moisture Percentage"
      v-model="percent_moisture"
      hint="enter in the moisture rate as a decimal"
    />
    <rules-text
      validation-rules="required|max_value:9999|decimal:3"
      type="number"
      step="any"
      label="Scrap Percentage"
      v-model="percent_scrap"
      hint="enter in the scrap rate as a decimal"
    />
    <rules-date
      label="Date"
      v-model="date"
      type="date"
    />
  </unauthorized>
</template>

<script>
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import { fetchSources, TYPE } from '@/requests/assayResult'

export default {
  name: 'AssayResultForm',
  components: { RulesDate, RulesText },
  props: {
    assayResult: {
      type: Object,
      default: Object
    }
  },
  data: () => ({
    pt_ppm: 0,
    pd_ppm: 0,
    rh_ppm: 0,
    status: null,
    percent_moisture: 0,
    percent_scrap: 0,
    date: new Date().toISOString().slice(0, 10),
    source: null,
    assay_type: TYPE.XRF.value,
    sourceOptions: [],
    typeOptions: [
      TYPE.XRF,
      TYPE.ICP,
      TYPE.SMT
    ]
  }),
  computed: {
    statusOptions () {
      return this.$store.getters['getAssayResultStatus']
    }
  },
  watch: {
    $data: {
      handler () {
        this.$emit('export', {
          pt_ppm: this.pt_ppm,
          pd_ppm: this.pd_ppm,
          rh_ppm: this.rh_ppm,
          status: this.status,
          percent_moisture: this.percent_moisture,
          percent_scrap: this.percent_scrap,
          date: this.date,
          source: this.source,
          assay_type: this.assay_type
        })
      },
      immediate: true,
      deep: true
    },
    assayResult: {
      handler (assayResult) {
        if (assayResult?.pt_ppm) this.pt_ppm = assayResult.pt_ppm
        if (assayResult?.pd_ppm) this.pd_ppm = assayResult.pd_ppm
        if (assayResult?.rh_ppm) this.rh_ppm = assayResult.rh_ppm
        if (assayResult?.status) this.status = assayResult.status
        if (assayResult?.percent_moisture) this.percent_moisture = assayResult.percent_moisture
        if (assayResult?.percent_scrap) this.percent_scrap = assayResult.percent_scrap
        if (assayResult?.date) this.date = assayResult.date
        if (assayResult?.assay_type) this.assay_type = assayResult.assay_type
        if (assayResult?.source) this.source = assayResult.source
      },
      immediate: true,
      deep: true
    }
  },
  async mounted () {
    this.sourceOptions = await fetchSources()
    if (this.statusOptions.length) this.status = this.statusOptions[0].value
  }
}
</script>
