<template>
  <dynamic-toolbar
    dense
    flat
    :menu-items="menuItems"
  />
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import DynamicToolbar from '@/components/DynamicToolbar.vue'

export default {
  name: 'LotToolbar',
  components: { DynamicToolbar },
  computed: {
    ...mapGetters({
      lot: 'lotStore/lot'
    }),
    menuItems () {
      const that = this
      let menu = []
      menu.push({
        title: 'Shipping Labels',
        disabled: !(this.lot?.inventory?.inventoryline_set.length !== 0),
        action: () => that.$router.push({ name: 'shipping-labels', params: { lot: this.lot } }),
        tooltip: 'Available when lot has inventory lines'
      })
      if (this.$store.getters.can('view-lot-manifests')) {
        if (this.lot.manifest_id) {
          menu.push({
            title: 'Shipping Manifest',
            action: () => that.$router.push({ name: 'lot-manifest', params: { id: this.lot.manifest_id } })
          })
        } else {
          menu.push({
            title: 'New Shipping Manifest',
            action: () => that.$router.push({ name: 'create-lot-manifest', query: { lot: this.lot.id } })
          })
        }
      }
      if (this.$store.getters.can('view-advanced-calculator')) {
        menu.push({
          title: 'Advance Calculator',
          action: () => that.$router.push({ name: 'advance-calculator', params: { lot: this.lot } })
        })
      }
      if (this.$store.getters.can('view-client-proforma')) {
        menu.push({
          title: 'Client Proforma',
          action: () => that.$router.push({ name: 'client-proforma', params: { lot: this.lot } })
        })
      }
      if (!this.lot.final_report) {
        menu.push({
          title: 'Create Final Report',
          disabled: !this.lot.shipment_summary_lot,
          action: this.createFinalReport,
          tooltip: 'Available when Shipment Summary Attached'
        })
      } else {
        menu.push({
          title: 'Final Report',
          action: () => that.$router.push({ name: 'lot-final-report', params: { id: this.lot.final_report.id } }),
          tooltip: 'Available when Shipment Summary Attached'
        })
      }
      return menu
    }
  },
  methods: {
    /*
    * Creates a new the final report with this shipment summary attached
    * Finally redirects to this page with the new shipment summary id
    * */
    async createFinalReport () {
      if (!this.lot.shipment_summary_lot || !this.lot.shipment_summary_lot.shipment_summary) {
        this.$store.commit('setSnackbarError', 'Could not find linked Shipment Summary')
        return
      }
      let payload = {
        shipmentsummary_set: [this.lot.shipment_summary_lot.shipment_summary.url],
        smelter: this.lot.smelter.url,
        pt_return_rate: this.lot.smelter.pt_return_rate,
        pd_return_rate: this.lot.smelter.pd_return_rate,
        rh_return_rate: this.lot.smelter.rh_return_rate
      }
      const response = await axios.post('/api/final_reports/', payload)
      return this.$router.push({ name: 'lot-final-report', params: { id: response.data.id } })
    }
  }
}
</script>
