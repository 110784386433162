<template>
  <v-card>
    <v-card-subtitle>
      Hedging Overview
    </v-card-subtitle>
    <v-simple-table>
      <tbody>
        <tr>
          <td>Est. Return</td>
          <td class="pt-color">
            {{ lot.estimated_returnable_pt | toFixed(3) | appendRtoz | filterNA }}
          </td>
          <td class="pd-color">
            {{ lot.estimated_returnable_pd | toFixed(3) | appendRtoz | filterNA }}
          </td>
          <td class="rh-color">
            {{ lot.estimated_returnable_rh | toFixed(3) | appendRtoz | filterNA }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'WarehouseManagerHedgingOverview',
  props: {
    lot: { type: Object, required: true }
  }
}
</script>
