<template>
  <div>
    <v-stepper
      v-if="lotStepper.length > 1"
      class="elevation-0 pt-2"
      :value="step"
    >
      <v-stepper-header class="text-center">
        <template v-for="(lot, index) in lotStepper">
          <v-stepper-step
            color="success"
            :complete="step > index+1"
            :step="index+1"
            :key="lot.id"
          >
            {{ lot.lotName }}
          </v-stepper-step>
        </template>
      </v-stepper-header>
    </v-stepper>
    <v-window v-model="step">
      <v-window-item
        :value="index+1"
        v-for="(lot, index) in lots"
        :key="lot.id"
      >
        <h3 class="text-center pt-2">
          {{ `Lot ${lot.name}` }}
        </h3>
        <write-summary-lot
          @incStep="incStep"
          :lot="lot"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import WriteSummaryLot from './WriteSummaryLot'

export default {
  name: 'WriteSummaryLots',
  props: {
    lots: { type: Array, required: true },
    deletedLots: { type: Boolean, required: false, default: false }
  },
  data: () => ({ step: 1 }),
  components: { WriteSummaryLot },
  methods: {
    incStep () {
      if (this.lots.length > this.step) {
        this.step++
      } else this.$emit('incStep')
    }
  },
  computed: {
    lotStepper () {
      return this.lots.map(l => { return { id: l.id, lotName: l.name } })
    }
  },
  watch: {
    deletedLots () {
      if (this.deletedLots) this.step = 1
    }
  }
}
</script>
