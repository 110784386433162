<template>
  <div>
    <summary-weights
      :shipment-summaries="shipmentSummaries"
      :shipment-summary-lot="shipmentSummaryLot"
    />
    <contained-assays :shipment-summaries="shipmentSummaries" />
    <contained-metals :shipment-summaries="shipmentSummaries" />
    <returnable-ounces :shipment-summaries="shipmentSummaries" />
    <summary-of-charges :shipment-summaries="shipmentSummaries" />
  </div>
</template>

<script>
import SummaryOfCharges from '@/views/ShipmentSummaries/components/Read/components/SummaryOfCharges.vue'
import ReturnableOunces from '@/views/ShipmentSummaries/components/Read/components/ReturnableOunces.vue'
import ContainedMetals from '@/views/ShipmentSummaries/components/Read/components/ContainedMetals.vue'
import ContainedAssays from '@/views/ShipmentSummaries/components/Read/components/ContainedAssays.vue'
import SummaryWeights from '@/views/ShipmentSummaries/components/Read/components/SummaryWeights.vue'

export default {
  name: 'ReadSummary',
  components: {
    SummaryWeights,
    ContainedAssays,
    ContainedMetals,
    ReturnableOunces,
    SummaryOfCharges
  },
  props: {
    shipmentSummaries: {
      type: Array,
      required: true
    },
    shipmentSummaryLot: {
      type: Object,
      required: true
    }
  }
}
</script>
