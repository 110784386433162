<!--
Note of caution Purchased outright we only save the fields that have a "total"
-->
<template>
  <div>
    <h2>
      Purchased Outright
    </h2>
    <p>
      Values of Zero do not appear on the final settlement.
      The Fields that are filled (gray background) are currently being calculated.
    </p>
    <v-simple-table class="mb-6">
      <thead>
        <tr>
          <th
            v-for="h in headers"
            :key="h"
            :style="'min-width:' + (['Type', 'Mark Paid'].includes(h)) ? 50 : 100 + 'px;'"
            v-text="h"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in purchasedOutright"
          :key="index"
        >
          <td>
            <strong>{{ item.material_type }}</strong>
          </td>
          <td>
            <ValidationProvider
              immediate
              rules="required|max_value:2147483647|min_value:0|decimal:0"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                type="number"
                :value="item.quantity"
                @input="setValue(item.material_type, 'quantity', $event)"
                :error-messages="errors"
              />
            </ValidationProvider>
          </td>
          <td>
            <ValidationProvider
              immediate
              rules="required|max_value:9999999999|min_value:0|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                :class="{ 'dense-filled': computeFromTotal[item.material_type]}"
                type="number"
                :value="item.unit_price"
                @input="setValue(item.material_type, 'unit_price', $event)"
                prepend-icon="mdi-currency-usd"
                :error-messages="errors"
              />
            </ValidationProvider>
          </td>
          <td class="pt-2">
            <ValidationProvider
              immediate
              rules="required|max_value:9999999999|min_value:0|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                :class="{ 'dense-filled': !computeFromTotal[item.material_type]}"
                type="number"
                :value="item.total_price"
                @input="setValue(item.material_type, 'total_price', $event)"
                prepend-icon="mdi-currency-usd"
              />
            </ValidationProvider>
          </td>
          <td class="pb-4 pl-6">
            <v-simple-checkbox
              :disabled="bs.status && bs.status === 'FI'"
              color="primary"
              :value="item.paid"
              @input="setValue(item.material_type, 'paid', $event)"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row>
      <v-col
        cols="12"
        offset-md="8"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            filled
            readonly
            type="number"
            :value="bs.unpaid_units"
            label="Unpaid AM/DPF/Foil Total"
            prepend-icon="mdi-currency-usd"
            :error-messages="errors"
            hint="(readonly) total of unpaid outright purchases"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'

export default {
  name: 'BSOutrightForm',
  props: {
    bs: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    headers: ['Type', 'Quantity', 'Unit Price', 'Total Price', 'Mark Paid'],
    computeFromTotal: {
      AM: false,
      IF: false,
      DF: false,
      DP: false,
      D3: false
    }
  }),
  computed: {
    /**
     * fill lines that do not exist with zeros
     * @returns {[{material_type: string, quantity: number, total_price: number, paid: boolean, unit_price: number},{material_type: string, quantity: number, total_price: number, paid: boolean, unit_price: number},{material_type: string, quantity: number, total_price: number, paid: boolean, unit_price: number},{material_type: string, quantity: number, total_price: number, paid: boolean, unit_price: number},{material_type: string, quantity: number, total_price: number, paid: boolean, unit_price: number}]}
     */
    purchasedOutright () {
      const keyed = {}
      let def = [
        { material_type: 'AM', quantity: 0, unit_price: 0, total_price: 0, paid: true },
        { material_type: 'IF', quantity: 0, unit_price: 0, total_price: 0, paid: true },
        { material_type: 'DF', quantity: 0, unit_price: 0, total_price: 0, paid: true },
        { material_type: 'DP', quantity: 0, unit_price: 0, total_price: 0, paid: true },
        { material_type: 'D3', quantity: 0, unit_price: 0, total_price: 0, paid: true }
      ]
      // copy the source into the defaults
      if (this.bs.purchased_outright && this.bs.purchased_outright.length) {
        this.bs.purchased_outright.forEach(po => { keyed[po.material_type] = po })
      }
      return def.map(po => keyed[po.material_type] ? keyed[po.material_type] : po)
    }
  },
  methods: {
    /**
     * Sets the values on purchased outright
     * Has special logic to re-calculate the totals based on the context of value entered
     * @param materialType
     * @param field
     * @param value
     */
    setValue (materialType, field, value) {
      const clone = [...this.purchasedOutright]
      const i = clone.findIndex(po => po.material_type === materialType)
      if (i === -1) return this.$store.commit('setSnackbarError', 'Field Material Lookup Failed')
      const x = clone[i]

      x[field] = value

      // Switch compute method based of field being set
      if (field === 'total_price') this.computeFromTotal[materialType] = true
      else if (field === 'unit_price') this.computeFromTotal[materialType] = false

      // warn if setting price without quantity
      if (field === 'total_price' && x.quantity === 0) {
        this.$store.commit('setSnackbarWarning', 'Please enter a quantity.')
      }

      // Switch that determines which direction to compute the fields from
      if (!this.computeFromTotal[materialType]) {
        x.total_price = Number(BigNumber(x.unit_price).multipliedBy(x.quantity)).toFixed(2)
      } else {
        x.unit_price = (x.quantity) ? Number(BigNumber(x.total_price).dividedBy(x.quantity)).toFixed(2) : 0
      }
      this.$emit('setProp', ['purchased_outright', clone])
      this.calculateUnpaidUnits(clone)
    },
    /**
     * Calculates total of unpaid units and emits setUnpaidUnits
     */
    calculateUnpaidUnits (purchasedOutright) {
      const total = purchasedOutright
        .filter(p => !p.paid)
        .reduce((n, p) => n + Number(p.total_price), 0)
      this.$emit('setProp', ['unpaid_units', total])
    }
  }
}
</script>
