<template>
  <div>
    <v-row>
      <card-list-item title="Settlement ID">
        <router-link :to="{ name: 'brokered-settlement', params: { id: settlement.id } }">
          {{ settlement.id }}
        </router-link>
      </card-list-item>
      <card-list-item title="Status">
        {{ settlement.status_text }}
      </card-list-item>
      <card-list-item title="Date Shipped">
        {{ settlement.shipped_date | formatDate }}
      </card-list-item>
    </v-row>
    <v-row>
      <card-list-item title="Notes">
        {{ settlement.notes ? settlement.notes : '-' }}
      </card-list-item>
    </v-row>
    <v-expansion-panels
      flat
      accordion
      focusable
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b> Catalyst Shipped </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <card-list-item title="Converters Processed">
              {{ settlement.converters_processed }}
            </card-list-item>
            <card-list-item title="Mixed Catalyst">
              {{ settlement.received_lbs_total | dollarSign }}
            </card-list-item>
          </v-row>
          <v-row>
            <card-list-item title="AVG per CAT">
              {{ settlement.converter_average_price | dollarSign }}
            </card-list-item>
            <card-list-item title="Catalyst Average Per Pound">
              {{ settlement.average_per_lb | dollarSign }}
            </card-list-item>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b> Assay &amp; Value Summary </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <thead>
              <tr>
                <th>Commodity</th>
                <th>Troy Ounces</th>
                <th>Market Price</th>
                <th>% Return</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody
              class="text-right"
              v-for="(metal, index) in settlement.metals_details"
              :key="index"
            >
              <tr v-if="metal.pt_value > 0">
                <td><b>Platinum</b></td>
                <td>{{ Number(metal.pt_returnable).toFixed(2) | appendToz }}</td>
                <td>{{ Number(metal.pt_mk).toFixed(2) | addCommas | dollarSign }}</td>
                <td>{{ metal.pt_return_rate | appendPercent }}</td>
                <td>{{ metal.pt_value | addCommas | dollarSign }}</td>
              </tr>
              <tr v-if="metal.pd_value > 0">
                <td><b>Palladium</b></td>
                <td>{{ Number(metal.pd_returnable).toFixed(2) | appendToz }}</td>
                <td>{{ Number(metal.pd_mk).toFixed(2) | addCommas | dollarSign }}</td>
                <td>{{ metal.pd_return_rate | appendPercent }}</td>
                <td>{{ metal.pd_value | addCommas | dollarSign }}</td>
              </tr>
              <tr v-if="metal.rh_value > 0">
                <td><b>Platinum</b></td>
                <td>{{ Number(metal.rh_returnable).toFixed(2) | appendToz }}</td>
                <td>{{ Number(metal.rh_mk).toFixed(2) | addCommas | dollarSign }}</td>
                <td>{{ metal.rh_return_rate | appendPercent }}</td>
                <td>{{ metal.rh_value | addCommas | dollarSign }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b> Paid Outright </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Total Price</th>
                <th>Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in settlement.purchased_outright"
                :key="item.id"
              >
                <td>
                  <b>{{ item.material_type }}</b>
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  {{ item.unit_price | currencyUSD }}
                </td>
                <td>
                  {{ item.total_price | currencyUSD }}
                </td>
                <td>
                  {{ item.paid }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <b> Fees, Advances, &amp; Dues </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <thead>
              <tr>
                <th>Gross Value</th>
                <th>Treatment Fee</th>
                <th>Handling Fee</th>
                <th>DPF/Foil Value</th>
                <th><b>Net Value</b></th>
                <th><i>Less Advance</i></th>
                <th><b>Amount Due</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>{{ settlement.gross_value | addCommas | dollarSign }}</b></td>
                <td>{{ settlement.treatment_fee | addCommas | dollarSign }}</td>
                <td>{{ settlement.small_lot_fee | addCommas | dollarSign }}</td>
                <td>{{ settlement.unpaid_units | addCommas | dollarSign }}</td>
                <td><b>{{ settlement.net_value | addCommas | dollarSign }}</b></td>
                <td>{{ settlement.advances | addCommas | dollarSign }}</td>
                <td><b>{{ settlement.amount_due | addCommas | dollarSign }}</b></td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'ViewBrokeredSettlement',
  props: {
    settlement: {
      type: Object,
      required: true
    }
  }
}
</script>
