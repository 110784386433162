<template>
  <div>
    <v-row
      v-for="key in keys"
      :key="key.value"
    >
      <!--New Section-->
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|decimal:10|min_value:0"
          v-slot="{ errors }"
        >
          <v-text-field
            dense
            type="number"
            :value="hedge[key.value + '_client_returnable']"
            label="Returnable Toz"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:9999999|decimal:3|min_value:0"
          v-slot="{ errors }"
        >
          <v-text-field
            dense
            type="number"
            :value="hedge[key.value + '_mk']"
            @input="setProp(key.value,key.value + '_mk', $event)"
            label="Market Price"
            prepend-icon="mdi-currency-usd"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|decimal:2|min_value:0"
          v-slot="{ errors }"
        >
          <v-text-field
            dense
            class="dense-filled"
            readonly
            type="number"
            :value="hedge[key.value + '_value']"
            label="Value"
            prepend-icon="mdi-currency-usd"
            :error-messages="errors"
            hint="(readonly) toz x price x rate"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'InternalProformaMetal',
  props: {
    hedge: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    keys: [
      { desc: 'Platinum', value: 'pt' },
      { desc: 'Palladium', value: 'pd' },
      { desc: 'Rhodium', value: 'rh' }
    ]
  }),
  methods: {
    setProp (key, prop, value) {
      this.$emit(
        'setHedge',
        Object.assign({}, this.hedge, { [prop]: value })
      )
    }
  }
}
</script>
