var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" Finance/Amount Due ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"dense":"","value":_vm.bs.expected_pay_date,"label":"Expected Payment Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['expected_pay_date', $event])}}},on))]}}],null,true)},[_c('v-date-picker',{on:{"input":function($event){return _vm.$emit('setProp', ['expected_pay_date', $event])}},model:{value:(_vm.bs.expected_pay_date),callback:function ($$v) {_vm.$set(_vm.bs, "expected_pay_date", $$v)},expression:"bs.expected_pay_date"}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{'dense-filled': !_vm.bs.finance_charge_manual},attrs:{"readonly":!_vm.bs.finance_charge_manual,"dense":"","type":"number","value":_vm.bs.finance_charge,"label":"Finance Charge","prepend-icon":"mdi-currency-usd","hint":'Net Value x 5% / 360 x (difference between received date ' + _vm.config.receivedDate + ' and Expected Payment Date)',"append-icon":_vm.bs.finance_charge_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline',"error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['finance_charge', $event])},"click:append":function($event){return _vm.$emit('setProp', ['finance_charge_manual', !_vm.bs.finance_charge_manual])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","value":_vm.bs.advances,"label":"Advances","prepend-icon":"mdi-currency-usd","error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['advances', $event])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"dense-filled",attrs:{"readonly":"","dense":"","type":"number","value":_vm.bs.amount_due,"prepend-icon":"mdi-currency-usd","label":"Settlement Amount Due","hint":"(readonly) net value - advance + unpaid value","error-messages":errors}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }