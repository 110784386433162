<template>
  <tbody>
    <tr>
      <td>Est. Return</td>
      <td class="pt-color text-right">
        {{ lot.estimated_returnable_pt | toFixed(3) | appendRtoz | filterNA }}
      </td>
      <td class="pd-color text-right">
        {{ lot.estimated_returnable_pd | toFixed(3) | appendRtoz | filterNA }}
      </td>
      <td class="rh-color text-right">
        {{ lot.estimated_returnable_rh | toFixed(3) | appendRtoz | filterNA }}
      </td>
    </tr>
    <tr>
      <td>Returnable</td>
      <td class="pt-color text-right">
        {{ lot.returnable_pt | toFixed(3) | appendRtoz | filterNA }}
      </td>
      <td class="pd-color text-right">
        {{ lot.returnable_pd | toFixed(3) | appendRtoz | filterNA }}
      </td>
      <td class="rh-color text-right">
        {{ lot.returnable_rh | toFixed(3) | appendRtoz | filterNA }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'HedgeOzDisplay',
  props: { lot: { type: Object, required: true } }
}
</script>
