<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-skeleton-loader
          type="text"
        />
      </v-col>
    </v-row>
    <h1>
      Brokered Settlement
    </h1>
    <v-skeleton-loader
      type="heading, actions"
    />
    <v-row
      v-for="nx in 3"
      :key="'r'+ nx"
    >
      <v-col
        md="4"
        v-for="n in 3"
        :key="n"
      >
        <v-skeleton-loader
          type="text"
        />
      </v-col>
    </v-row>
    <h2>
      Purchased Outright
    </h2>
    <v-skeleton-loader
      type="text, table-row@6, actions"
    />
    <!--metals-->
    <h2>
      Metals
    </h2>
    <v-row>
      <v-col
        cols="12"
        v-for="r in 3"
        :key="'r' + r"
      >
        <v-skeleton-loader
          type="table-row@3"
        />
      </v-col>
      <v-col
        md="4"
        v-for="n in 3"
        :key="n"
      >
        <v-skeleton-loader
          type="text"
        />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader
          type="actions"
        />
      </v-col>
    </v-row>
    <h2>
      Fees
    </h2>
    <v-row>
      <v-col
        md="3"
        v-for="n in 4"
        :key="n"
      >
        <v-skeleton-loader
          type="text"
        />
      </v-col>
    </v-row>
    <h2>
      Finance/Amount Due
    </h2>
    <v-row>
      <v-col
        md="3"
        v-for="n in 4"
        :key="n"
      >
        <v-skeleton-loader
          type="text"
        />
      </v-col>
    </v-row>
    <h2>
      Averages
    </h2>
    <v-row>
      <v-col
        md="4"
        v-for="n in 2"
        :key="n"
      >
        <v-skeleton-loader
          type="text"
        />
      </v-col>
    </v-row>
    <v-skeleton-loader
      type="actions"
    />
  </v-container>
</template>

<script>
export default {
  name: 'BrokeredSettlementSkeleton'
}
</script>
