<template>
  <v-speed-dial
    class="d-print-none"
    v-model="fab"
    fixed
    bottom
    right
    v-bind="[$attrs, $props]"
    v-on="$listeners"
  >
    <template v-slot:activator>
      <v-btn
        v-model="fab"
        color="primary"
        dark
        fab
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <slot />
  </v-speed-dial>
</template>

<script>
export default {
  name: 'BaseSpeedDial',
  props: {
    icon: {
      type: String,
      default: 'mdi-cog-outline'
    }
  },
  data: () => ({
    fab: false
  })
}
</script>
