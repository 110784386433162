<template>
  <v-card>
    <v-expansion-panels
      accordion
      @change="loadCheckIns"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="v-card__subtitle font-weight-bold">
          <div>
            Intakes & Check-Ins <span class="caption">(Converted to {{ $auth.user.fx_currency }})</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <lot-check-ins-table
            :loading="loading"
            :check-ins="checkIns"
            :totals="totals"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import axios from 'axios'
import LotCheckInsTable from './LotCheckInsTable'

export default {
  name: 'IntakeCheckInSection',
  components: {
    LotCheckInsTable
  },
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      panels: [],
      loading: true,
      intakes: [],
      checkIns: {},
      totals: {},
      fetched: false
    }
  },
  methods: {
    async loadCheckIns () {
      if (!this.fetched) {
        const response = await axios.get(`/api/lots/${this.lot.id}/check_ins/`)
        if (response.data) {
          this.checkIns = response.data.check_ins
          this.totals = response.data.totals
        }
        this.loading = false
      }
    }
  }
}
</script>
