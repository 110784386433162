<template>
  <div
    class="text-center"
    v-if="$store.getters.can('create-lots')"
  >
    <v-tooltip
      top
      :disabled="shipmentSummaryLots.length > 0"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <base-button
            label="Export Allocations"
            :disabled="loading || invalid"
            :loading="loading"
            @clicked="exportToExcel"
          />
        </span>
      </template>
      <span> No Lots Found for Shipment Summary </span>
    </v-tooltip>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { mapActions, mapGetters } from 'vuex'
import { saveAs } from 'file-saver'

export default {
  name: 'ExportHedgeAllocations',
  props: {
    shipmentSummaryId: { type: Number, required: true },
    shipmentSummaryLots: { type: Array, required: true }
  },
  data: () => ({
    loading: true,
    invalid: false
  }),
  computed: {
    ...mapGetters({
      hedgeBank: 'hedgeStore/hedges'
    })
  },
  methods: {
    ...mapActions({
      fetchHedgeBank: 'hedgeStore/fetchHedgeBank'
    }),
    exportToExcel () {
      const wb = XLSX.utils.book_new()
      wb.Props = {
        Title: 'SmelterHedgeAllocation',
        Subject: 'SmelterHedgeAllocations',
        Author: 'PGM',
        CreatedDate: new Date()
      }
      wb.SheetNames.push('Allocations')
      const headers = this.getHeaders()
      const wsData = this.getAllocationExcelData(headers)
      let ws = XLSX.utils.json_to_sheet(wsData, { headers: Object.keys(headers) })
      ws['!cols'] = this.getMinWidthPerColum(wsData, headers)
      const formattedHeaders = this.formatHeaders(ws, headers)
      wb.Sheets['Allocations'] = formattedHeaders
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
      const octetArray = this.convertToOctetArray(wbout)
      const name = `Shipment_Summary_${this.shipmentSummaryId}_allocations.xlsx`
      saveAs(new Blob([octetArray], { type: 'application/octet-stream' }), name)
    },
    getAllocationExcelData (headers) {
      const table = this.hedgeBank.reduce((carr, hedge) => {
        const nextRowSet = this.getNextRowSet(hedge)
        carr.push(...nextRowSet)
        return carr
      }, [])
      const totals = this.getLotTotals(table)
      table.push(totals)
      return table
    },
    /**
     * Get the next set of rows
     * @param {Object} hedge
     * @return {Array[Object]}
     */
    getNextRowSet (hedge) {
      const metalTypes = ['pt', 'pd', 'rh']
      return metalTypes.reduce((accu, mt) => {
        let unitPrice = 0
        let initialHedge = 0
        let balanceToz = 0
        switch (mt) {
          case 'pt':
            unitPrice = hedge.pt_rate_per_toz
            initialHedge = hedge.pt_initial_toz
            balanceToz = hedge.pt_available_toz
            break
          case 'pd':
            unitPrice = hedge.pd_rate_per_toz
            initialHedge = hedge.pd_initial_toz
            balanceToz = hedge.pd_available_toz
            break
          default:
            unitPrice = hedge.rh_rate_per_toz
            initialHedge = hedge.rh_initial_toz
            balanceToz = hedge.rh_available_toz
            break
        }
        let nextRow = {
          date: this.formatDate(hedge.created_at),
          estArrival: this.formatDate(hedge.expires_on),
          metalType: mt.toUpperCase(),
          unitPrice: this.formatMoney(unitPrice),
          initialHedge
        }
        const lotAllocations = this.getLotAllocations(hedge, mt)
        const currentAvailable = parseFloat(balanceToz) + this.getAllocationValue(lotAllocations)
        nextRow.applied = Math.abs(initialHedge - currentAvailable)
        nextRow.balanceToz = initialHedge - nextRow.applied
        nextRow = { ...nextRow, ...lotAllocations }
        nextRow.remaining = balanceToz
        nextRow.comment = ' '
        const lotValues = this.getLotValues(lotAllocations, unitPrice)
        nextRow = { ...nextRow, ...lotValues }
        accu.push(nextRow)
        return accu
      }, [])
    },
    /**
     * Get the allocations for lots
     * @param {Object} hedge
     * @param {String} metalType
     * @return {Object}
     */
    getLotAllocations (hedge, metalType) {
      return this.shipmentSummaryLots.reduce((accu, ssl) => {
        let nextAlloc = ' '
        const allocated = hedge.allocations.reduce((accu, alloc) => {
          if (alloc.lot.id === ssl.lot.id) accu += parseFloat(alloc[metalType])
          return accu
        }, 0)
        if (allocated > 0) nextAlloc = allocated + ''
        accu[`lot-${ssl.lot.id}`] = nextAlloc
        return accu
      }, {})
    },
    /**
     * Get the value of the lot allocations
     * @param {Object} lotAllocations
     * @return {Object}
     */
    getAllocationValue (lotAllocations) {
      return Object.values(lotAllocations).reduce((accu, alloc) => {
        if (!isNaN(parseFloat(alloc))) accu += parseFloat(alloc)
        return accu
      }, 0)
    },
    /**
     * Get the values for each lot for each hedge
     * @param {Object} lotAllocations
     * @param {Float} pricePerOunce
     */
    getLotValues (lotAllocations, pricePerOunce) {
      return Object.entries(lotAllocations).reduce((accu, alloc) => {
        const key = alloc[0]
        const value = alloc[1]
        accu[`${key}-Total`] = ' '
        if (value !== ' ') {
          const total = parseFloat(value) * parseFloat(pricePerOunce)
          accu[`${key}-Total`] = this.formatMoney(total)
        }
        return accu
      }, {})
    },
    /**
     * Get the totals for the lot to be appended to the table
     * @param {Array[Object]} table
     * @return {Object}
     */
    getLotTotals (table) {
      const lotKeys = this.shipmentSummaryLots.reduce((accu, ssl) => {
        accu.push(`lot-${ssl.lot.id}-Total`)
        return accu
      }, [])
      return lotKeys.reduce((accu, key) => {
        const nextTotal = table.reduce((carr, row) => {
          if (row[key] === ' ') return carr
          carr += parseFloat(row[key].replace(/\$|,/g, ''))
          return carr
        }, 0)
        accu[key] = this.formatMoney(nextTotal)
        return accu
      }, {})
    },
    /**
     * Get the max required width to show content
     * @param {Array[Object]} table
     * @param {Object} headers
     * @return {Array[Object]}
     */
    getMinWidthPerColum (table, headers) {
      return Object.keys(headers).reduce((accu, col) => {
        const nextWidth = table.reduce((carr, row) => {
          const min = (row[col] + '').length
          if (min > carr) carr = min
          return carr
        }, col.length)
        accu.push({ width: nextWidth * 1.3 })
        return accu
      }, [])
    },
    /**
     * Get all headers that the excel sheet
     */
    getHeaders () {
      let headers = {
        date: 'Date',
        estArrival: 'Est Arrival',
        vendorName: 'Vendor Name',
        metalType: 'Metal Type',
        unitPrice: 'Unit Price ($)',
        initialHedge: 'Hedge',
        applied: 'Applied',
        balanceToz: 'Balance TOZ'
      }
      const lotApplyHeaders = this.shipmentSummaryLots.reduce((accu, ssl) => {
        const internalId = ssl.internal_lot_id || ssl.lot.id
        const externalId = ssl.external_lot_id
        let value = `${internalId}`
        if (externalId) {
          value = `${externalId} \r ${internalId}`
        }
        accu[`lot-${ssl.lot.id}`] = value
        return accu
      }, {})
      headers = { ...headers, ...lotApplyHeaders }
      headers.remaining = 'Remaining'
      headers.comment = 'Comment'
      const lotTotalHeaders = this.shipmentSummaryLots.reduce((accu, ssl) => {
        const internalId = ssl.internal_lot_id || ssl.lot.id
        accu[`lot-${ssl.lot.id}-Total`] = internalId
        return accu
      }, {})
      headers = Object.assign(headers, lotTotalHeaders)
      return headers
    },
    /**
     * Formats headers to the correct value, rather than their key
     * @param {Object} ws - the worksheet
     * @param {Object} headers
     */
    formatHeaders (ws, headers) {
      let range = XLSX.utils.decode_range(ws['!ref'])
      for (let header = range.s.r; header <= range.e.c; ++header) {
        let address = XLSX.utils.encode_col(header) + '1'
        if (!ws[address]) continue
        ws[address].v = headers[ws[address].v]
      }
      return ws
    }
  },
  mounted () {
    this.fetchHedgeBank().then(() => { this.loading = false })
  }
}
</script>
