<template>
  <div>
    <h2>
      Fees
    </h2>
    <v-card
      class="my-6"
      outlined
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <ValidationProvider
              immediate
              rules="required|max_value:99999999|min_value:0|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                :readonly="!bs.treatment_fee_manual"
                :class="{'dense-filled': !bs.treatment_fee_manual}"
                dense
                type="number"
                :value="bs.treatment_fee"
                @input="$emit('setProp', ['treatment_fee', $event])"
                label="Processing Fee"
                prepend-icon="mdi-currency-usd"
                hint="weight * processing per lb"
                :append-icon="bs.treatment_fee_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline'"
                :error-messages="errors"
                @click:append="$emit('setProp', ['treatment_fee_manual', !bs.treatment_fee_manual])"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <ValidationProvider
              immediate
              rules="required|max_value:99999999|min_value:0|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                :readonly="!bs.freight_charge_manual"
                :class="{'dense-filled': !bs.freight_charge_manual}"
                dense
                type="number"
                :value="bs.small_lot_fee"
                @input="$emit('setProp', ['small_lot_fee', $event])"
                label="Handling Fee"
                prepend-icon="mdi-currency-usd"
                hint="Populated when weight < 2000lbs and Freight Responsibility is Vendor"
                :append-icon="bs.freight_charge_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline'"
                :error-messages="errors"
                @click:append="$emit('setProp', ['freight_charge_manual', !bs.freight_charge_manual])"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <ValidationProvider
              immediate
              rules="required|max_value:99999999|min_value:0|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                type="number"
                :value="bs.freight_charge"
                @input="$emit('setProp', ['freight_charge', $event])"
                label="Freight Charge"
                prepend-icon="mdi-currency-usd"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-text-field
              :value="sumOtherFees"
              label="Net Other Fees"
              prepend-icon="mdi-currency-usd"
              dense
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <ValidationProvider
              immediate
              rules="required|max_value:99999999|min_value:0|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                class="dense-filled"
                dense
                readonly
                type="number"
                :value="bs.net_value"
                label="Net Value"
                prepend-icon="mdi-currency-usd"
                hint="(readonly) gross - (processing fee + handling fee)"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="addFeeDialog = !addFeeDialog"
        >
          Add Fee
        </v-btn>
      </v-card-actions>
    </v-card>
    <edit-dialog
      headline-text="Additional Fees"
      v-model="addFeeDialog"
      :show-decline="false"
      accept-text="done"
    >
      <v-data-table
        :headers="headers"
        :items="bs.additional_fees"
      >
        <template v-slot:item.other_fee_value="{ item }">
          ${{ item.other_fee_value }}
        </template>
        <template v-slot:item.remove="{ item }">
          <v-btn
            small
            @click="removeFee(item)"
          >
            remove
          </v-btn>
        </template>
      </v-data-table>
      <br>
      <h3>
        Add Fee
      </h3>
      <v-row>
        <v-col>
          <v-text-field
            v-model="field.other_fee_name"
            type="text"
            label="Fee Description"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="field.other_fee_value"
            type="number"
            min="0"
            label="Fee Amount"
            prepend-icon="mdi-currency-usd"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn
            @click="addFee"
            :disabled="field.other_fee_name === '' || field.other_fee_value === 0"
            color="primary"
            small
          >
            Add fee
          </v-btn>
        </v-col>
      </v-row>
    </edit-dialog>
  </div>
</template>
<script>
export default {
  name: 'BSFeesForm',
  props: {
    bs: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    addFeeDialog: false,
    field: {
      other_fee_name: '',
      other_fee_value: 0
    },
    headers: [
      { text: 'Fee Name', value: 'other_fee_name' },
      { text: 'Value', value: 'other_fee_value' },
      { text: 'Remove', value: 'remove', align: 'right' }
    ]
  }),
  computed: {
    sumOtherFees () {
      const reducer = (previousValue, currentValue) => previousValue + +currentValue.other_fee_value
      const total = this.bs.additional_fees.reduce(reducer, 0).toFixed(2)
      return total
    },
    additionalFees () {
      if (this.bs.additional_fees && this.bs.additional_fees.length) return this.bs.additional_fees
      return []
    }
  },
  methods: {
    addFee () {
      let clone = [...this.additionalFees]
      clone.push(this.field)
      this.$emit('setProp', ['additional_fees', clone])
      this.$store.commit('setSnackbarSuccess', 'Added new additional fee')
      this.field = {
        other_fee_name: '',
        other_fee_value: 0
      }
    },
    removeFee (fee) {
      if (confirm('Are you sure you want to remove ' + fee.other_fee_name + '?')) {
        const index = this.additionalFees.findIndex(f => f.other_fee_name === fee.other_fee_name)
        let clone = this.additionalFees.slice(0, index).concat(this.additionalFees.slice(index + 1))
        this.$emit('setProp', ['additional_fees', clone])
      }
    }
  }
}
</script>
