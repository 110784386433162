<template>
  <unauthorized
    :authorized="$store.getters.can('view-lots')"
    message="Unauthorized to view Shipment Summaries"
  >
    <base-title title="Shipment Summary List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchSmelters"
    >
      <date-range-input
        :start-date="createdStartDate"
        :end-date="createdEndDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="createdStartDate = $event"
        @alterEndDate="createdEndDate = $event"
      />
      <date-range-input
        :start-date="shippedStartDate"
        :end-date="shippedEndDate"
        start-date-label="Shipped Date From"
        end-date-label="Shipped Date To"
        @alterStartDate="shippedStartDate = $event"
        @alterEndDate="shippedEndDate = $event"
      />
      <date-range-input
        :start-date="receivedStartDate"
        :end-date="receivedEndDate"
        start-date-label="Smelter Received Date From"
        end-date-label="Smelter Received Date To"
        @alterStartDate="receivedStartDate = $event"
        @alterEndDate="receivedEndDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            label="Customer Shipment ID"
            v-model="customerId"
            type="string"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-autocomplete
            label="Smelters"
            v-model="smelters"
            :items="smelterList"
            multiple
            clearable
            item-text="name"
            item-value="name"
          />
        </v-col>
      </v-row>
      <!--      <v-row>-->
      <!--        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">-->
      <!--          <v-text-field-->
      <!--            label="Final Lot Value"-->
      <!--            v-model.number="finalLotValue"-->
      <!--            prepend-inner-icon="mdi-currency-usd"-->
      <!--            type="number"-->
      <!--          />-->
      <!--        </v-col>-->
      <!--        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">-->
      <!--          <v-text-field-->
      <!--            label="Settlement Value"-->
      <!--            v-model.number="settlementValue"-->
      <!--            prepend-inner-icon="mdi-currency-usd"-->
      <!--            type="number"-->
      <!--          />-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="shipment-summary"
        />
        <info-tooltip
          v-if="item.settlement_report"
        >
          <template v-slot:wrap-me>
            <external-link
              :value="item.settlement_report"
              class="pl-4"
              icon="mdi-paperclip"
            />
          </template>
          Shipment Summary
        </info-tooltip>
      </template>
      <template v-slot:item.smelter__name="{ item }">
        {{ item.smelter.name }}
      </template>
      <template v-slot:item.shipmentsummarylot_set__lot__name="{ item }">
        <router-link
          v-for="lot in lotObjs(item)"
          :key="lot.id"
          :to="{ name: 'lot', params: { id: lot.id } }"
        >
          {{ lot.name }}
        </router-link>
      </template>
      <template v-slot:item.shipped_date="{ item }">
        {{ item.shipped_date | filterNA | formatDate }}
      </template>
      <template v-slot:item.shipped_date="{ item }">
        {{ item.shipped_date | filterNA | formatDate }}
      </template>
      <template v-slot:item.received_date="{ item }">
        {{ item.received_date | filterNA | formatDate }}
      </template>
      <template v-slot:item.settlement_value="{ item }">
        {{ item.settlement_value | filterNA | trimDecimals | addCommas | dollarSign }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | filterNA | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-lots')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="$router.push({ name: 'create-shipment-summary' })"
    >
      Create Summary
    </text-fab>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import { mapGetters } from 'vuex'
import axios from 'axios'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'

export default {

  mixins: [chipsMixin, paginationMixin],

  name: 'ShipmentSummaryList',
  components: {
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'shipment-summary-list',
      lazyLoadedSmelters: false,

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Cust. ID', value: 'customer_shipment_id' },
        { text: 'Smelter', value: 'smelter__name' },
        { text: 'Lot', sortable: false, value: 'shipmentsummarylot_set__lot__name' },
        { text: 'Final Value', value: 'settlement_value' },
        { text: 'Shipped', value: 'shipped_date' },
        { text: 'Received', value: 'received_date' },
        { text: 'Created', value: 'created_at' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'smelters', prop: 'smelters', type: Array, query: 'smelter__name__in' },
        { text: 'Cust. ID', prop: 'customerId', type: String, query: 'customer_shipment_id__icontains' },
        // { name: 'finalLotValue', prop: 'finalLotValue', type: String, query: 'final_lot_value__range' },
        // { name: 'settlementValue', prop: 'settlementValue', type: String, query: 'settlement_value__range' },
        { name: 'createdStartDate', prop: 'createdStartDate', type: String, query: 'created_at__gte' },
        { name: 'createdEndDate', prop: 'createdEndDate', type: String, query: 'created_at__lte' },
        { name: 'shippedStartDate', prop: 'shippedStartDate', type: String, query: 'ship_date__gte' },
        { name: 'shippedEndDate', prop: 'shippedEndDate', type: String, query: 'ship_date__lte' },
        { name: 'receivedStartDate', prop: 'receivedStartDate', type: String, query: 'received_date__gte' },
        { name: 'receivedEndDate', prop: 'receivedEndDate', type: String, query: 'received_date__lte' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      smelterList: 'smelterStore/smelters'
    })
  },
  methods: {
    fetchSmelters () {
      if (!this.lazyLoadedSmelters) {
        this.$store.dispatch('smelterStore/fetchSmelters')
        this.lazyLoadedSmelters = true
      }
    },
    lotObjs (shipmentSummary) {
      return shipmentSummary.shipmentsummarylot_set.map(ssl => ssl.lot).filter(x => x)
    },
    async executeSearch () {
      const params = this.getQueryParams()
      axios.get('/api/shipment_summaries/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
