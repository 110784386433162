<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th class="pa-1 text-center">
          Material Type
        </th>
        <th class="pa-1 text-center">
          Gross Rec.
        </th>
        <th class="pa-1 text-center">
          Tare
        </th>
        <th class="pa-1 text-center">
          Net Wet Rec.
        </th>
        <th class="pa-1 text-center">
          Scrap
        </th>
        <th class="pa-1 text-center">
          Adj. Net Wet Rec.
        </th>
        <th class="pa-1 text-center">
          Percent Moisture
        </th>
        <th class="pa-1 text-center">
          Net Dry
        </th>
        <th class="pa-1 text-center">
          Carbon
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, index) in report.smelter_summary"
        class="text-center"
        :key="index"
      >
        <td class="pa-1">
          {{ row.material_type }}
        </td>
        <td class="pa-1">
          <uom>{{ row.gross_received_lbs | toFixed(3) | addCommas }}</uom>
        </td>
        <td class="pa-1">
          <uom>{{ row.tare_lbs | toFixed(3) | addCommas }}</uom>
        </td>
        <td class="pa-1">
          <uom>{{ row.net_wet_received_lbs | toFixed(3) | addCommas }}</uom>
        </td>
        <td class="pa-1">
          <uom>{{ row.scrap_lbs | toFixed(3) | addCommas }}</uom>
        </td>
        <td class="pa-1">
          <uom>{{ row.adj_net_wet_received_lbs | toFixed(3) | addCommas }}</uom>
        </td>
        <td class="pa-1">
          {{ row.percent_moisture | toFixed(3) }}<small>%</small>
        </td>
        <td class="pa-1">
          <uom>{{ row.net_dry_lbs | toFixed(3) | addCommas }}</uom>
        </td>
        <td class="pa-1">
          {{ row.carbon | toFixed(2) }}<small>%</small>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

export default {
  name: 'FinalReportSummary',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
