<template>
  <edit-dialog
    :value="value"
    @input="$emit('input', $event)"
    @accept="select"
    headline-text="Select Hedge"
    accept-text="Select Hedge"
  >
    <v-autocomplete
      v-model="hedge"
      :loading="loading"
      :search-input.sync="search"
      label="Search for Hedge"
      :items="hedges"
      :item-text="formatText"
      return-object
      clearable
    />
  </edit-dialog>
</template>

<script>
import { searchHedges } from '@/requests/hedge'

export default {
  name: 'HedgeSearchDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    loading: false,
    hedge: null,
    search: '',
    hedges: [],
    searchTimeout: null
  }),
  watch: {
    search (text) {
      if (this.hedge && (this.formatText(this.hedge) === text)) return
      if (this.searchTimeout) clearTimeout(this.searchTimeout)
      this.loading = true
      this.searchTimeout = setTimeout(() => {
        searchHedges(text).then(res => {
          if (res.data.results) this.hedges = res.data.results
        }).finally(() => {
          this.loading = false
        })
      }, 300)
    }
  },
  methods: {
    formatText (hedge) {
      if (!hedge) return ''
      return hedge.id.toString()
    },
    select () {
      this.$emit('selected', this.hedge)
    }
  }
}
</script>
