<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-report')"
    message="You are not authorized to view Lots Reports"
  >
    <v-container v-if="report === null">
      <v-skeleton-loader type="heading" />
    </v-container>
    <v-container
      v-else
    >
      <base-title class="d-print-none">
        Final Report
        <template v-slot:subtitle>
          Aggregate report on lot settlements
        </template>
      </base-title>
      <v-alert
        v-if="editRequired"
        class="my-4"
        color="warning"
        outlined
        dense
      >
        <v-row>
          <v-col>
            <v-icon color="warning">
              mdi-alert
            </v-icon>
            {{ editRequired }}
          </v-col>
        </v-row>
      </v-alert>
      <v-sheet
        light
        class="mx-auto my-8 d-print-none"
        max-width="800px"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>
                Summary - Lot
              </th>
              <th />
            </tr>
          </thead>
          <tbody
            v-for="(summary, key) in summaries"
            :key="key"
          >
            <tr>
              <td>
                <router-link-id
                  name="shipment-summary"
                  :id="summary.id"
                  target="_blank"
                >
                  {{ summary.id }}
                </router-link-id>
              </td>
              <td class="text-right">
                <lot-finance-edit-dialog
                  :shipment-summary="summary"
                  @updated="loadData"
                >
                  <template v-slot:default="{ toggle }">
                    <v-btn
                      :disabled="!$store.getters.can('view-lot-report')"
                      small
                      :color="isFilled(summary) ? '' : 'primary'"
                      :outlined="isFilled(summary)"
                      @click="toggle"
                    >
                      Edit Summary Values
                    </v-btn>
                  </template>
                </lot-finance-edit-dialog>
              </td>
            </tr>
            <tr
              v-for="ssl in summary.shipmentsummarylot_set"
              :key="ssl.id"
            >
              <td>
                <router-link-id
                  class="pl-8"
                  name="lot"
                  :id="ssl.lot.id"
                  target="_blank"
                >
                  {{ ssl.lot.name }}
                </router-link-id>
              </td>
              <td class="text-right">
                <div class="d-flex align-center justify-end">
                  <v-btn
                    v-if="!isFilled(summary)"
                    disabled
                    small
                    outlined
                    color="warning"
                  >
                    Mark Complete
                  </v-btn>
                  <v-btn
                    v-else-if="!ssl.lot.lot_finance_complete"
                    small
                    outlined
                    color="primary"
                    @click="markLotFinanceToggle(ssl.lot.id, !ssl.lot.lot_finance_complete)"
                  >
                    Mark Complete
                  </v-btn>
                  <v-btn
                    v-else
                    small
                    outlined
                    @click="markLotFinanceToggle(ssl.lot.id, !ssl.lot.lot_finance_complete)"
                  >
                    Completed
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-sheet>
      <v-card
        light
        class="pa-2 mx-auto"
        flat
        max-width="800px"
      >
        <v-row>
          <v-col
            class="text-center"
            cols="6"
            offset="3"
          >
            <v-img
              :src="config.logo"
              :alt="config.name"
              height="100px"
              contain
            />
          </v-col>
          <v-col
            class="text-right"
            cols="3"
          >
            <small>
              Report Date {{ report.report_date }}
            </small>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="title text-center"
          >
            Final Lot Report
          </v-col>
        </v-row>
        <!--Row 2-->
        <hr>
        <final-report-overview
          class="mb-10"
          :report="report"
        />
        <h3>Lot Shipment Summary</h3>
        <hr>
        <final-report-shipment
          class="mb-10"
          :report="report"
        />
        <h3>
          Smelter Return Summary
        </h3>
        <hr>
        <final-report-summary
          class="mb-10"
          :report="report"
        />
        <h3>Metals</h3>
        <hr>
        <div class="d-print-none">
          <h4>Allocations <info-tooltip>Does not appear when printed</info-tooltip></h4>
          <report-allocations
            class="mb-10"
            :report="report"
          />
        </div>
        <h4>Metal Summary</h4>
        <final-report-summary-metals
          :report="report"
          class="mb-10"
        />
        <h3>Charges and Values</h3>
        <hr>
        <final-report-values
          :report="report"
        />
        <h3>Profit Summary</h3>
        <hr>
        <final-report-profit
          class="mb-10"
          :report="report"
        />
        <h3>Notes</h3>
        <hr>
        <p
          v-if="this.report.notes"
          class="pa-4 pb-10"
        >
          {{ this.report.notes }}
        </p>
        <p v-else>
          <small>
            <i>Left Blank</i>
          </small>
        </p>
      </v-card>
      <p class="d-print-none text-center my-8">
        For best printing quality, turn off headers/footers and set margins to None.
      </p>
      <final-report-edit
        v-if="report"
        v-model="edit"
        :report="report"
        @report-updated="patchReport"
      />
      <v-btn
        class="d-print-none"
        color="accent"
        fixed
        fab
        bottom
        right
        @click="edit = !edit"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-container>
  </unauthorized>
</template>

<script>
import FinalReportOverview from './components/FinalReportOverview'
import FinalReportShipment from './components/FinalReportShipment'
import FinalReportSummary from './components/FinalReportSummary'
import FinalReportSummaryMetals from './components/FinalReportSummaryMetals'
import FinalReportValues from './components/FinalReportValues'
import FinalReportProfit from './components/FinalReportProfit'
import FinalReportEdit from './components/FinalReportEdit'
import axios from 'axios'
import ReportAllocations from '@/views/FinanceReport/components/ReportAllocations'
import config from '@/../izzi_config'
import LotFinanceEditDialog from '@/views/FinanceReport/components/LotFinanceEditDialog.vue'

export default {
  name: 'LotFinalReport',
  components: {
    LotFinanceEditDialog,
    ReportAllocations,
    FinalReportEdit,
    FinalReportValues,
    FinalReportProfit,
    FinalReportSummaryMetals,
    FinalReportSummary,
    FinalReportShipment,
    FinalReportOverview
  },
  data: () => ({
    config,
    loading: false,
    report: null,
    edit: false,
    summaries: []
  }),
  computed: {
    editRequired () {
      if (this.report.warnings && this.report.warnings.length > 0) {
        return this.report.warnings.join(' ')
      }
      return false
    },
    reportUrl () {
      const reportId = this.$route.params.id
      return `/api/final_reports/${reportId}/`
    }
  },
  methods: {
    isFilled (summary) {
      return !!(summary.smelter_payment_date !== '' &&
        summary.finance_charge !== '' &&
        summary.freight_charge !== '' &&
        summary.loc_finance_charge !== '')
    },
    async markLotFinanceToggle (lotId, value) {
      await axios.patch(`api/lots/${lotId}/`, { lot_finance_complete: value })
      await this.fetchSummaries()
      await this.fetchReport()
    },
    async patchReport (payload) {
      this.loading = true
      await axios.patch(this.reportUrl, payload)
      await this.fetchReport()
      this.loading = false
    },
    async fetchReport () {
      this.loading = true
      const r = await axios.get(this.reportUrl + 'report/')
      this.report = r.data
      this.loading = false
    },
    fetchSummaries () {
      const params = {
        id__in: this.report.shipment_summary_list.map(ssl => ssl.id).join(','),
        fields: 'id,finance_charge,freight_charge,loc_finance_charge,lot_finance_complete,smelter_payment_date,shipmentsummarylot_set',
        page_size: 0
      }
      axios.get('/api/shipment_summaries/', { params }).then(response => {
        this.summaries = response.data
      })
    },
    async loadData () {
      await this.fetchReport()
      await this.fetchSummaries()
    }
  },
  async mounted () {
    await this.loadData()
  }
}
</script>

<style scoped>
@media screen {
  div.divFooter {
    display: none;
  }
}
@media print {
  div.divFooter {
    position: fixed;
    bottom: 0;
  }
}
</style>
