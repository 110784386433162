var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-4"},[_c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-assay-result'),"message":"Sorry, not authorized to see PPM's"}},[_c('v-card-subtitle',[_c('strong',[_vm._v(" Assay Results ")])]),(!_vm.loading)?_c('v-card-text',[(_vm.results)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"disable-pagination":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.source_display",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.source_display)+" "),_c('br'),_c('v-chip',{attrs:{"x-small":"","color":item.status === 'FN' ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.status_display)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.pt_ppm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pt-color"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pt_ppm,3)))+" ")])]}},{key:"item.pd_ppm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pd-color"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pd_ppm,3)))+" ")])]}},{key:"item.rh_ppm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"rh-color"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.rh_ppm,3)))+" ")])]}},{key:"item.percent_moisture",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percent_moisture)+"% ")]}},{key:"item.percent_scrap",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percent_scrap)+"% ")]}},{key:"item.pt_toz_estimated",fn:function(ref){
var item = ref.item;
return [(item.pt_toz_estimated)?_c('span',{staticClass:"pt-color"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pt_toz_estimated,3)))+" ")]):_vm._e()]}},{key:"item.pd_toz_estimated",fn:function(ref){
var item = ref.item;
return [(item.pd_toz_estimated)?_c('span',{staticClass:"pd-color"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pd_toz_estimated,3)))+" ")]):_vm._e()]}},{key:"item.rh_toz_estimated",fn:function(ref){
var item = ref.item;
return [(item.rh_toz_estimated)?_c('span',{staticClass:"rh-color"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.rh_toz_estimated,3)))+" ")]):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editResult(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,128817073)}):_c('div',[_vm._v(" No Results Yet ")])],1):_vm._e()],1),(_vm.lot && _vm.lot.id)?_c('create-assay-result-form',{attrs:{"lot-id":_vm.lot.id},on:{"created":function($event){return _vm.results.push($event)}}}):_vm._e(),(_vm.assayResultToEdit)?_c('edit-assay-result-dialog',{attrs:{"assay-result":_vm.assayResultToEdit},on:{"updated":function($event){_vm.results = _vm.results.map(function (r) { return r.id === $event.id ? $event : r; })}},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }