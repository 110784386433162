<template>
  <div>
    <v-card-subtitle class="subtitle-1 pb-0 font-weight-bold">
      Weights
    </v-card-subtitle>
    <div
      v-for="(ss, index) in shipmentSummaries"
      :key="index + '-ss'"
    >
      <div v-if="shipmentSummaries.length > 1">
        <b class="ml-3 mt-5">{{ `Lot ${ss.internal_lot_id}` }}</b>
        <v-divider class="ma-2" />
      </div>
      <v-row>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Material Type"
            no-cols
          >
            <template
              v-if="$store.getters.can('edit-shipment-summary')"
              v-slot:title-button
            >
              <edit-material-type-dialog :shipment-summary-lot="shipmentSummaryLot" />
            </template>
            {{ shipmentSummaryLot.material_type_text }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Gross Received LBS"
            no-cols
          >
            {{ ss.gross_lbs | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Tare LBS"
            no-cols
          >
            {{ ss.tare_lbs | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Net Wet Received LBS"
            no-cols
          >
            {{ ss.net_wet_lbs | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Scrap LBS"
            no-cols
          >
            {{ ss.scrap_lbs | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Adj. Net Wet Received Lbs"
            no-cols
          >
            {{ ss.adj_net_wet_received_lbs | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Percent Moisture"
            no-cols
          >
            {{ ss.percent_moisture | toFixed(2) | filterZero | appendPercent }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Net Dry Lbs"
            no-cols
          >
            {{ ss.net_dry_lbs | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Sample Weight"
            no-cols
          >
            {{ ss.sample_weight | toFixed(3) | filterZero | appendLbs }}
          </card-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import EditMaterialTypeDialog from '@/views/ShipmentSummaries/components/Write/EditMaterialTypeDialog.vue'

export default {
  name: 'SummaryWeights',
  components: { EditMaterialTypeDialog },
  props: {
    shipmentSummaries: {
      type: Array,
      required: true
    },
    shipmentSummaryLot: {
      type: Object,
      required: true
    }
  }
}
</script>
