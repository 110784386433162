<template>
  <unauthorized
    :authorized="$store.getters.can('view-client-proforma')"
    message="You are not authorized to view Client Proforma"
  >
    <brokered-settlement-skeleton
      v-if="loading"
      title="Client Proforma"
    />
    <ValidationObserver
      v-else
      v-slot="{ invalid, reset }"
    >
      <v-form
        :readonly="isLocked"
        :value="invalid"
        class="mb-16"
      >
        <v-container>
          <url-breadcrumbs
            v-if="breadcrumbs"
            :items="breadcrumbs"
          />
          <h1>
            Client Proforma
          </h1>
          <div class="caption">
            Generates a detailed report for clients showing returns on a lot after it has been processed at the smelter, providing transparency and financial insight.
          </div>
          <v-toolbar
            flat
            dense
            color="transparent"
          >
            <v-btn
              :color="isLocked ? 'error' : ''"
              small
              rounded
              @click="isLocked = !isLocked"
            >
              {{ isLocked ? 'Locked' : 'unlocked' }}
              <v-icon
                small
                class="ml-2"
              >
                {{ isLocked ? 'mdi-lock-outline' : 'mdi-lock-open-outline' }}
              </v-icon>
            </v-btn>
            <v-spacer />
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-btn
                  :disabled="isLocked"
                  text
                  @click="refreshData"
                >
                  Refresh Data
                </v-btn>
              </template>
              Replace values with the latest lot data.
            </info-tooltip>
            <v-btn
              :disabled="!history.length"
              text
              class="ml-2"
              target="_blank"
              :to="{ name: 'client-proforma-print', params: { id: $route.params.id } }"
            >
              Print
            </v-btn>
          </v-toolbar>
          <v-select
            :value="historyVersion"
            label="Revision History"
            :items="history"
            :item-text="historyLabelFormat"
            item-value="id"
            @change="setVersionFromHistoryById"
          />
          <div>
            <h2>
              Properties
            </h2>
            <v-sheet class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="lotName"
                    readonly
                    class="dense-filled"
                    label="Lot Name"
                    validation-rules="required|max:400"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    immediate
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="Yard"
                      v-model="yardId"
                      :items="$store.getters['clientProformaStore/getYardChoices']"
                      :item-text="x => `${x.id} - ${x.name} ${x.address || ''}, ${x.city || ''} ${x.state || ''}`"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    label="Est. Ship Date"
                    v-model="shipDate"
                    :show-date-picker="!isLocked"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  offset-md="8"
                >
                  <rules-date
                    label="Arrival Date"
                    v-model="arrivalDate"
                    :show-date-picker="!isLocked"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                    v-model="convertersProcessed"
                    label="Converters Processed"
                    type="number"
                  />
                  <v-checkbox
                    v-model="printPieces"
                    class="pt-0 mt-0"
                    label="Print Pieces"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    v-model="mixedCatalystWeight"
                    label="Mixed Catalyst Weight/Received Lbs"
                    type="number"
                  />
                  <v-checkbox
                    v-model="printWeight"
                    class="pt-0 mt-0"
                    label="Print Weight"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    label="Client Value Date"
                    v-model="clientValueDate"
                    :show-date-picker="!isLocked"
                  />
                  <div class="text-center text-sm-body-2">
                    <a
                      v-if="!isLocked"
                      @click="clientValueDate = clientValueDateByArrival"
                    >
                      Use Arrival + Client Payment Days ({{ termsClientPaymentDays }}) {{ clientValueDateByArrival | formatDate }}
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Outright Units
            </h2>
            If the units are already paid, the are not included in the deposit calculation
            <v-sheet class="pa-4 mb-6">
              <v-simple-table>
                <thead>
                  <tr>
                    <th
                      v-for="h in ['Type', 'Quantity', 'Unit Price', 'Total Price', 'Mark Paid']"
                      :key="h"
                      :style="'min-width:' + (['Type', 'Mark Paid'].includes(h)) ? 50 : 100 + 'px;'"
                      v-text="h"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="outright in $store.getters['clientProformaStore/getPurchasedOutright']"
                    :key="outright.materialType"
                  >
                    <td>
                      <strong>{{ outright.materialType }}</strong>
                    </td>
                    <td>
                      <rules-text
                        validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                        :value="outright.quantity"
                        type="number"
                        @input="updateOutrightValue(outright.materialType, 'quantity', $event)"
                      />
                    </td>
                    <td>
                      <rules-text
                        validation-rules="required|max_value:9999999999|min_value:0|decimal:2"
                        :value="outright.price"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                        @input="updateOutrightValue(outright.materialType, 'price', $event)"
                      />
                    </td>
                    <td class="pt-2">
                      <rules-text
                        dense
                        class="dense-filled"
                        readonly
                        validation-rules="required|max_value:9999999999|min_value:0|decimal:2"
                        :value="outright.totalPrice"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                      />
                    </td>
                    <td class="pb-4 pl-6">
                      <v-simple-checkbox
                        :value="outright.paid"
                        color="primary"
                        @input="updateOutrightValue(outright.materialType, 'paid', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-row>
                <v-col
                  cols="12"
                  md="9"
                >
                  <v-select
                    v-model="unitCode"
                    class="d-inline-block mr-4"
                    :items="availableCodes"
                  />
                  <v-btn
                    :disabled="!unitCode"
                    outlined
                    small
                    @click="() => addPurchasedOutrightCode(unitCode)"
                  >
                    Add Line
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <rules-text
                    class="dense-filled"
                    :value="grossOutrightUnpaidValue"
                    label="Gross Unpaid Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Sum of Unpaid Outright"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Metal Allocations
            </h2>
            <v-sheet class="pa-4">
              <span class="h2">Expected Return Values for Lot</span>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="containedPt"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Contained Pt"
                  />
                  <rules-text
                    v-model="brokeredRatePt"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Brokered Rate Pt"
                  />
                  <rules-text
                    v-model="availablePt"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Pt"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedPt / availablePt * 100"
                    color="#0068b9"
                    rounded
                  >
                    <span class="font-weight-medium white--text pl-2">
                      {{ allocatedPt }} / {{ availablePt }} rtoz
                    </span>
                    <v-spacer />
                    <span class="font-weight-medium white--text pr-2">
                      {{ unallocatedPt }}
                    </span>
                  </v-progress-linear>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="containedPd"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Contained Pd"
                  />
                  <rules-text
                    v-model="brokeredRatePd"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Brokered Rate Pd"
                  />
                  <rules-text
                    v-model="availablePd"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Pd"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedPd / availablePd * 100"
                    color="#d00e00"
                    rounded
                  >
                    <span class="font-weight-medium white--text pl-2">
                      {{ allocatedPd }} / {{ availablePd }} rtoz
                    </span>
                    <v-spacer />
                    <span class="font-weight-medium white--text pr-2">
                      {{ unallocatedPd }}
                    </span>
                  </v-progress-linear>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="containedRh"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Contained Rh"
                  />
                  <rules-text
                    v-model="brokeredRateRh"
                    type="number"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Brokered Rate Rh"
                  />
                  <rules-text
                    v-model="availableRh"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Rh"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedRh / availableRh * 100"
                    color="#006104"
                    rounded
                  >
                    <span class="font-weight-medium white--text pl-2">
                      {{ allocatedRh }} / {{ availableRh }} rtoz
                    </span>
                    <v-spacer />
                    <span class="font-weight-medium white--text pr-2">
                      {{ unallocatedRh }}
                    </span>
                  </v-progress-linear>
                </v-col>
              </v-row>
              <div
                v-for="allocation in allocations"
                :key="allocation.hedgeId"
              >
                <div class="d-flex justify-space-between align-center">
                  <div class="text-h6">
                    Hedge #{{ allocation.hedgeId }}
                  </div>
                  <v-btn
                    :disabled="isLocked"
                    outlined
                    x-small
                    @click="removeAllocation(allocation)"
                  >
                    Remove
                  </v-btn>
                </div>
                <v-row>
                  <v-col>
                    <edit-metal
                      alternate-style
                      :hedge="allocation"
                      @setHedge="updateAllocations(allocation.hedgeId, $event)"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="mt-8">
                <div class="text-h6">
                  Add Hedges
                </div>
                <div class="d-flex align-center">
                  <search-autocomplete
                    v-model="selectedHedge"
                    class="mx-4"
                    label="Search for Hedges"
                    item-text="friendlyName"
                    api-path="/api/hedges/"
                    :params="{ ordering: '-id' }"
                    :format-entry="x => Object.assign({}, { friendlyName: `Hedge #${x.id} ${x.pt_available_toz}@$${x.pt_rate_per_toz_fx} / ${x.pd_available_toz}@$${x.pd_rate_per_toz_fx} / ${x.rh_available_toz}@$${x.rh_rate_per_toz_fx}` }, x)"
                  />
                  <v-btn
                    :disabled="!selectedHedge || isLocked"
                    class="mr-4"
                    color="primary"
                    outlined
                    x-small
                    @click="addSearchedHedge(selectedHedge)"
                  >
                    Add
                  </v-btn>
                </div>
                <v-list>
                  <v-list-item
                    v-for="hedge in unallocatedHedges"
                    :key="hedge.id"
                  >
                    <v-list-item-content>
                      {{
                        `Hedge #${hedge.id} ${hedge.ptAvailableToz}@${hedge.ptRate} / ${hedge.pdAvailableToz}@${hedge.pdRate} / ${hedge.rhAvailableToz}@${hedge.rhRate}`
                      }}
                      <span class="text-caption">designated</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="isLocked"
                        color="accent"
                        outlined
                        x-small
                        @click="addHedge(hedge)"
                      >
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      Add Live Hedge
                      <span class="text-caption">covers the unallocated amount of troy oz</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="isLocked"
                        outlined
                        x-small
                        @click="addBlankHedge"
                      >
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="allocatedReturnablePt"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Pt rtoz"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="allocatedReturnablePd"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Pd rtoz"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    immediate
                    :value="allocatedReturnableRh"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Rh rtoz"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  offset-md="9"
                  md="3"
                >
                  <rules-text
                    class="dense-filled"
                    :value="grossMetalValue"
                    label="Gross Metal Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Sum Hedge Values"
                  />
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <div class="py-4" />
          <h2>
            Fees
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="refiningFee"
                  type="number"
                  label="Refining Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a
                    v-if="!isLocked"
                    @click="refiningFee = termsRefiningFeeTotal"
                  >
                    Use fee from terms {{ termsRefiningFeeTotal }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="processingFee"
                  type="number"
                  label="Processing Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a
                    v-if="!isLocked"
                    @click="processingFee = termsTreatmentCharge"
                  >
                    Use charges from terms {{ termsTreatmentCharge }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="handlingFee"
                  type="number"
                  label="Handling Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a
                    v-if="!isLocked"
                    @click="handlingFee = termsHandlingFee"
                  >
                    Use fee from terms {{ termsHandlingFee }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="freightCharge"
                  type="number"
                  label="Freight Charge"
                  hint="Users total from allocated transits."
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="assayFee"
                  type="number"
                  label="Assay Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a
                    v-if="!isLocked"
                    @click="assayFee = termAssayFee"
                  >
                    Use fee from terms {{ termAssayFee }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="shreddingFee"
                  type="number"
                  label="Shredding Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a
                    v-if="!isLocked"
                    @click="shreddingFee = termShreddingFee"
                  >
                    Use fee from terms {{ termShreddingFee }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="netOtherFees"
                  type="number"
                  label="Net Other Fees"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  class="px-4"
                  v-model="consolidateFees"
                  label="Consolidate Fees When Printing"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="totalFees"
                  readonly
                  class="dense-filled"
                  type="number"
                  label="Total Fees"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="netValue"
                  readonly
                  class="dense-filled"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Net Value"
                  prepend-icon="mdi-currency-usd"
                  hint="Unpaid Value + Metal Value - Fees"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
                offset-md="6"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="brokerageFee"
                  type="number"
                  label="Brokerage Fee"
                  prepend-icon="mdi-currency-usd"
                />
                <div class="text-center text-sm-body-2">
                  <a
                    v-if="!isLocked"
                    @click="brokerageFee = termBrokerageFee"
                  >
                    Use fee from terms {{ termBrokerageFee }}
                  </a>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="netValueLessBrokerage"
                  readonly
                  class="dense-filled"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Net Value (Less Brokerage Fee)"
                  prepend-icon="mdi-currency-usd"
                  hint="Unpaid Value + Metal Value - Fees"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Brokerage Terms
          </h2>
          Terms are displayed for reference only
          <v-sheet>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="term in brokerageTerms"
                  :key="term[0]"
                >
                  <th>{{ term[0] }}</th>
                  <td
                    class="text-right"
                  >
                    {{ term[1] }}
                  </td>
                  <td
                    class="text-right"
                  >
                    {{ term[2] }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Finance / Amount Due
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-date
                  v-model="expectedPaymentDate"
                  label="Expected Payment Date"
                  prepend-icon="mdi-calendar"
                  :show-date-picker="!isLocked"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  v-model="daysOfInterest"
                  label="Days of Interest"
                  type="number"
                  hint="Client Value Date - Expected Payment Date"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="interestPercent"
                  label="Interest Rate %"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="financeCharge"
                  class="dense-filled"
                  readonly
                  label="Finance Charge"
                  type="number"
                  hint="Net Value * Interest Percent / 360 * Days of Interest"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                offset-md="9"
                md="3"
              >
                <rules-text
                  :value="settlementAmountDue"
                  readonly
                  class="dense-filled"
                  label="Settlement Amount Due"
                  type="number"
                  hint="Net Value + Finance Charge"
                />
              </v-col>
              <v-col
                cols="12"
                offset-md="6"
                md="3"
              >
                <rules-text
                  v-model="depositPercent"
                  label="Deposit %"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="depositTotal"
                  class="dense-filled"
                  readonly
                  label="Deposit Total"
                  type="number"
                  hint="Settlement Amount * Deposit Percent"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Advances
          </h2>
          Advances will be saved separately to the advance calculator, and will be consistent across revisions.
          <v-sheet class="pa-4">
            <v-row
              v-for="advance in advances"
              :key="advance.id"
            >
              <v-col
                cols="12"
                md="9"
                class="mt-8"
              >
                <router-link
                  :to="{ name: 'advance-view', params: { id: advance.id } }"
                  target="_blank"
                >
                  Advance #{{ advance.id }}
                </router-link>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  :label="`Advance #${ advance.id } Amount`"
                  :value="advance.amount_fx"
                  :hint="`Source ${advance.currency} ${advance.amount}`"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                Recommended Advance is {{ recommendedAdvance }}. Click add advance to add advance entry.
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="text-right"
              >
                <v-btn
                  text
                  color="primary"
                  rounded
                  small
                  :to="{
                    name: 'advance-create',
                    query: advanceQuery
                  }"
                >
                  Add Advance
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                offset-md="9"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  label="Total Advances"
                  :value="totalAdvances"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-container>
        <text-fab
          v-if="!isLocked"
          bottom
          right
          fixed
          icon-text="mdi-content-save-outline"
          :color="isModified ? 'primary': ''"
          @click="save"
        >
          Save Changes
        </text-fab>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>

import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton.vue'
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import EditMetal from '@/views/Lots/components/Brokered/components/EditMetal.vue'
import moment from 'moment'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientProforma',
  components: {
    SearchAutocomplete,
    EditMetal,
    RulesDate,
    RulesText,
    BrokeredSettlementSkeleton
  },
  data () {
    return {
      isModified: false,
      selectedHedge: null,
      breadcrumbs: [
        ['lot list', { name: 'lot-list' }],
        [`Lot #${this.$route.params.id}`, { name: 'lot' }],
        [`Client Proforma`, { name: 'client-proforma' }]
      ],
      yardChoices: [],
      loading: false,
      readonly: true,
      unitCode: null
    }
  },
  watch: {
    yardId () {
      this.$store.dispatch('clientProformaStore/fetchYardTerms')
    },
    snapshot () {
      this.isModified = true
    }
  },
  computed: {
    availableCodes () {
      const outright = this.$store.getters['clientProformaStore/getPurchasedOutright'].map(x => x.materialType)
      return this.$store.getters['getConverterTypes'].map(x => x.value).filter(x => !outright.includes(x))
    },
    advanceQuery () {
      return {
        lot: this.lotId,
        amount: this.recommendedAdvance,
        redirect: JSON.stringify({
          name: 'advance-calculator',
          params: { id: this.lotId }
        })
      }
    },
    snapshot () {
      return [
        this.$store.getters['clientProformaStore/getStateSnapshot']
      ]
    },
    isLocked: {
      get () {
        return this.$store.getters['clientProformaStore/getIsLocked']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setIsLocked', value)
      }
    },
    yardId: {
      get () {
        return this.$store.getters['clientProformaStore/getYardId']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setYardId', value)
      }
    },
    shipDate: {
      get () {
        return this.$store.getters['clientProformaStore/getShipDate']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setShipDate', value)
      }
    },
    convertersProcessed: {
      get () {
        return this.$store.getters['clientProformaStore/getConvertersProcessed']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setConvertersProcessed', value)
      }
    },
    mixedCatalystWeight: {
      get () {
        return this.$store.getters['clientProformaStore/getMixedCatalystWeight']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setMixedCatalystWeight', value)
      }
    },
    arrivalDate: {
      get () {
        return this.$store.getters['clientProformaStore/getArrivalDate']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setArrivalDate', value)
      }
    },
    clientValueDate: {
      get () {
        return this.$store.getters['clientProformaStore/getClientValueDate']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setClientValueDate', value)
      }
    },
    assayFee: {
      get () {
        return this.$store.getters['clientProformaStore/getAssayFee']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setAssayFee', value)
      }
    },
    shreddingFee: {
      get () {
        return this.$store.getters['clientProformaStore/getShreddingFee']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setShreddingFee', value)
      }
    },
    brokerageFee: {
      get () {
        return this.$store.getters['clientProformaStore/getBrokerageFee']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setBrokerageFee', value)
      }
    },
    refiningFee: {
      get () {
        return this.$store.getters['clientProformaStore/getRefiningFee']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setRefiningFee', value)
      }
    },
    processingFee: {
      get () {
        return this.$store.getters['clientProformaStore/getProcessingFee']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setProcessingFee', value)
      }
    },
    handlingFee: {
      get () {
        return this.$store.getters['clientProformaStore/getHandlingFee']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setHandlingFee', value)
      }
    },
    freightCharge: {
      get () {
        return this.$store.getters['clientProformaStore/getFreightCharge']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setFreightCharge', value)
      }
    },
    netOtherFees: {
      get () {
        return this.$store.getters['clientProformaStore/getNetOtherFees']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setNetOtherFees', value)
      }
    },
    consolidateFees: {
      get () {
        return this.$store.getters['clientProformaStore/getConsolidateFees']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setConsolidateFees', value)
      }
    },
    expectedPaymentDate: {
      get () {
        return this.$store.getters['clientProformaStore/getExpectedPaymentDate']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setExpectedPaymentDate', value)
      }
    },
    interestPercent: {
      get () {
        return this.$store.getters['clientProformaStore/getInterestPercent']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setInterestPercent', value)
      }
    },
    depositPercent: {
      get () {
        return this.$store.getters['clientProformaStore/getDepositPercent']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setDepositPercent', value)
      }
    },
    allocations () {
      return this.$store.getters['clientProformaStore/getAllocations'].map(allocation => ({
        hedgeId: allocation.hedgeId,
        pt_returnable: allocation.ptToz,
        pt_return_rate: allocation.ptRate,
        pt_client_returnable: allocation.ptRtoz,
        pt_mk: allocation.ptMarketRate,
        pt_value: allocation.ptValue,
        pd_returnable: allocation.pdToz,
        pd_return_rate: allocation.pdRate,
        pd_client_returnable: allocation.pdRtoz,
        pd_mk: allocation.pdMarketRate,
        pd_value: allocation.pdValue,
        rh_returnable: allocation.rhToz,
        rh_return_rate: allocation.rhRate,
        rh_client_returnable: allocation.rhRtoz,
        rh_mk: allocation.rhMarketRate,
        rh_value: allocation.rhValue
      }))
    },
    containedPt: {
      get () {
        return this.$store.getters['clientProformaStore/getContainedPt']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setContainedPt', value)
      }
    },
    containedPd: {
      get () {
        return this.$store.getters['clientProformaStore/getContainedPd']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setContainedPd', value)
      }
    },
    containedRh: {
      get () {
        return this.$store.getters['clientProformaStore/getContainedRh']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setContainedRh', value)
      }
    },
    brokeredRatePt: {
      get () {
        return this.$store.getters['clientProformaStore/getBrokeredRatePt']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setBrokeredRatePt', value)
      }
    },
    brokeredRatePd: {
      get () {
        return this.$store.getters['clientProformaStore/getBrokeredRatePd']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setBrokeredRatePd', value)
      }
    },
    brokeredRateRh: {
      get () {
        return this.$store.getters['clientProformaStore/getBrokeredRateRh']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setBrokeredRateRh', value)
      }
    },
    printPieces: {
      get () {
        return this.$store.getters['clientProformaStore/getPrintPieces']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setPrintPieces', value)
      }
    },
    printWeight: {
      get () {
        return this.$store.getters['clientProformaStore/getPrintWeight']
      },
      set (value) {
        this.$store.commit('clientProformaStore/setPrintWeight', value)
      }
    },
    ...mapGetters('clientProformaStore', {
      termsRefiningFeeTotal: 'getTermsRefiningFeeTotal',
      termsTreatmentCharge: 'getTermsTreatmentCharge',
      termsHandlingFee: 'getTermsHandlingFee',
      termsClientPaymentDays: 'getTermsClientPaymentDays',
      termAssayFee: 'getTermAssayFee',
      advances: 'getAdvances',
      allocatedPd: 'getAllocatedPd',
      allocatedPt: 'getAllocatedPt',
      allocatedRh: 'getAllocatedRh',
      allocatedReturnablePd: 'getAllocatedReturnablePd',
      allocatedReturnablePt: 'getAllocatedReturnablePt',
      allocatedReturnableRh: 'getAllocatedReturnableRh',
      availablePd: 'getAvailablePd',
      availablePt: 'getAvailablePt',
      availableRh: 'getAvailableRh',
      brokerageTerms: 'getCleanedBrokerageTerms',
      clientValueDateByArrival: 'getClientValueDateByArrival',
      daysOfInterest: 'getDaysOfInterest',
      depositTotal: 'getDepositTotal',
      financeCharge: 'getFinanceCharge',
      grossMetalValue: 'getGrossMetalValue',
      grossOutrightUnpaidValue: 'getGrossOutrightUnpaidValue',
      history: 'getAdvanceCalculatorHistory',
      historyVersion: 'getHistoryVersion',
      lotId: 'getLotId',
      lotName: 'getLotName',
      netValue: 'getNetValue',
      netValueLessBrokerage: 'getNetValueLessBrokerage',
      newAdvances: 'getNewAdvances',
      recommendedAdvance: 'getRecommendedAdvance',
      settlementAmountDue: 'getSettlementAmountDue',
      totalAdvances: 'totalAdvances',
      totalFees: 'getTotalFees',
      unallocatedHedges: 'getUnallocatedHedges',
      unallocatedPd: 'getUnallocatedPd',
      unallocatedPt: 'getUnallocatedPt',
      unallocatedRh: 'getUnallocatedRh',
      termShreddingFee: 'getTermShreddingFee',
      termBrokerageFee: 'getTermBrokerageFee'
    })
  },
  methods: {
    addPurchasedOutrightCode (code) {
      this.$store.commit('clientProformaStore/addPurchasedOutrightCode', code)
      this.unitCode = null
    },
    historyLabelFormat (history) {
      let text = `${history.id}. ${moment(history.created_at).format('MM/DD/YYYY HH:mm:ss')} ${history.fx_currency}`
      if (this.history.length > 0 && history.id === this.history[0].id) text += ' (Latest)'
      return text
    },
    addSearchedHedge (hedge) {
      this.addHedge({
        id: hedge.id,
        ptAvailableToz: hedge.pt_available_toz,
        ptRate: hedge.pt_rate_per_toz_fx,
        pdAvailableToz: hedge.pd_available_toz,
        pdRate: hedge.pd_rate_per_toz_fx,
        rhAvailableToz: hedge.rh_available_toz,
        rhRate: hedge.rh_rate_per_toz_fx
      })
    },
    addBlankHedge () {
      const prefix = 'Live '
      function getNextCSequence (ids) {
        let cValue = 0
        for (const id of ids) {
          if (!(typeof id === 'string') || !id.startsWith(prefix)) continue
          const x = parseInt(id.slice(prefix.length))
          if (isNaN(x) || x < cValue) continue
          cValue = x
        }
        // Return the next C sequence value up to the target sequence (e.g., "C5")
        return prefix + (cValue + 1)
      }
      this.$store.commit('clientProformaStore/addHedgeToAllocations', {
        hedgeId: getNextCSequence(this.allocations.map(x => x.hedgeId)),
        ptAvailableToz: 0,
        ptToz: +this.unallocatedPt,
        ptRate: 0.90,
        ptMarketRate: 0,
        pdAvailableToz: 0,
        pdToz: +this.unallocatedPd,
        pdRate: 0.90,
        pdMarketRate: 0,
        rhAvailableToz: 0,
        rhToz: +this.unallocatedRh,
        rhRate: 0.90,
        rhMarketRate: 0
      })
    },
    addHedge (hedge) {
      if (this.allocations.find(alloc => alloc.hedgeId === hedge.id)) {
        this.$store.commit('setSnackbarWarning', 'Allocation for Hedge already exists.')
        return
      }
      this.$store.commit('clientProformaStore/addHedgeToAllocations', {
        hedgeId: hedge.id,
        ptToz: Math.min(+hedge.ptAvailableToz, +this.unallocatedPt),
        ptRate: 0.90,
        ptMarketRate: hedge.ptRate,
        pdToz: Math.min(+hedge.pdAvailableToz, +this.unallocatedPd),
        pdRate: 0.90,
        pdMarketRate: hedge.pdRate,
        rhToz: Math.min(+hedge.rhAvailableToz, +this.unallocatedRh),
        rhRate: 0.90,
        rhMarketRate: hedge.rhRate
      })
      this.isModified = true
    },
    updateAllocations (hedgeId, allocation) {
      let updatedAllocations = []
      for (const line of this.$store.getters['clientProformaStore/getAllocations']) {
        if (line.hedgeId === hedgeId) {
          line.ptToz = allocation.pt_returnable
          line.ptRate = allocation.pt_return_rate
          line.ptRtoz = allocation.pt_client_returnable
          line.ptMarketRate = allocation.pt_mk
          line.ptValue = allocation.pt_value
          line.pdToz = allocation.pd_returnable
          line.pdRate = allocation.pd_return_rate
          line.pdRtoz = allocation.pd_client_returnable
          line.pdMarketRate = allocation.pd_mk
          line.pdValue = allocation.pd_value
          line.rhToz = allocation.rh_returnable
          line.rhRate = allocation.rh_return_rate
          line.rhRtoz = allocation.rh_client_returnable
          line.rhMarketRate = allocation.rh_mk
          line.rhValue = allocation.rh_value
        }
        updatedAllocations.push(line)
      }
      this.$store.commit('clientProformaStore/setAllocations', updatedAllocations)
    },
    updateOutrightValue (material, key, value) {
      let updatedLines = []
      for (const line of this.$store.getters['clientProformaStore/getPurchasedOutright']) {
        if (line.materialType === material) {
          line[key] = value
        }
        updatedLines.push(line)
      }
      this.$store.commit('clientProformaStore/setPurchasedOutright', updatedLines)
    },
    removeAllocation (allocation) {
      this.$store.commit('clientProformaStore/removeAllocation', allocation.hedgeId)
      this.isModified = true
    },
    save () {
      this.$store.dispatch('clientProformaStore/saveAdvanceCalculator')
      this.isModified = false
    },
    async initialize () {
      return Promise.all([
        this.$store.dispatch('clientProformaStore/fetchAdvanceCalculators'),
        this.$store.dispatch('clientProformaStore/fetchDesignatedHedges'),
        this.$store.dispatch('clientProformaStore/fetchAdvances')
      ])
    },
    setVersionFromHistoryById (id) {
      const history = this.history.find(h => h.id === id)
      if (history) {
        this.$store.commit('clientProformaStore/setContextFromHistory', history)
        return true
      }
      return false
    },
    refreshData () {
      return this.$store.dispatch('clientProformaStore/refreshDataFromLotData')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isModified) {
      if (!window.confirm('Leave without saving?')) {
        return
      }
    }
    next()
  },
  /*
   * Fetch the history, if no history then fetch the latest lot seed data
   */
  async mounted () {
    this.loading = true
    this.$store.commit('clientProformaStore/clearState')
    this.$store.commit('clientProformaStore/setLotId', this.$route.params.id)
    await this.initialize()
    if (this.history.length) {
      this.$store.commit('clientProformaStore/setContextFromHistory', this.history[0])
    } else {
      // if there is no history, then seed data
      await this.refreshData()
      this.isLocked = false
    }
    this.loading = false
  }
}
</script>
