var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" Purchased Outright ")]),_c('p',[_vm._v(" Values of Zero do not appear on the final settlement. The Fields that are filled (gray background) are currently being calculated. ")]),_c('v-simple-table',{staticClass:"mb-6"},[_c('thead',[_c('tr',_vm._l((_vm.headers),function(h){return _c('th',{key:h,style:('min-width:' + (['Type', 'Mark Paid'].includes(h)) ? 50 : 100 + 'px;'),domProps:{"textContent":_vm._s(h)}})}),0)]),_c('tbody',_vm._l((_vm.purchasedOutright),function(item,index){return _c('tr',{key:index},[_c('td',[_c('strong',[_vm._v(_vm._s(item.material_type))])]),_c('td',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:2147483647|min_value:0|decimal:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","value":item.quantity,"error-messages":errors},on:{"input":function($event){return _vm.setValue(item.material_type, 'quantity', $event)}}})]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:9999999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{ 'dense-filled': _vm.computeFromTotal[item.material_type]},attrs:{"dense":"","type":"number","value":item.unit_price,"prepend-icon":"mdi-currency-usd","error-messages":errors},on:{"input":function($event){return _vm.setValue(item.material_type, 'unit_price', $event)}}})]}}],null,true)})],1),_c('td',{staticClass:"pt-2"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:9999999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{ 'dense-filled': !_vm.computeFromTotal[item.material_type]},attrs:{"dense":"","type":"number","value":item.total_price,"prepend-icon":"mdi-currency-usd"},on:{"input":function($event){return _vm.setValue(item.material_type, 'total_price', $event)}}})]}}],null,true)})],1),_c('td',{staticClass:"pb-4 pl-6"},[_c('v-simple-checkbox',{attrs:{"disabled":_vm.bs.status && _vm.bs.status === 'FI',"color":"primary","value":item.paid},on:{"input":function($event){return _vm.setValue(item.material_type, 'paid', $event)}}})],1)])}),0)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","readonly":"","type":"number","value":_vm.bs.unpaid_units,"label":"Unpaid AM/DPF/Foil Total","prepend-icon":"mdi-currency-usd","error-messages":errors,"hint":"(readonly) total of unpaid outright purchases"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }