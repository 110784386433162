import factories from './factories'
const { factory, fetchMixin, patchMixin } = factories

const store = factory(
  {
    entityName: 'summary',
    urlTemplate: id => `api/shipment_summaries/${id}/`
  },
  fetchMixin,
  patchMixin
)

export default store
