<template>
  <edit-dialog
    :value="value"
    headline-text="Edit Final Report"
    @accept="patchReport"
    @input="$emit('input', $event)"
  >
    <b>Attached Summaries:</b>
    <v-row>
      <v-chip
        v-for="summary in summaries"
        :key="summary.url"
        close
        @click:close="removeSummary(summary)"
      >
        {{ summary.id + ': ' + summary.customer_shipment_id }}
      </v-chip>
    </v-row>
    <v-row>
      <v-col>
        <p>
          There <b>must</b> be at least <b>ONE</b> Summary attached.
          <br>Summaries that are <b>already attached to a final report</b> will <b>not</b> appear.<br>
          Only Summaries with the <b>same smelter as the report</b> will appear.
        </p>
        <search-autocomplete
          v-model="lookUpSummary"
          :params="{ page_size: 0, fields: 'id,url,customer_shipment_id', smelter: report.smelter_id, final_report__isnull: true }"
          label="Summary Search"
          api-path="/api/shipment_summaries/"
          :format-entry="x => Object.assign({ friendlyName: x.id + ': ' + x.customer_shipment_id }, x)"
          @input="addSummary"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="notes"
          label="Notes"
          hint="Add any notes you would like to appear on the final report"
        />
      </v-col>
    </v-row>
  </edit-dialog>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
export default {
  name: 'FinalReportEdit',
  components: { SearchAutocomplete },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    report: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    lookUpSummary: null,
    summaries: [],
    notes: ''
  }),
  watch: {
    value (opened) {
      if (opened) {
        // this.$store.dispatch('smelterStore/fetchSmelters')
        this.notes = this.report.notes
        this.summaries = this.report.shipment_summary_list
      }
    }
  },
  methods: {
    addSummary (summary) {
      if (this.summaries.findIndex(s => s.url === summary.url) !== -1) return
      if (summary) this.summaries = [...this.summaries, summary]
    },
    removeSummary (summary) {
      const list = this.summaries.filter(x => x.url !== summary.url)
      if (list.length > 0) {
        this.summaries = list
      } else {
        this.$store.commit('setSnackbarWarning', 'There must be at least one summary attached.')
      }
    },
    patchReport () {
      const payload = {
        shipmentsummary_set: this.summaries.map(x => x.url),
        notes: this.notes
      }
      this.$emit('report-updated', payload)
    }
  }
}
</script>
