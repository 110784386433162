var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('edit-dialog',{attrs:{"value":_vm.value,"headline-text":"Update Lot Shipment Summary Fields"},on:{"accept":_vm.patchReport,"input":function($event){return _vm.$emit('input', $event)}}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"disabled":!!_vm.report.lot_finance_complete}},[_c('date-input',{attrs:{"label":"Smelter Payment Date"},model:{value:(_vm.smelterPaymentDate),callback:function ($$v) {_vm.smelterPaymentDate=$$v},expression:"smelterPaymentDate"}}),_c('ValidationProvider',{attrs:{"rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Smelter Finance Charge","error-messages":errors,"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.financeCharge),callback:function ($$v) {_vm.financeCharge=_vm._n($$v)},expression:"financeCharge"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"LOC Finance Charge","error-messages":errors,"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.locFinanceCharge),callback:function ($$v) {_vm.locFinanceCharge=_vm._n($$v)},expression:"locFinanceCharge"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Freight Charge","error-messages":errors,"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.freightCharge),callback:function ($$v) {_vm.freightCharge=_vm._n($$v)},expression:"freightCharge"}})]}}],null,true)})],1),_c('v-switch',{attrs:{"label":_vm.financeCompleteText},model:{value:(_vm.financeComplete),callback:function ($$v) {_vm.financeComplete=$$v},expression:"financeComplete"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }