<template>
  <unauthorized
    :authorized="$store.getters.can('view-lots')"
    message="Not Authorized to View Shipment Summaries"
  >
    <app-loading-indicator v-if="loading" />
    <v-container v-else>
      <base-title>
        {{ `Shipment Summary ID #${shipmentSummary.id}` }}
      </base-title>
      <v-toolbar
        dense
        flat
      >
        <user-upload-dialog :file-links="fileLinks" />
        <v-spacer />
        <edit-shipment-summary
          v-if="$store.getters.can('edit-shipment-summary')"
          @delete="deleteShipmentSummary"
          @update="addDocuments"
          :correct-lot-status="correctLotStatus"
          :id="shipmentSummary.id"
        />
      </v-toolbar>
      <summary-overview
        :shipment-summary="shipmentSummary"
        :adding-doc="addingDoc"
        :not-brokered="notBrokered"
        @refetch="fetchShipmentSummary(this.$route.params.id)"
      />
      <div class="my-8" />
      <summary-documents
        :shipment-summary="shipmentSummary"
        :adding-doc="addingDoc"
        :not-brokered="notBrokered"
        :files="files"
        @addDocuments="addDocuments"
        @deleteFile="deleteFile"
        @refetch="fetchShipmentSummary(this.$route.params.id)"
      />
      <div class="my-8" />
      <export-hedge-allocations
        :shipment-summary-id="shipmentSummary.id"
        :shipment-summary-lots="shipmentSummary.shipmentsummarylot_set"
      />
    </v-container>
  </unauthorized>
</template>

<script>
import SummaryOverview from './SummaryOverview'
import ExportHedgeAllocations from './ExportHedgeAllocations'
import EditShipmentSummary from '../Write/EditShipmentSummary'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import UserUploadDialog from '@/base/components/UserUploadDialog.vue'
import fileLinks from '@/helpers/fileLinks'
import SummaryDocuments from '@/views/ShipmentSummaries/components/Read/SummaryDocuments.vue'
import { expectsSettlement } from '@/helpers/utils'

export default {
  name: 'ShipmentSummary',
  data: () => ({
    loading: true,
    addingDoc: false,
    lots: [],
    notBrokered: false,
    files: []
  }),
  components: {
    SummaryDocuments,
    UserUploadDialog,
    SummaryOverview,
    ExportHedgeAllocations,
    // BaseDialog,
    EditShipmentSummary
  },
  computed: {
    ...mapGetters({ shipmentSummary: 'shipmentSummaryStore/shipmentSummary' }),
    // a shipment summary can only be deleted if it has no attached lots or
    // if none if it's attached lots are completed
    correctLotStatus () {
      if (this.shipmentSummary.shipmentsummarylot_set.length === 0 || this.shipmentSummary.shipmentsummarylot_set.every(sl => sl.lot.status !== 'CO')) return true
      else return false
    },
    fileLinks () {
      return fileLinks.shipmentSummaryFileLink(this.shipmentSummary)
    }
  },
  methods: {
    ...mapActions({
      updateSummary: 'shipmentSummaryStore/updateShipmentSummary',
      deleteSummary: 'shipmentSummaryStore/deleteShipmentSummary'
    }),
    async fetchShipmentSummary (id) {
      const params = {
        page_size: 0,
        shipment_summary: id
      }
      const responses = await Promise.all([
        this.$store.dispatch('shipmentSummaryStore/fetchShipmentSummary', id),
        axios.get('/api/shipment_summary_files/', { params })
      ])
      this.files = responses[1].data
    },
    /**
     * Checks the designation of the attached lots to allow updating
     * of lot status based on final invoice upload
     */
    determineDesignation () {
      let lots = this.shipmentSummary.shipmentsummarylot_set.reduce((accu, ssl) => {
        if (ssl.lot && !expectsSettlement(ssl.lot.designation)) {
          accu += ssl
        }
        return accu
      }, [])
      if (lots && lots.length !== 0) this.notBrokered = true
      else this.notBrokered = false
    },
    async deleteFile (instance) {
      if (confirm('Are you sure you want to delete final invoice #' + instance.id)) {
        await axios.delete(`/api/shipment_summary_files/${instance.id}/`)
        this.$store.commit('setSnackbarSuccess', ' Final Invoice #' + instance.id + ' removed')
        return this.fetchShipmentSummary(this.shipmentSummary.id)
      }
    },
    // Sets documents to formdata and dispatches action to update the shipment summary
    async addDocuments (documents) {
      this.addingDoc = true
      if (documents.summaryDoc) {
        const formData = new FormData()
        formData.set('settlement_report', documents.summaryDoc)
        const summary = { formData, id: this.shipmentSummary.id }
        await this.updateSummary(summary)
      }
      if (documents.invoiceDoc) {
        const formData = new FormData()
        formData.set('upload', documents.invoiceDoc)
        formData.set('type', 'FI')
        formData.set('shipment_summary', this.shipmentSummary.id)
        try {
          await axios.post('/api/shipment_summary_files/', formData)
        } catch {
          this.$store.commit('setSnackbarError', 'Unable to upload file')
        }
      }
      await this.fetchShipmentSummary(this.shipmentSummary.id)
      this.addingDoc = false
    },
    // deletes shipment summary and updates the related lots shipment summary set
    // to null if connected
    deleteShipmentSummary () {
      axios.delete(this.shipmentSummary.url).then(response => {
        if (response.status === 204) {
          this.shipmentSummary.shipmentsummarylot_set.forEach(ssl => {
            axios.patch(`/api/lots/${ssl.lot.id}/`, { shipment_summary_lot_id: null })
          })
          this.$store.commit('setSnackbarSuccess', `Successfully deleted Shipment Summary ID#${this.shipmentSummary.id}`, { root: true })
          this.$router.push({ name: 'shipment-summary-list' })
        } else {
          this.$store.commit('setSnackbarError', 'An error occurred while trying to delete the shipment summary', { root: true })
        }
      })
    }
  },
  mounted () {
    // Fetches Shipment Summary before displaying
    this.fetchShipmentSummary(this.$route.params.id).then(() => {
      this.determineDesignation()
      this.loading = false
    })
  }
}
</script>
