<template>
  <div>
    <h2 class="text-h6">
      Please ensure all fields are correct
    </h2>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <rules-text
          v-model="customerShipmentId"
          label="Lot ID *"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <rules-text
          v-model="shipmentId"
          label="Smelter Lot ID *"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <rules-date
          v-model="shippedDate"
          label="Shipped Date *"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <rules-date
          v-model="receivedDate"
          label="Received Date *"
          :min="shippedDate"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <rules-date
          v-model="assayDate"
          label="Assay Date *"
          :min="receivedDate"
        />
      </v-col>
    </v-row>
    <div
      v-for="(ssl, index) in summaryLots"
      :key="ssl.id"
    >
      <h3>
        <strong>
          Summary Lot {{ index + 1 }}<span v-if="ssl.internalLotId">: {{ ssl.internalLotId }}</span>
        </strong>
      </h3>
      <h3> Weights </h3>
      <v-row>
        <v-col
          cols="6"
          md="3"
        >
          <v-select
            v-model="ssl.materialType"
            label="Material Type"
            :items="converterTypes"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="max_value:9999999999999|decimal:3"
            v-model="ssl.grossLbs"
            label="Gross Received LBS"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="max_value:9999999999999|decimal:3"
            v-model="ssl.tareLbs"
            label="Tare LBS"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="max_value:9999999999999|decimal:3"
            v-model="ssl.netWetLbs"
            label="Net Wet Received LBS"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="max_value:9999999999999|decimal:3"
            v-model="ssl.scrapLbs"
            label="Scrap LBS"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="required|max_value:9999999999999|decimal:3"
            v-model="ssl.adjNetLbs"
            label="Adjusted Net Wet Received LBS *"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="required|max_value:100|decimal:3"
            v-model="ssl.percentMoisture"
            label="Percent Moisture *"
            append-icon="mdi-percent-outline"
            persistent-hint
            :hint="((ssl.percentMoisture / 100) * ssl.adjNetLbs).toFixed(3) + ' LBS'"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="max_value:9999999999999|decimal:3"
            v-model="ssl.netDryLbs"
            label="Net Dry LBS"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="ssl.sampleWeight"
              label="Sample Weight LBS"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3> Contained Assays </h3>
      <v-row>
        <v-col
          cols="6"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.cPtPpm"
            label="PT PPM"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.cPdPpm"
            label="PD PPM"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.cRhPpm"
            label="RH PPM"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-text
            validation-rules="min_value:0|max_value:100|decimal:8"
            v-model="ssl.percentCarbon"
            label="Carbon Percent"
            append-icon="mdi-percent-outline"
          />
        </v-col>
      </v-row>
      <h3> Contained Metals </h3>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.cPtToz"
            label="PT Troy Ounce"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.cPdToz"
            label="PD Troy Ounce"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.cRhToz"
            label="RH Troy Ounce"
            type="number"
          />
        </v-col>
      </v-row>
      <h3>
        Returnable Ounces
      </h3>
      <v-row>
        <v-col
          cols="6"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.rPtToz"
            label="PT Troy Ounce"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.rPdToz"
            label="PD Troy Ounce"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:10"
            v-model="ssl.rRhToz"
            label="RH Troy Ounce"
            type="number"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
        >
          <rules-date
            v-model="ssl.valueDate"
            label="Value Date"
            :min="shippedDate"
          />
        </v-col>
      </v-row>
      <h3>
        Summary of Charges
      </h3>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.chargePerLb"
            label="Treatment $/LBS"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <!-- net wet * chargePerLb -->
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.treatmentCharge"
            label="Treatment Charge"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.smallLot"
            label="Handling Charge"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.totalTreatmentCharge"
            label="Total Treatment Charge"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.totalRefineCharge"
            label="Total Refining Charge"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <!-- total refinance charge + total treatment -->
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.totalProcessCharge"
            label="Total Processing Charge"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.assayCharge"
            label="Assay Charge"
            hint="i.e. Lot or Lab fee"
            persistent-hint
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <rules-text
            validation-rules="max_value:99999999|decimal:2"
            v-model="ssl.freightCharge"
            label="Freight Charge"
            prepend-icon="mdi-currency-usd"
            type="number"
          />
        </v-col>
      </v-row>
    </div>
    <div class="caption">
      <p>* Indicates a required field</p>
    </div>
    <base-button
      auto-center
      @clicked="save"
      label="Save"
      :disabled="loading"
      :loading="loading"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions, mapState } from 'vuex'
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'

export default {
  name: 'ConfirmSummary',
  components: { RulesDate, RulesText },
  data: () => ({
    shipmentId: null,
    customerShipmentId: null,
    shippedDate: null,
    receivedDateDialog: false,
    receivedDate: null,
    assayDateDialog: false,
    assayDate: null,
    summaryLots: [],
    loading: false,
    ss: null
  }),
  computed: {
    ...mapGetters({
      converterTypes: 'lotStore/lotMaterialType',
      shipmentSummary: 'shipmentSummaryStore/shipmentSummary'
    }),
    ...mapState('shipmentSummaryStore', {
      selectedLots: 'lots'
    })
  },
  methods: {
    ...mapActions({
      updateSummaryLot: 'shipmentSummaryStore/updateShipmentSummaryLots',
      updateSummary: 'shipmentSummaryStore/updateShipmentSummary'
    }),
    /** Checks if shipment summary needs to be updated
     * Adds shipment summary to be emitted to 'create shipment summary' which patches
     * shipment summary lots and attaches the associated lots
     */
    save () {
      this.loading = true
      let summary = { id: this.shipmentSummary.id }
      let summaryLotSet
      if (this.assayDate !== this.shipmentSummary.assay_date) summary.assay_date = this.assayDate
      if (this.shippedDate !== this.shipmentSummary.shipped_date) summary.shipped_date = this.shippedDate
      if (this.receivedDate !== this.shipmentSummary.received_date) summary.received_date = this.receivedDate
      if (this.shipmentId !== this.shipmentSummary.shipment_id) summary.shipment_id = this.shipmentId
      if (this.customerShipmentId !== this.shipmentSummary.customer_shipment_id) summary.customer_shipment_id = this.customerShipmentId
      summaryLotSet = this.summaryLots.map(sl => {
        return {
          lotId: sl.lotId,
          id: sl.sslId,
          internal_lot_id: sl.internalLotId,
          material_type: sl.materialType,
          gross_lbs: sl.grossLbs,
          tare_lbs: sl.tareLbs,
          net_wet_lbs: sl.netWetLbs,
          scrap_lbs: sl.scrapLbs,
          adj_net_wet_received_lbs: sl.adjNetLbs,
          percent_moisture: sl.percentMoisture,
          net_dry_lbs: sl.netDryLbs,
          sample_weight: sl.sampleWeight,
          contained_pt_ppm: sl.cPtPpm,
          contained_pd_ppm: sl.cPdPpm,
          contained_rh_ppm: sl.cRhPpm,
          carbon_percent: sl.percentCarbon,
          contained_pt_toz: sl.cPtToz,
          contained_pd_toz: sl.cPdToz,
          contained_rh_toz: sl.cRhToz,
          returnable_pt_toz: sl.rPtToz,
          returnable_pd_toz: sl.rPdToz,
          returnable_rh_toz: sl.rRhToz,
          value_date: sl.valueDate,
          treatment_charge_per_lb: sl.chargePerLb,
          treatment_charge_total: sl.treatmentCharge,
          small_lot_charge: sl.smallLot,
          total_treatment_charge: sl.totalTreatmentCharge,
          total_refining_charge: sl.totalRefineCharge,
          total_processing_charge: sl.totalProcessCharge,
          freight_charge: sl.freightCharge,
          assay_charge: sl.assayCharge
        }
      })
      this.updateSummaryLot(summaryLotSet).then(() => {
        this.updateSummary(summary).then(() => {
          this.loading = false
          let summaryId = this.shipmentSummary.id
          axios.post(`/api/shipment_summaries/${summaryId}/mark_lot_reconcile/`).then(res => {
            this.$store.commit('setSnackbarInfo', res.data)
          }).finally(() => {
            this.$emit('updateRoute', summaryId)
          })
        })
      })
    },
    // sets all data to be confirmed
    setInit () {
      this.receivedDate = this.shipmentSummary.received_date
      this.shippedDate = this.shipmentSummary.shipped_date
      this.assayDate = this.shipmentSummary.assay_date
      this.shipmentId = this.shipmentSummary.shipment_id
      this.customerShipmentId = this.shipmentSummary.customer_shipment_id
      this.summaryLots = this.shipmentSummary.shipmentsummarylot_set.map(l => ({
        sslId: l.id,
        lotId: (l.lot && l.lot.id) ? l.lot.id : this.selectedLots[0].id,
        internalLotId: l.internal_lot_id,
        materialType: l.material_type,
        grossLbs: l.gross_lbs,
        tareLbs: l.tare_lbs,
        netWetLbs: l.net_wet_lbs,
        sampleWeight: l.sample_weight,
        scrapLbs: l.scrap_lbs,
        adjNetLbs: l.adj_net_wet_received_lbs,
        percentMoisture: l.percent_moisture,
        netDryLbs: l.net_dry_lbs,
        cPtPpm: l.contained_pt_ppm,
        cPdPpm: l.contained_pd_ppm,
        cRhPpm: l.contained_rh_ppm,
        percentCarbon: l.carbon_percent,
        cPtToz: l.contained_pt_toz,
        cPdToz: l.contained_pd_toz,
        cRhToz: l.contained_rh_toz,
        rPtToz: l.returnable_pt_toz,
        rPdToz: l.returnable_pd_toz,
        rRhToz: l.returnable_rh_toz,
        valueDate: l.value_date,
        chargePerLb: l.treatment_charge_per_lb,
        treatmentCharge: l.treatment_charge_total,
        smallLot: l.small_lot_charge,
        totalTreatmentCharge: l.total_treatment_charge,
        totalRefineCharge: l.total_refining_charge,
        totalProcessCharge: l.total_processing_charge,
        assayCharge: l.assay_charge,
        freightCharge: l.freight_charge
      }))
    }
  },
  watch: {
    shipmentSummary () {
      this.setInit()
    }
  },
  mounted () {
    this.setInit()
  }
}
</script>
