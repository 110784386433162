<!--
Form for creating a new brokered settlement given a shipment summary lot url and a specific yard
@emit('created', brokeredSettlement)
-->

<template>
  <ValidationObserver
    v-slot="{ invalid, reset }"
  >
    <v-form
      :value="invalid"
      @submit.prevent="createBrokeredSettlement"
    >
      <edit-dialog
        headline-text="Create Brokered Settlement"
        :value="value"
        :inputs-valid="!invalid"
        accept-text="create"
        @input="$emit('input', $event)"
        @accept="createBrokeredSettlement"
      >
        <v-card-title>
          Lot Name: {{ getLotName }}
        </v-card-title>
        <ValidationProvider
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            v-model="yard"
            :items="yards"
            title="Yard"
            :item-text="x => `${x.name} (${x.full_address})`"
            return-object
            :error-messages="errors"
          />
        </ValidationProvider>
      </edit-dialog>
    </v-form>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CreateBrokeredSettlementDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    lotName: {
      type: String,
      default: ''
    },
    shipmentSummaryLot: {
      type: Object,
      required: true
    },
    yards: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    loading: true,
    yard: null
  }),
  computed: {
    getLotName () {
      if (this.lotName) return this.lotName
      if (this.yard) return this.yard.name
      return 'Name Not Available'
    }
  },
  methods: {
    /**
     * Creates a payload for creating a brokered settlement
     * @returns {{shipment_summary_lot: string, metals: *[], yard_id: null}}
     */
    getCreatePayload () {
      if (!this.yard.id) throw Error('Yard ID Not Available')
      return {
        // if lot name isn't set use the yards name
        lot_name: this.getLotName,
        shipment_summary_lot: this.shipmentSummaryLot.url,
        metals: [],
        yard_id: this.yard.id
      }
    },
    /**
     * Submits a axios request for posting a new brokered settlement
     * @returns {Promise<void>}
     */
    async createBrokeredSettlement () {
      if (!this.shipmentSummaryLot.url) {
        this.$store.commit('setSnackbarError', 'Missing: Shipment Summary Lot')
        return
      }
      try {
        this.loading = true
        // Two requests, one to create then a second to attach
        const response = await axios.post('/api/brokered_settlements/', this.getCreatePayload())
        await axios.patch(this.shipmentSummaryLot.url, { brokeredsettlement: response.data.url })
        // this.$emit('created', response.data)
        if (response.data?.id) {
          this.$router.push({ name: 'brokered-settlement', params: { id: response.data.id } })
          this.$emit('created')
          this.$store.commit('setSnackbarSuccess', 'Brokered Settlement #' + response.data.id + ' Created successfully')
        }
      } catch (err) {
        this.$store.commit('setSnackbarError', err.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
