<!--
Form fields for creating a Carrier
@created(carrier) that is created
-->
<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    >
      <v-btn
        @click="dialog = !dialog"
      >
        Create Location
      </v-btn>
    </slot>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        headline-text="Create Location"
        v-model="dialog"
        :inputs-valid="!invalid"
        accept-text="Create"
        @accept="createLocation"
      >
        <p class="font-italic">
          Important: Don't use this form to create locations for yards (they are created automatically).
        </p>
        <ValidationProvider
          rules="required|max:100"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Name*"
            v-model="name"
            :error-messages="errors"
          />
        </ValidationProvider>
        <v-text-field
          label="Street"
          v-model="address"
        />
        <v-text-field
          label="City"
          v-model="city"
        />
        <ValidationProvider
          rules="max:2"
          v-slot="{ errors }"
        >
          <v-text-field
            label="State"
            :error-messages="errors"
            v-model="state"
          />
        </ValidationProvider>
        <v-text-field
          label="Zip Code"
          v-model="zip"
        />
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CreateLocationDialog',
  props: {
    minimalFields: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    name: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  }),
  methods: {
    createLocation () {
      if (this.name.length === 0) {
        this.$store.commit('setSnackbarError', 'Can not create a location without a name.')
        return
      }
      const payload = {
        name: this.name
      }
      if (this.address) payload.address = this.address
      if (this.city) payload.city = this.city
      if (this.state) payload.state = this.state
      if (this.zip) payload.zip_code = this.zip
      axios.post('/logistics/locations/', payload).then(res => {
        this.$store.commit('setSnackbarSuccess', 'Location created.')
        this.dialog = false
        this.$emit('created', res.data)
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Failed to create Location.')
      })
    }
  }
}
</script>
