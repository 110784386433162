<template>
  <v-card class="pb-4">
    <v-card-subtitle>
      <strong>Smelter Return Summary</strong>
    </v-card-subtitle>
    <div v-if="settlement && settlement.shipment_summary">
      <v-row>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            no-cols
            title="Shipment Summary ID"
          >
            <router-link :to="{ name: 'shipment-summary', params: { id: settlement.shipment_summary.id } }">
              {{ settlement.shipment_summary | filterObject('id') }}
            </router-link>
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            no-cols
            title="Shipped Date"
          >
            {{ settlement.shipment_summary | filterObject('shipped_date') | formatDate }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            no-cols
            title="Received Date"
          >
            {{ settlement.shipment_summary | filterObject('received_date') | formatDate }}
          </card-list-item>
        </v-col>
      </v-row>
      <v-expansion-panels
        v-model="expanded"
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            Show Details
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Notes <v-btn
                    x-small
                    icon
                    color="blue"
                    @click="editNotes = !editNotes"
                  >
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-list-item-subtitle>
                <span v-if="settlement.shipment_summary">
                  {{ settlement.shipment_summary.return_notes }}
                </span>
              </v-list-item-content>
            </v-list-item>
            <read-summary
              :shipment-summaries="[settlement]"
              :shipment-summary-lot="lot.shipment_summary_lot"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <card-list-item title="No Settlement Found" />
      <lot-settlement-dialog
        v-if="$store.getters.can('create-lots')"
        :lot="lot"
        @fetchLot="$emit('fetchLot')"
      />
      <!-- ^ TODO: Re-add status check when shipment summaries are uploaded && lot.status === 'RE'   -->
    </div>
    <edit-shipment-summary-dialog
      v-if="$store.getters.can('edit-shipment-summary') && settlement && settlement.shipment_summary"
      :shipment-summary-id="settlement.shipment_summary.id"
      v-slot:default="{ toggle }"
    >
      <v-btn
        v-if="expanded === 0"
        color="primary"
        small
        absolute
        fab
        bottom
        right
        @click="toggle"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </edit-shipment-summary-dialog>
    <edit-dialog
      v-model="editNotes"
      headline-text="Edit Notes"
      @accept="saveNotes"
    >
      <v-text-field
        v-model="notes"
        label="Smelter Return Notes"
      />
    </edit-dialog>
  </v-card>
</template>

<script>
import ReadSummary from '@/views/ShipmentSummaries/components/Read/ReadSummary'
import LotSettlementDialog from './LotSettlementDialog'
import EditShipmentSummaryDialog from '@/views/ShipmentSummaries/components/Read/components/EditShipmentSummaryDialog.vue'
import axios from 'axios'
import shipmentSummary from '../../../ShipmentSummaries/components/Read/ShipmentSummary.vue'

export default {
  name: 'LotSettlementOverview',
  computed: {
    shipmentSummary () {
      return shipmentSummary
    }
  },
  props: {
    settlement: { type: Object, required: false, default: null },
    lot: { type: Object, required: true }
  },
  components: { EditShipmentSummaryDialog, ReadSummary, LotSettlementDialog },
  data: () => ({
    editNotes: false,
    expanded: false,
    notes: ''
  }),
  methods: {
    saveNotes () {
      const id = this.settlement?.shipment_summary?.id
      if (!id) {
        this.$store.commit('setSnackbarError', 'Settlement id missing')
        return
      }
      axios.patch(`/api/shipment_summaries/${id}/`, {
        return_notes: this.notes
      }).then(() => {
        this.$emit('fetchLot')
        this.$store.commit('setSnackbarSuccess', 'Updated settlement notes')
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Failed to save changes')
      })
    }
  }
}
</script>
