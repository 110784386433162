<template>
  <v-simple-table dense>
    <tbody>
      <tr>
        <td>Total Gross Metals Value</td>
        <td><dollar-sign />{{ report.charges_and_values.total_gross_metals_value | toFixed(2) | addCommas }}</td>
      </tr>
      <tr>
        <td class="font-weight-light">
          Treatment Charge
        </td>
        <td><dollar-sign />{{ report.charges_and_values.treatment_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td class="font-weight-light">
          Lab/Assay Charge
        </td>
        <td><dollar-sign />{{ report.charges_and_values.lab_assay_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td class="font-weight-light">
          Refining Charge
        </td>
        <td><dollar-sign />{{ report.charges_and_values.refining_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td class="font-weight-light">
          Small Lot Charge
        </td>
        <td><dollar-sign />{{ report.charges_and_values.small_lot_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Total Charges</td>
        <td><dollar-sign />{{ report.charges_and_values.total_charges | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Smelter Finance Charge</td>
        <td><dollar-sign />{{ report.charges_and_values.smelter_finance_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Smelter Total Final Payment</td>
        <td><dollar-sign />{{ report.charges_and_values.smelter_total_final_payment | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>LOC Finance</td>
        <td><dollar-sign />{{ report.charges_and_values.loc_finance_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Smelter Freight</td>
        <td><dollar-sign />{{ report.charges_and_values.freight_charge | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>PGM Transit</td>
        <td><dollar-sign />{{ report.charges_and_values.transit_total_cost | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
      <tr>
        <td>Total Revenue</td>
        <td><dollar-sign />{{ report.charges_and_values.total_revenue | toFixed(2) | filterNA | addCommas }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'FinalReportValues',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
tbody tr {
  text-align: right;
}
tbody tr td:first-child {
  font-weight: bold;
}
tbody tr td:last-child {
  text-align: right;
  max-width: 50px;
}
</style>
