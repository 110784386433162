<template>
  <v-simple-table
    dense
  >
    <tbody>
      <tr>
        <td class="text-right font-weight-bold text-caption">
          Lot #:
        </td>
        <td>
          {{ report.lot_numbers | filterNA }}
        </td>
        <td class="text-right font-weight-bold text-caption">
          Client:
        </td>
        <td>
          {{ report.client_names | filterNA }}
        </td>
        <td class="text-right font-weight-bold text-caption">
          Ship Date:
        </td>
        <td>
          {{ report.ship_date | filterNA }}
        </td>
      </tr>
      <tr>
        <td class="text-right font-weight-bold text-caption">
          Smelter Lot #:
        </td>
        <td>
          {{ report.smelter_lot_numbers | filterNA }}
        </td>
        <td class="text-right font-weight-bold text-caption">
          Smelter:
        </td>
        <td>
          {{ report.smelter_name | filterNA }}
        </td>
        <td class="text-right font-weight-bold text-caption">
          Smelter Received Date:
        </td>
        <td>
          {{ report.smelter_received_date | filterNA }}
        </td>
      </tr>
      <tr>
        <td class="text-right font-weight-bold text-caption">
          Lot Type:
        </td>
        <td>
          {{ report.lot_type | filterNA }}
        </td>
        <td class="text-right font-weight-bold text-caption">
          Assay Date:
        </td>
        <td>
          {{ report.assay_date | filterNA }}
        </td>
        <td class="text-right font-weight-bold text-caption">
          Lot Value Date:
        </td>
        <td>
          {{ report.lot_value_date | filterNA }}
        </td>
      </tr>
      <tr>
        <td colspan="4" />
        <td class="text-right font-weight-bold text-caption">
          Smelter Payment Date:
        </td>
        <td>
          {{ report.smelter_payment_date | filterNA }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>

export default {
  name: 'FinalReportOverview',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatDuplicates (item) {
      return [...new Set(item.split(','))].join(', ')
    }
  }
}
</script>
