<!--
Attempts to load the manifest by the lot id, if does not exist prompt to create or attach.
-->
<template>
  <unauthorized
    :authorized="$store.getters.can('view-lot-manifests')"
    message="Unauthorized to view lab entry"
  >
    <v-container v-if="!loading">
      <url-breadcrumbs
        :items="[
          ['lot Manifest List', { name: 'lot-manifest-list' }],
          ['Lot Manifest']
        ]"
      />
      <!--Hidden on print-->
      <div class="d-print-none">
        <v-row>
          <v-col>
            <base-title>
              Lot Manifest #{{ manifest.id }}
            </base-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list>
              <card-list-item
                title="Lots"
              >
                {{ manifest.lots__details.map(l => l.name).join(', ') }}
              </card-list-item>
              <card-list-item
                v-if="manifest.transit"
                title="Transit"
              >
                <router-link-id
                  name="transit-view"
                  :id="manifest.transit_id"
                >
                  Transit #{{ manifest.transit_id }}
                </router-link-id>
              </card-list-item>
              <card-list-item
                v-else
                title="Transit Requested"
              >
                {{ manifest.transit_requested ? 'Yes' : 'No' }}
              </card-list-item>
              <card-list-item
                title="Location"
              >
                <location-block
                  v-if="currentLocation"
                  :location="currentLocation"
                />
                <span v-else>Not Available</span>
              </card-list-item>
              <card-list-item
                title="Destination"
              >
                <location-block
                  v-if="toLocation"
                  :location="toLocation"
                />
                <span v-else>Not Available</span>
              </card-list-item>
              <card-list-item
                title="Skid Count"
              >
                {{ skidCount }}
              </card-list-item>
              <card-list-item
                title="Weight"
              >
                {{ weight }}lbs
              </card-list-item>
              <card-list-item
                title="Ship Date"
              >
                {{ shipDate | formatDate }}
              </card-list-item>
              <card-list-item
                title="Smelter"
              >
                {{ manifest.smelter__name }}
              </card-list-item>
              <card-list-item
                title="Approval Status"
              >
                <span v-if="manifest.transit_requested_at">
                  Transit Requested on {{ manifest.transit_requested_at | formatDate }}
                </span>
                <span v-else-if="manifest.approval_granted_at">
                  Approval Granted on {{ manifest.approval_granted_at | formatDate }}
                </span>
                <span v-else-if="manifest.approval_requested_at">
                  Approval Requested on {{ manifest.approval_requested_at | formatDate }}
                </span>
                <span v-else>
                  Not available
                </span>
              </card-list-item>
            </v-list>
          </v-col>
        </v-row>
      </div>
      <v-row
        class="text-center"
      >
        <v-img
          :src="config.logo"
          :alt="config.name"
          height="100px"
          contain
        />
        <v-col cols="12">
          <h4>{{ config.address }}</h4>
        </v-col>
      </v-row>
      <div class="pb-8" />
      <lot-manifest-table
        v-if="manifest"
        :manifest-lines="manifestLines"
      />
      <base-speed-dial
        direction="left"
        icon="mdi-pencil"
        open-on-hover
      >
        <v-btn
          :disabled="!$store.getters.can('delete-lot-manifests') || manifest.transit"
          fab
          small
          color="error"
          @click="deleteManifest"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          rounded
          color="primary"
          @click="transitDialog = !transitDialog"
        >
          Edit Transit Request
          <v-icon class="ml-2">
            mdi-truck
          </v-icon>
        </v-btn>
      </base-speed-dial>
      <edit-dialog
        v-model="transitDialog"
        headline-text="Request Transit"
        accept-text="Save"
        @accept="updateTransit"
      >
        <em>Blank locations will not be changed</em>
        <v-row>
          <v-col
            cols="12"
          >
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <base-location-field
                v-model="currentLocation"
                label="Change Origin"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <base-location-field
                v-model="toLocation"
                label="Change Destination"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <rules-text
              v-model="skidCount"
              label="Skid Count"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <rules-text
              v-model="weight"
              label="Weight"
              type="number"
              append-icon="mdi-weight-pound"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <rules-date
              v-model="shipDate"
              label="Ship Date"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="smelter"
              :items="smelters"
              label="Smelter"
              item-text="name"
              item-value="url"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="!manifest.approval_requested_at">
              <v-checkbox
                :disabled="!$store.getters.can('submit-approval-request')"
                label="Submit for Approval"
                v-model="submitForApproval"
              />
              <em>
                Sends message to the transit review group
              </em>
            </div>
            <div
              v-if="manifest.approval_requested_at && !manifest.transit_requested_at"
            >
              <!-- todo allow only coordinator to approve -->
              <v-checkbox
                :disabled="!$store.getters.can('submit-transit-request')"
                label="Approve and Request Transit"
                v-model="approveAndRequestTransit"
              />
              <em>
                Sends message to the transit group
              </em>
            </div>
          </v-col>
        </v-row>
      </edit-dialog>
    </v-container>
  </unauthorized>
</template>

<script>
import BaseSpeedDial from '@/components/BaseSpeedDial.vue'
import {
  deleteLotManifestById,
  fetchLotManifestById,
  submitForApproval, submitTransitRequest,
  updateLotManifestById
} from '@/requests/logistics'
import LotManifestTable from '@/views/Lots/components/LotManifest/LotManifestTable.vue'
import BaseLocationField from '@/components/BaseLocationField.vue'
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock.vue'
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import { fetchAllSmelters } from '@/requests/smelters'
import config from '@/../izzi_config'

export default {
  name: 'LotManifest',
  components: { RulesDate, RulesText, LocationBlock, BaseLocationField, LotManifestTable, BaseSpeedDial },
  data: () => ({
    config,
    // Flags for calling email hooks
    submitForApproval: false,
    approveAndRequestTransit: false,

    skidCount: 0,
    weight: 0,
    smelter: null,
    shipDate: null,
    currentLocation: null,
    toLocation: null,
    transitRequested: false,
    transitDialog: false,
    loading: true,
    lot: null,
    manifest: [],
    smelters: [],
    headers: [
      { text: 'Lot', value: 'id' },
      { text: 'Estimated ctoz Pt', value: 'estimated_pt', align: 'right' },
      { text: 'Estimated ctoz Pd', value: 'estimated_pd', align: 'right' },
      { text: 'Estimated ctoz Rh', value: 'estimated_rh', align: 'right' },
      { text: 'Ship Date', value: 'ship_date' }
    ]
  }),
  computed: {
    manifestLines () {
      let carry = []
      this.manifest.lots__details.forEach(lot => {
        if (!lot.lines) return
        lot.lines.forEach(line => {
          carry.push(Object.assign({ lot: lot.name }, line))
        })
      })
      return carry
    },
    lots () {
      if (this.manifest && this.manifest.lots__details) {
        return this.manifest.lots__details
      } else {
        return []
      }
    }
  },
  watch: {
    transitDialog (value) {
      if (value) {
        fetchAllSmelters().then(response => {
          this.smelters = response.data
        })
      }
    }
  },
  methods: {
    async updateTransit () {
      const payload = {
        transit_requested: this.transitRequested,
        current_location: this.currentLocation?.url,
        to_location: this.toLocation?.url,
        skid_count: this.skidCount,
        weight: this.weight,
        smelter: this.smelter,
        ship_date: this.shipDate
      }

      await updateLotManifestById(this.$route.params.id, payload)
      this.$store.commit('setSnackbarSuccess', 'Changes Saved')

      if (this.submitForApproval) {
        const r = await submitForApproval(this.$route.params.id)
        if (r.status === 201) this.$store.commit('setSnackbarSuccess', 'Submitted for Approval')
      }
      if (this.approveAndRequestTransit) {
        const r = await submitTransitRequest(this.$route.params.id)
        if (r.status === 201) this.$store.commit('setSnackbarSuccess', 'Submitted for Transit')
      }

      await this.fetchManifest()
    },
    deleteManifest () {
      if (confirm('Delete lot manifest?')) {
        deleteLotManifestById(this.manifest.id).then(() => {
          this.$router.push({ name: 'lot-manifest-list' })
        }).catch(() => {
          this.$store.commit('setSnackbarError', 'Failed to delete lot manifest')
        })
      }
    },
    initializePage () {
      this.fetchLot(this.$route.params.id).then(() => {
        this.loading = false
      })
    },
    /* Search for a manifest for this lot */
    async fetchManifest () {
      const response = await fetchLotManifestById(this.$route.params.id)
      const data = response.data
      this.manifest = data
      this.currentLocation = data.current_location__data
      this.toLocation = data.to_location__data
      this.skidCount = data.skid_count
      this.weight = data.weight
      this.smelter = data.smelter
      this.shipDate = data.ship_date
      return data
    }
  },
  async mounted () {
    // first we ge the manifest, if none exists then fetch the lot and prompt to create the manifest
    await this.fetchManifest()
    this.loading = false
    if (this.$route.query.request) {
      this.transitDialog = true
    }
  }
}
</script>
