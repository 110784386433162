<!--
Creates a v-list of the provided object
-->
<template>
  <v-list>
    <v-list-item
      v-for="key in Object.keys(value).filter(v => !exclude.includes(v))"
      :key="key"
    >
      <v-list-item-content>
        <v-list-item-title
          class="title"
        >
          {{ key.replaceAll('_', ' ') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ value[key] }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'ObjectDumpList',
  props: {
    value: {
      type: Object,
      required: true
    },
    exclude: {
      type: Array,
      default: Array
    }
  }
}
</script>
