<template>
  <v-container>
    <base-title
      class="pa-2"
      title="Import Stillwater Shipment Summary"
    />
    <ul>
      <li>
        Option 1. Upload the PDF document
        <ol>
          <li>
            Click the <strong>File Input</strong> and select the file
          </li>
        </ol>
      </li>
      <li>
        Option 2. Upload an EXCEL export of the PDF document
        <ol>
          <li>
            Open the PDF in Adobe Acrobat Reader
          </li>
          <li>
            Select <strong>File</strong> from the title bar
          </li>
          <li>
            Select <strong>Convert to Word, Excel and Power Point</strong>
          </li>
          <li>
            Change <strong>Convert To: Microsoft Excel Document (*.xlsx)</strong>
          </li>
          <li>
            Select <strong>Export to Excel</strong>
          </li>
          <li>
            Click the <strong>File Input</strong> and select the file
          </li>
        </ol>
      </li>
    </ul>
    <v-file-input
      v-model="importFile"
      :single-line="true"
      multiple
      label="File input"
    />
    <v-spacer />
    <ul>
      <li>
        Option 3. Copy and Paste the text
        <ol>
          <li>
            Open the PDF in Adobe Acrobat Reader
          </li>
          <li>
            Click anywhere on the document
          </li>
          <li>
            Press <strong>CTRL + A</strong> to select all text
          </li>
          <li>
            Press <strong>CTRL + C</strong> to copy selected text
          </li>
          <li>
            Select the text field below
          </li>
          <li>
            Press <strong>CTRL + V</strong> to paste copied text
          </li>
        </ol>
      </li>
    </ul>
    <v-form>
      <v-container>
        <v-text-field
          v-model="importText"
          prepend-icon="mdi-cursor-text"
          label="Text Input"
        />
      </v-container>
    </v-form>
    <v-row
      justify="center"
      class="mx-0"
    >
      <base-button
        auto-center
        label="Upload"
        color="success"
        text
        :loading="loading"
        @clicked="importSummary()"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImportStillwater',
  props: {
    selectedLots: { type: Array, required: true },
    smelter: { type: Object, required: false, default: null }
  },
  data: () => ({
    importFile: null,
    importText: '',
    loading: false
  }),
  methods: {
    /** Stillwater Shipment Summary Doc
     * Creates a shipment summary & dispatches doc upload with returned id
     * Creates shipment summary lots and attaches lots in store
     */
    importSummary () {
      this.loading = true
      let body = { smelter_id: this.smelter.id }
      this.$store.dispatch('shipmentSummaryStore/createShipmentSummary', body).then((id) => {
        if (id && id !== null) {
          this.$store.dispatch('shipmentSummaryStore/shipmentSummaryUpload', {
            id,
            lots: this.selectedLots,
            file: this.importFile,
            fileText: this.importText
          }).then(() => {
            this.$emit('fetchSum')
            this.importFile = null
            this.importText = ''
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>
