<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-lots')"
    message="Unauthorized to view Lots"
  >
    <base-title title="Shipped Lots">
      <template v-slot:subtitle>
        Lot must be in a status of Shipped or a more advanced stage
      </template>
    </base-title>
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchAdvancedSets"
    >
      <date-range-input
        :start-date="shipStartDate"
        :end-date="shipEndDate"
        start-date-label="Ship Date From"
        end-date-label="Ship Date To"
        @alterStartDate="shipStartDate = $event"
        @alterEndDate="shipEndDate = $event"
      />
      <date-range-input
        :start-date="startReceivedDate"
        :end-date="endReceivedDate"
        start-date-label="Smelter Date From"
        end-date-label="Smelter Date To"
        @alterStartDate="startReceivedDate = $event"
        @alterEndDate="endReceivedDate = $event"
      />
      <date-range-input
        :start-date="startAssayDate"
        :end-date="endAssayDate"
        start-date-label="Result Date From"
        end-date-label="Result Date To"
        @alterStartDate="startAssayDate = $event"
        @alterEndDate="endAssayDate = $event"
      />
      <date-range-input
        :start-date="startReturnDate"
        :end-date="endReturnDate"
        start-date-label="Settlement Date From"
        end-date-label="Settlement Date To"
        @alterStartDate="startReturnDate = $event"
        @alterEndDate="endReturnDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Smelter"
            multiple
            v-model="smelterSet"
            :items="smelters"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Material Types"
            v-model="typeSet"
            multiple
            :items="lotMaterialType"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
        >
          <v-select
            label="Show Empty Ship Date"
            :items="boolOptions"
            v-model="shipDateIsNull"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-select
            label="Show Empty Summary Date"
            :items="boolOptions"
            v-model="ReceivedDateIsNull"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
        >
          <v-select
            label="Show Empty Result Date"
            :items="boolOptions"
            v-model="AssayDateIsNull"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-select
            label="Show Empty Settlement Date"
            :items="boolOptions"
            v-model="ReturnDateIsNull"
            clearable
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:item.name="{ item }">
        <router-link-id
          :id="item.id"
          name="lot"
          :display="item.name"
        />
      </template>
      <template v-slot:item.status="{ item }">
        {{ formatLotStatus(item.status, lotStatuses) }}
      </template>
      <template v-slot:item.ship_date="{ item }">
        {{ item.ship_date | formatDate | filterNA }}
      </template>
      <template v-slot:item.shipment_summary_lot__shipment_summary__received_date="{ item }">
        {{ item.shipment_summary_lot__shipment_summary__received_date | formatDate | filterNA }}
      </template>
      <template v-slot:item.shipment_summary_lot__shipment_summary__assay_date="{ item }">
        {{ item.shipment_summary_lot__shipment_summary__assay_date | formatDate | filterNA }}
      </template>
      <template v-slot:item.shipment_summary_lot__value_date="{ item }">
        {{ item.shipment_summary_lot__value_date | formatDate | filterNA }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-lots')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="$router.push({ name: 'create-lot' })"
    >
      Create Lot
    </text-fab>
    <div class="py-8" />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'LotShippedList',
  components: {
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'lot-shipped-list',

      headers: [
        { text: 'Name', value: 'name', width: 400 },
        { text: 'Smelter', value: 'smelter__name' },
        { text: 'Ship Date', value: 'ship_date' },
        { text: 'Smelter Date', value: 'shipment_summary_lot__shipment_summary__received_date' },
        { text: 'Result Date', value: 'shipment_summary_lot__shipment_summary__assay_date' },
        { text: 'Value Date', value: 'shipment_summary_lot__value_date' }
      ],

      ...this.chipPropagation([
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Smelters', prop: 'smelterSet', type: Array, query: 'smelter__name__in' },
        { name: 'Material Type', prop: 'typeSet', type: Array, query: 'lot_type__in' },
        { name: 'Start Ship Date', prop: 'shipStartDate', type: String, query: 'ship_date__gte' },
        { name: 'End Ship Date', prop: 'shipEndDate', type: String, query: 'ship_date__lte' },
        { name: 'Show Empty Ship Date', prop: 'shipDateIsNull', type: String, query: 'ship_date__isnull' },

        { name: 'Start Summary Date', prop: 'startReceivedDate', type: String, query: 'shipment_summary_lot__shipment_summary__received_date__gte' },
        { name: 'End Summary Date', prop: 'endReceivedDate', type: String, query: 'shipment_summary_lot__shipment_summary__received_date__lte' },
        { name: 'Show Empty Summary Date', prop: 'ReceivedDateIsNull', type: String, query: 'shipment_summary_lot__shipment_summary__received_date__isnull' },

        { name: 'Start Result Date', prop: 'startAssayDate', type: String, query: 'shipment_summary_lot__shipment_summary__assay_date__gte' },
        { name: 'End Result Date', prop: 'endAssayDate', type: String, query: 'shipment_summary_lot__shipment_summary__assay_date__lte' },
        { name: 'Show Empty Result Date', prop: 'AssayDateIsNull', type: String, query: 'shipment_summary_lot__shipment_summary__assay_date__isnull' },

        { name: 'Start Settlement Date', prop: 'startReturnDate', type: String, query: 'shipment_summary_lot__value_date__gte' },
        { name: 'End Settlement Date', prop: 'endReturnDate', type: String, query: 'shipment_summary_lot__value_date__lte' },
        { name: 'Show Empty Settlement Date', prop: 'ReturnDateIsNull', type: String, query: 'shipment_summary_lot__value_date__isnull' }
      ]),

      divisions: [],
      smelters: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      lotDesignations: 'lotStore/lotTypes',
      lotMaterialType: 'lotStore/lotMaterialType'
    })
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        // 'url',
        'id',
        // 'code',
        // 'inventory',
        // 'smelter',
        'smelter__name',
        'name',
        'status',
        // 'designation',
        'ship_date',
        // 'piece_count',
        // 'lot_type',
        // 'estimated_pt',
        // 'estimated_pd',
        // 'estimated_rh',
        // 'estimated_returnable_pt',
        // 'estimated_returnable_pd',
        // 'estimated_returnable_rh',
        // 'contained_pt',
        // 'contained_pd',
        // 'contained_rh',
        // 'returnable_pt',
        // 'returnable_pd',
        // 'returnable_rh',
        // 'notes',
        // 'outbound_transit',
        'shipment_summary_lot__shipment_summary__received_date',
        'shipment_summary_lot__shipment_summary__assay_date',
        'shipment_summary_lot__value_date'
        // 'ppm_calculator',
        // 'allocations',
        // 'cut_plan_color',
        // 'created_at'
        // 'yard'
      ].join(',')
      params.status__in = 'SH,RE,RC,CO'
      axios.get('/api/lots/', { params }).then(this.setResponseResults)
    },
    fetchAdvancedSets () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name,code').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.code }))
        })
      }
      if (this.smelters.length === 0) {
        axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
          this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.name }))
        })
      }
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
