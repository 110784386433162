<template>
  <v-container>
    <base-title
      class="pa-2"
      title="Import BASF Shipment Summary"
    />
    <ul>
      <li>
        Option 1. Upload the CSV document available from the BASF web portal
        <ol>
          <li>
            Click the <strong>File Input</strong> and select the file to continue
          </li>
        </ol>
      </li>
    </ul>
    <v-file-input
      v-model="importFile"
      :single-line="true"
      multiple
      label="File input"
    />
    <v-row
      justify="center"
      class="mx-0"
    >
      <base-button
        auto-center
        label="Upload"
        color="success"
        text
        :loading="loading"
        @clicked="importSummary()"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImportBasf',
  props: {
    selectedLots: { type: Array, required: true },
    smelter: { type: Object, required: false, default: null }
  },
  data: () => ({
    importFile: null,
    importText: '',
    loading: false
  }),
  methods: {
    /** Stillwater Shipment Summary Doc
     * Creates a shipment summary & dispatches doc upload with returned id
     * Creates shipment summary lots and attaches lots in store
     */
    importSummary () {
      this.loading = true
      let body = { smelter_id: this.smelter.id }
      this.$store.dispatch('shipmentSummaryStore/createShipmentSummary', body).then((id) => {
        if (id && id !== null) {
          this.$store.dispatch('shipmentSummaryStore/shipmentSummaryUpload', {
            id,
            lots: this.selectedLots,
            file: this.importFile,
            fileText: this.importText
          }).then(() => {
            this.$emit('fetchSum')
            this.importFile = null
            this.importText = ''
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>
