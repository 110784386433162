<template>
  <div>
    <div class="subtitle-2 text-center">
      Allocations
    </div>
    <v-data-table
      v-if="allocations.length > 0"
      multi-sort
      single-expand
      show-expand
      :expanded.sync="expanded"
      :headers="allocationTableHeaders"
      :items="allocations"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :items-per-page="5"
    >
      <template v-slot:item.hedge="{ item }">
        {{ item.hedge | filterObject('id') }}
      </template>
      <template v-slot:item.pt="{ item }">
        <div class="pt-color text-right">
          {{ item | filterObject('pt') | trimDecimals | addCommas | appendRtoz }}
        </div>
      </template>
      <template v-slot:item.pd="{ item }">
        <div class="pd-color text-right">
          {{ item | filterObject('pd') | trimDecimals | addCommas | appendRtoz }}
        </div>
      </template>
      <template v-slot:item.rh="{ item }">
        <div class="rh-color text-right">
          {{ item | filterObject('rh') | trimDecimals | addCommas | appendRtoz }}
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="allocationTableHeaders.length++">
          <v-container>
            <div class="text-center">
              <v-row>
                <v-col class="pt-color text-right">
                  <fx-tag
                    :fx="item.hedge.pt_rate_per_toz_fx"
                    :value="item.hedge.pt_rate_per_toz"
                    :cur="item.hedge.currency"
                  /><br>x {{ item.pt }} rtoz
                  <hr>
                  <fx-tag
                    :fx="item.pt * item.hedge.pt_rate_per_toz_fx"
                    :value="item.pt * item.hedge.pt_rate_per_toz"
                    :cur="item.hedge.currency"
                  />
                  <br>
                </v-col>
                <v-col class="pd-color text-right">
                  <fx-tag
                    :fx="item.hedge.pd_rate_per_toz_fx"
                    :value="item.hedge.pd_rate_per_toz"
                    :cur="item.hedge.currency"
                  /><br>x {{ item.pd }} rtoz
                  <hr>
                  <fx-tag
                    :fx="item.pd * item.hedge.pd_rate_per_toz_fx"
                    :value="item.pd * item.hedge.pd_rate_per_toz"
                    :cur="item.hedge.currency"
                  />
                  <br>
                </v-col>
                <v-col class="rh-color text-right">
                  <fx-tag
                    :fx="item.hedge.rh_rate_per_toz_fx"
                    :value="item.hedge.rh_rate_per_toz"
                    :cur="item.hedge.currency"
                  /><br>x {{ item.rh }} rtoz
                  <hr>
                  <fx-tag
                    :fx="item.rh * item.hedge.rh_rate_per_toz_fx"
                    :value="item.rh * item.hedge.rh_rate_per_toz"
                    :cur="item.hedge.currency"
                  />
                </v-col>
              </v-row>
              <v-row>
                <div
                  class="text-left font-weight-bold pr-2"
                >
                  Total:<br>
                  Created:<br>
                  Expires:<br>
                  Notes:<br>
                </div>
                <div
                  class="text-left"
                >
                  {{ calculateTotalRoz(item) | addCommas | trimDecimals | dollarSign }}<br>
                  {{ item.hedge | filterObject('created_at') | formatDate }}<br>
                  {{ item.hedge | filterObject('expires_on') | formatDate }}<br>
                  {{ item.hedge | filterObject('memo') | filterNA }}<br>
                </div>
              </v-row>
            </div>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <div
      v-else
      class="text-center pb-4 font-weight-thin"
    >
      No Allocations Found
    </div>
    <hedge-allocation-dialog
      v-if="canAddAllocation()"
      :unallocated-metals="allUnallocatedMetals()"
      :lot="lot.id"
      :hedges="hedges"
      @addedAllocation="$emit('addedAllocation')"
    />
  </div>
</template>

<script>
import HedgeAllocationDialog from './HedgeAllocationDialog'

export default {
  name: 'Allocations',
  components: {
    HedgeAllocationDialog
  },
  props: {
    allocations: { type: Array, required: true },
    finalToz: { type: Object, required: true },
    hedges: { type: Array, required: true },
    lot: { type: Object, required: true }
  },
  data: () => ({
    expanded: [],
    allocationTableHeaders: [
      { text: 'Hedge', sortable: true, value: 'hedge' },
      { text: 'Platinum', sortable: true, value: 'pt' },
      { text: 'Palladium', sortable: true, value: 'pd' },
      { text: 'Rhodium', sortable: true, value: 'rh' }
    ]
  }),
  methods: {
    // Returns the total hedge dollar amount for returnable
    calculateTotalRoz (item) {
      let totalPt = (item.pt * item.hedge.pt_rate_per_toz)
      let totalPd = (item.pd * item.hedge.pd_rate_per_toz)
      let totalRh = (item.rh * item.hedge.rh_rate_per_toz)
      let totalAmount = (totalPt + totalRh + totalPd)
      return totalAmount.toFixed(2)
    },
    /**
     * Is the user able to add an allocation
     * Are there any ounces that need to be hedged
     * @return {Boolean} canAddAllocation
     */
    canAddAllocation () {
      const unallocated = Object.values(this.allUnallocatedMetals()).reduce((accu, val) => {
        accu += parseFloat(val)
        return accu
      }, 0)
      return unallocated > 0
    },
    /**
     * Get the amount of a metal left unhedged
     * @param {String} metal
     */
    unallocatedMetal (metal) {
      metal = metal.toLowerCase()
      const ouncesAllocated = this.allocations.filter(a => a.status !== 'HIS').reduce((accu, alloc) => {
        if (metal === 'pt') {
          accu += parseFloat(alloc.pt)
        } else if (metal === 'pd') {
          accu += parseFloat(alloc.pd)
        } else {
          accu += parseFloat(alloc.rh)
        }
        return accu
      }, 0)
      return (this.finalToz[metal] - ouncesAllocated)
    },
    /**
     * Returns all unallocated metals in an object
     */
    allUnallocatedMetals () {
      return {
        pt: this.unallocatedMetal('pt'),
        pd: this.unallocatedMetal('pd'),
        rh: this.unallocatedMetal('rh')
      }
    }
  }
}
</script>
