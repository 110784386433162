var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateReport($event)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_vm._v(" Edit Summary Values "+_vm._s(_vm.shipmentSummary.id)+" "),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-card-text',[_c('date-input',{attrs:{"label":"Smelter Payment Date"},model:{value:(_vm.smelterPaymentDate),callback:function ($$v) {_vm.smelterPaymentDate=$$v},expression:"smelterPaymentDate"}}),_c('ValidationProvider',{attrs:{"rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Smelter Finance Charge","error-messages":errors,"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.financeCharge),callback:function ($$v) {_vm.financeCharge=_vm._n($$v)},expression:"financeCharge"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Line of Credit Finance Charge","error-messages":errors,"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.locFinanceCharge),callback:function ($$v) {_vm.locFinanceCharge=_vm._n($$v)},expression:"locFinanceCharge"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Freight Charge","error-messages":errors,"prepend-inner-icon":"mdi-currency-usd"},model:{value:(_vm.freightCharge),callback:function ($$v) {_vm.freightCharge=_vm._n($$v)},expression:"freightCharge"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary","type":"submit"}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1),_vm._t("default",null,{"toggle":function () { _vm.dialog = !_vm.dialog }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }