import factories from './factories'
const { factory, fetchMixin } = factories

const store = factory(
  {
    entityName: 'report',
    urlTemplate: id => `api/lots/${id}/finance/`
  },
  fetchMixin
)

export default store
