var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-expansion-panels',{attrs:{"accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"v-card__subtitle font-weight-bold"},[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" Allocated Transits "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm.totalWeight))+" lbs ")]),_vm._l((_vm.grandTotals),function(value,key){return _c('div',{key:key,staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyV")(value,key))+" ")])})],2)])]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transits},scopedSlots:_vm._u([{key:"item.transit_id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"name":"transit-view","id":item.transit_id}})]}},{key:"item.transit_pickup_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.transit_pickup_date))+" ")]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addCommas")(item.weight))+" lbs ")]}},{key:"item.allocation_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.allocation_percent * 100 ))+"% ")]}},{key:"item.allocation_value",fn:function(ref){
var item = ref.item;
return [_c('fx-tag',{attrs:{"fx":item.allocation_value_fx}})]}},{key:"item.fees_value",fn:function(ref){
var item = ref.item;
return [_c('fx-tag',{attrs:{"fx":item.fees_value_fx}})]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v(" Totals ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm.totalWeight))+" lbs ")]),_c('td'),_c('td',{staticClass:"text-right"},_vm._l((_vm.totalCurrencies),function(value,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyV")(value,key))+" ")])}),0),_c('td',{staticClass:"text-right"},_vm._l((_vm.totalFeesCurrencies),function(value,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyV")(value,key))+" ")])}),0)])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }