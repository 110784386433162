<template>
  <div>
    <v-card-subtitle class="subtitle-1 pb-0 font-weight-bold">
      Contained Assays
    </v-card-subtitle>
    <div
      v-for="(lot, index) in shipmentSummaries"
      :key="index"
    >
      <div v-if="shipmentSummaries.length > 1">
        <b class="ml-3 mt-5">{{ `Lot ${lot.internal_lot_id}` }}</b>
        <v-divider class="ma-2" />
      </div>
      <v-row>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="PT PPM"
            no-cols
          >
            {{ lot.contained_pt_ppm | toFixed(3) | filterZero }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="PD PPM"
            no-cols
          >
            {{ lot.contained_pd_ppm | toFixed(3) | filterZero }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="RH PPM"
            no-cols
          >
            {{ lot.contained_rh_ppm | toFixed(3) | filterZero }}
          </card-list-item>
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <card-list-item
            title="Carbon Percent"
            no-cols
          >
            {{ lot.carbon_percent | toFixed(2) | filterZero | appendPercent }}
          </card-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainedAssays',
  props: {
    shipmentSummaries: {
      type: Array,
      required: true
    }
  }
}
</script>
