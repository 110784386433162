<template>
  <v-card>
    <v-card-subtitle>
      <strong>Designated Hedges</strong>
    </v-card-subtitle>
    <v-data-table
      single-expand
      show-expand
      :expanded.sync="expanded"
      :headers="hedgeTableHeaders"
      :items="hedges"
      :items-per-page="5"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="Number(item.id)"
          name="hedge"
        />
      </template>
      <template v-slot:item.pt_initial_toz="{ item }">
        <div class="pt-color text-right">
          {{ item.pt_initial_toz | toFixed(3) | appendToz }}
          <br>
          <fx-tag
            :fx="item.pt_rate_per_toz_fx"
            :value="item.pt_rate_per_toz"
            :cur="item.currency"
          />
        </div>
      </template>
      <template v-slot:item.pd_initial_toz="{ item }">
        <div class="pd-color text-right">
          {{ item.pd_initial_toz | toFixed(3) | appendToz }}
          <br>
          <fx-tag
            :fx="item.pd_rate_per_toz_fx"
            :value="item.pd_rate_per_toz"
            :cur="item.currency"
          />
        </div>
      </template>
      <template v-slot:item.rh_initial_toz="{ item }">
        <div class="rh-color text-right">
          {{ item.rh_initial_toz | toFixed(3) | appendToz }}
          <br>
          <fx-tag
            :fx="item.rh_rate_per_toz_fx"
            :value="item.rh_rate_per_toz"
            :cur="item.currency"
          />
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="hedgeTableHeaders.length++">
          <div
            class="text-center"
            v-if="item.memo"
          >
            Notes: <br>
            {{ item.memo }}
          </div>
          <div
            class="text-center"
            v-else
          >
            Notes: <br>
            <b>No notes have been added</b>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-btn
      color="primary"
      small
      fab
      absolute
      bottom
      right
      @click="editDialog = !editDialog"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-dialog
      v-model="editDialog"
      headline-text="Update Designated Hedges"
      @accept="updateHedges"
    >
      <v-autocomplete
        label="Select Hedge(s)"
        v-model="chipHedges"
        :items="combinedItems"
        :item-text="x => x.id + ' ' + x.code"
        :search-input.sync="search"
        chips
        return-object
        multiple
      >
        <template v-slot:selection="data">
          <v-chip
            close
            @click="data.select"
            @click:close="removeChip(data.item)"
          >
            {{ data.item.id }}  {{ data.item.code }}
          </v-chip>
        </template>
      </v-autocomplete>
    </edit-dialog>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DesignatedHedges',
  data: () => ({
    editDialog: false,
    designatedHedges: [],
    chipHedges: [],
    search: null,
    selectableHedges: [],
    expanded: [],
    timeout: null,
    hedgeTableHeaders: [
      { text: 'Created', value: 'created_at' },
      { text: 'ID', value: 'id' },
      { text: 'Platinum', value: 'pt_initial_toz' },
      { text: 'Palladium', value: 'pd_initial_toz' },
      { text: 'Rhodium', value: 'rh_initial_toz' }
    ]
  }),
  props: {
    hedges: { type: Array, required: true },
    url: { type: String, required: true }
  },
  watch: {
    // watches for input changes on edit Designated Yard
    search (val) {
      if (!val) {
        this.selectableHedges = []
        return
      }
      this.fetchResults(val)
    },
    editDialog (opened) {
      // reset the chips everytime the dialog is opened
      if (opened) this.chipHedges = this.hedges.slice()
    }
  },
  computed: {
    // Adds the chips to the list of selectableHedges so they dont disappear if the search query doesnt match a chip id
    combinedItems () {
      return this.selectableHedges.concat(this.chipHedges)
    }
  },
  methods: {
    // patch request to add or remove designated yards for a lot
    updateHedges () {
      axios.patch(this.url, { designated_hedges: this.chipHedges.map(h => h.id) }).then(() => {
        this.$emit('fetchLot')
      })
    },
    // removes a chip when clicking the close button on it
    removeChip (item) {
      const index = this.chipHedges.map(x => x.id).indexOf(item.id)
      if (index > -1) this.chipHedges.splice(index, 1)
    },
    // fetches new search results based on the value passed into search on a 3ms delay
    fetchResults (val) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const params = { search: val, unfiltered_search: false, fields: 'id,code' }
        const search = await axios.get(`/api/hedges/`, { params })
        this.selectableHedges = search.data.results
      }, 300)
    }
  }
}
</script>
