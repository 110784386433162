<template>
  <edit-dialog
    :value="value"
    headline-text="Update Lot Shipment Summary Fields"
    @accept="patchReport"
    @input="$emit('input', $event)"
  >
    <ValidationObserver
      v-slot="{ invalid }"
    >
      <v-form
        :disabled="!!report.lot_finance_complete"
      >
        <date-input
          label="Smelter Payment Date"
          v-model="smelterPaymentDate"
        />
        <ValidationProvider
          rules="required|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model.number="financeCharge"
            type="number"
            label="Smelter Finance Charge"
            :error-messages="errors"
            prepend-inner-icon="mdi-currency-usd"
          />
        </ValidationProvider>
        <ValidationProvider
          rules="required|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model.number="locFinanceCharge"
            type="number"
            label="LOC Finance Charge"
            :error-messages="errors"
            prepend-inner-icon="mdi-currency-usd"
          />
        </ValidationProvider>
        <ValidationProvider
          rules="required|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model.number="freightCharge"
            type="number"
            label="Freight Charge"
            :error-messages="errors"
            prepend-inner-icon="mdi-currency-usd"
          />
        </ValidationProvider>
      </v-form>
      <v-switch
        v-model="financeComplete"
        :label="financeCompleteText"
      />
    </ValidationObserver>
  </edit-dialog>
</template>

<script>

export default {
  name: 'LotFinanceEdit',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    report: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    smelterPaymentDate: '',
    financeCharge: 0,
    locFinanceCharge: 0,
    freightCharge: 0,
    financeComplete: false
  }),
  watch: {
    value (opened) {
      if (opened) {
        if (this.smelterPaymentDate !== '') {
          this.smelterPaymentDate = new Date(this.report.smelter_payment_date).toISOString().slice(0, 10)
        }
        this.financeCharge = this.report.finance_charge
        this.freightCharge = this.report.freight_charge
        this.locFinanceCharge = this.report.loc_finance_charge
        this.financeComplete = !!this.report.lot_finance_complete
      }
    }
  },
  computed: {
    financeCompleteText () {
      if (this.report.lot_finance_complete) {
        return 'Already marked complete by ' + this.report.lot_finance_complete.username
      }
      return 'Mark this Lot Finance Completed'
    }
  },
  methods: {
    patchReport () {
      const payload = {
        finance_charge: this.financeCharge,
        freight_charge: this.freightCharge,
        loc_finance_charge: this.locFinanceCharge,
        lot_finance_complete: this.financeComplete
      }
      if (this.smelterPaymentDate) payload.smelter_payment_date = this.smelterPaymentDate
      this.$emit('report-updated', payload)
    }
  }
}
</script>
