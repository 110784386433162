<template>
  <v-card>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header class="v-card__subtitle font-weight-bold">
          Pieces by Category from Check In
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-slide-y-transition>
            <div v-show="!loading">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th class="text-right">
                      Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(line, key) in piecesArray"
                    :key="key"
                  >
                    <td>{{ line[0] }}</td>
                    <td class="text-right">
                      {{ line[1] }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="font-weight-bold">
                    <td>Total</td>
                    <td class="text-right">
                      {{ total }}
                    </td>
                  </tr>
                </tfoot>
              </v-simple-table>
            </div>
          </v-slide-y-transition>
          <div v-if="loading">
            <v-skeleton-loader
              type="table-tbody"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LotPiecesByCategory',
  props: {
    lotId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      loading: true,
      pieces: {},
      total: 0
    }
  },
  computed: {
    piecesArray () {
      return Object.keys(this.pieces).reduce((list, key) => {
        list.push([key, this.pieces[key]])
        return list
      }, [])
    }
  },
  methods: {
    /**
     * Loads the intakes from the API
     * @return {Promise<void>}
     */
    async loadCounts () {
      const response = await axios.get(`/api/lots/${this.lotId}/pieces_by_category/`)
      if (response.data) {
        const pieces = response.data
        this.pieces = pieces
        this.total = pieces['total']
        delete pieces['total']
      }
      this.loading = false
    }
  },
  mounted () {
    this.loadCounts()
  }
}
</script>
