var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h3',[_vm._v(" Weights ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('v-select',{attrs:{"label":"Material Type","items":_vm.converterTypes,"item-text":"text","item-value":"value"},model:{value:(_vm.materialType),callback:function ($$v) {_vm.materialType=$$v},expression:"materialType"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Gross Received LBS","type":"number","error-messages":errors},model:{value:(_vm.grossLbs),callback:function ($$v) {_vm.grossLbs=$$v},expression:"grossLbs"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tare LBS","type":"number","persistent-hint":"","hint":"Scrap from Decanning","error-messages":errors},model:{value:(_vm.tareLbs),callback:function ($$v) {_vm.tareLbs=$$v},expression:"tareLbs"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Net Wet Received LBS","type":"number","error-messages":errors},model:{value:(_vm.netWetLbs),callback:function ($$v) {_vm.netWetLbs=$$v},expression:"netWetLbs"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Scrap LBS","type":"number","error-messages":errors},model:{value:(_vm.scrapLbs),callback:function ($$v) {_vm.scrapLbs=$$v},expression:"scrapLbs"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Adjusted Net Wet Received LBS *","error-messages":errors},model:{value:(_vm.adjNetLbs),callback:function ($$v) {_vm.adjNetLbs=$$v},expression:"adjNetLbs"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:100|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Moisture Percentage *","error-messages":errors,"type":"number"},model:{value:(_vm.percentMoisture),callback:function ($$v) {_vm.percentMoisture=$$v},expression:"percentMoisture"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Moisture Measurement *","items":_vm.measurementTypes,"error-messages":errors,"item-text":"text","item-value":"value"},model:{value:(_vm.moistureMeasurement),callback:function ($$v) {_vm.moistureMeasurement=$$v},expression:"moistureMeasurement"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Net Dry LBS","type":"number","error-messages":errors},model:{value:(_vm.netDryLbs),callback:function ($$v) {_vm.netDryLbs=$$v},expression:"netDryLbs"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999999999999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Sample Weight LBS","type":"number","error-messages":errors},model:{value:(_vm.sampleWeight),callback:function ($$v) {_vm.sampleWeight=$$v},expression:"sampleWeight"}})]}}],null,true)})],1)],1),_c('h3',[_vm._v(" Contained Assays ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PT PPM","type":"number","error-messages":errors},model:{value:(_vm.cPtPpm),callback:function ($$v) {_vm.cPtPpm=$$v},expression:"cPtPpm"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PD PPM","type":"number","error-messages":errors},model:{value:(_vm.cPdPpm),callback:function ($$v) {_vm.cPdPpm=$$v},expression:"cPdPpm"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"min_value:0|max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"RH PPM","type":"number","error-messages":errors},model:{value:(_vm.cRhPpm),callback:function ($$v) {_vm.cRhPpm=$$v},expression:"cRhPpm"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"min_value:0|max_value:100|decimal:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Carbon Percent","error-messages":errors,"append-icon":"mdi-percent-outline"},model:{value:(_vm.percentCarbon),callback:function ($$v) {_vm.percentCarbon=$$v},expression:"percentCarbon"}})]}}],null,true)})],1)],1),_c('h3',[_vm._v(" Contained Metals ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PT Troy Ounce","type":"number","error-messages":errors},on:{"keyup":function($event){_vm.rPtToz = _vm.calcMetal('contained', _vm.cPtToz, _vm.smelter.pt_return_rate)}},model:{value:(_vm.cPtToz),callback:function ($$v) {_vm.cPtToz=$$v},expression:"cPtToz"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PD Troy Ounce","type":"number","error-messages":errors},on:{"keyup":function($event){_vm.rPdToz = _vm.calcMetal('contained', _vm.cPdToz, _vm.smelter.pd_return_rate)}},model:{value:(_vm.cPdToz),callback:function ($$v) {_vm.cPdToz=$$v},expression:"cPdToz"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value: 99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"RH Troy Ounce","type":"number","error-messages":errors},on:{"keyup":function($event){_vm.rRhToz = _vm.calcMetal('contained', _vm.cRhToz, _vm.smelter.rh_return_rate)}},model:{value:(_vm.cRhToz),callback:function ($$v) {_vm.cRhToz=$$v},expression:"cRhToz"}})]}}],null,true)})],1)],1),_c('h3',[_vm._v(" Returnable Ounces ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PT Troy Ounce","error-messages":errors},on:{"keyup":function($event){_vm.cPtToz = _vm.calcMetal('returnable', _vm.rPtToz, _vm.smelter.pt_return_rate)}},model:{value:(_vm.rPtToz),callback:function ($$v) {_vm.rPtToz=$$v},expression:"rPtToz"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PD Troy Ounce","type":"number","error-messages":errors},on:{"keyup":function($event){_vm.cPdToz = _vm.calcMetal('returnable', _vm.rPdToz, _vm.smelter.pd_return_rate)}},model:{value:(_vm.rPdToz),callback:function ($$v) {_vm.rPdToz=$$v},expression:"rPdToz"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimal:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"RH Troy Ounce","type":"number","error-messages":errors},on:{"keyup":function($event){_vm.cRhToz = _vm.calcMetal('returnable', _vm.rRhToz, _vm.smelter.rh_return_rate)}},model:{value:(_vm.rRhToz),callback:function ($$v) {_vm.rRhToz=$$v},expression:"rRhToz"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDate(_vm.valueDate),"label":"Value Date","readonly":"","append-icon":"mdi-calendar-month"}},on))]}}],null,true),model:{value:(_vm.valueDateDialog),callback:function ($$v) {_vm.valueDateDialog=$$v},expression:"valueDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.valueDateDialog = false}},model:{value:(_vm.valueDate),callback:function ($$v) {_vm.valueDate=$$v},expression:"valueDate"}})],1)],1)],1),_c('h3',[_vm._v(" Summary of Charges ")]),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Treatment $/LBS","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.chargePerLb),callback:function ($$v) {_vm.chargePerLb=$$v},expression:"chargePerLb"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Treatment Charge","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.treatmentCharge),callback:function ($$v) {_vm.treatmentCharge=$$v},expression:"treatmentCharge"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Handling Charge","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.smallLot),callback:function ($$v) {_vm.smallLot=$$v},expression:"smallLot"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Total Treatment Charge","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.totalTreatmentCharge),callback:function ($$v) {_vm.totalTreatmentCharge=$$v},expression:"totalTreatmentCharge"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Total Refining Charge","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.totalRefineCharge),callback:function ($$v) {_vm.totalRefineCharge=$$v},expression:"totalRefineCharge"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Total Processing Charge","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.totalProcessCharge),callback:function ($$v) {_vm.totalProcessCharge=$$v},expression:"totalProcessCharge"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Assay Charge","hint":"i.e. Lot or Lab fee","persistent-hint":"","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.assayCharge),callback:function ($$v) {_vm.assayCharge=$$v},expression:"assayCharge"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 4}},[_c('ValidationProvider',{attrs:{"rules":"max_value:99999999|decimals:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Freight Charge","prepend-icon":"mdi-currency-usd","type":"number","error-messages":errors},model:{value:(_vm.freightCharge),callback:function ($$v) {_vm.freightCharge=$$v},expression:"freightCharge"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"caption"},[_c('p',[_vm._v("* Indicates a required field")])]),_c('base-button',{attrs:{"disabled":invalid || _vm.loading,"loading":_vm.loading,"auto-center":"","label":"Add Lot"},on:{"clicked":_vm.addSsl}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }