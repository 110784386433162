<template>
  <v-toolbar
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    class="transparent"
  >
    <!-- Horizontal menu for large screens -->
    <v-spacer />
    <v-toolbar-items
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <template v-for="(item, index) in menuItems">
        <info-tooltip
          :disabled="!item.tooltip"
          :key="index"
        >
          <template v-slot:wrap-me>
            <v-btn
              :key="index"
              :disabled="item.disabled"
              class="mt-3"
              small
              text
              @click="item.action"
            >
              {{ item.title }}
            </v-btn>
          </template>
          {{ item.tooltip }}
        </info-tooltip>
      </template>
    </v-toolbar-items>

    <!-- v-menu for small screens -->
    <v-menu v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <info-tooltip
          v-for="(item, index) in menuItems"
          :disabled="!item.tooltip"
          :key="index"
        >
          <template v-slot:wrap-me>
            <v-list-item
              small
              :disabled="item.disabled"
              @click="item.action"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          {{ item.tooltip }}
        </info-tooltip>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
export default {
  name: 'DynamicToolbar',
  props: {
    // [...{ title, disabled, tooltip, action }]
    menuItems: {
      type: Array,
      required: true
    }
  }
}
</script>
