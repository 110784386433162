<template>
  <v-container>
    <div v-if="type === 'import'">
      <import-stillwater
        v-if="smelter.name.includes('Stillwater')"
        :selected-lots="lots"
        :smelter="smelter"
        @fetchSum="$emit('skipLots')"
      />
      <import-basf
        v-if="smelter.name.includes('BASF')"
        :selected-lots="lots"
        :smelter="smelter"
        @fetchSum="$emit('skipLots')"
      />
    </div>
    <div v-if="type === 'manual'">
      <v-form
        @submit.prevent="createSS"
        ref="form"
      >
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-file-input
              label="Settlement Report *"
              :error-messages="errors"
              v-model="settlementReport"
              prepend-icon="mdi-paperclip"
            />
          </ValidationProvider>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="customerShipmentId"
                  label="Lot ID *"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="shipmentId"
                  label="Smelter Lot ID *"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <v-menu
                v-model="shippedDateDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :value="formatDate(shippedDate)"
                      label="Shipped Date *"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-month"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="shippedDate"
                  @input="shippedDateDialog = false"
                  no-title
                  scrollable
                  :max="receivedDate"
                />
              </v-menu>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <v-menu
                v-model="receivedDateDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :value="formatDate(receivedDate)"
                      label="Received Date *"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-month"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="receivedDate"
                  @input="receivedDateDialog = false"
                  no-title
                  scrollable
                  :min="shippedDate"
                />
              </v-menu>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
              <v-menu
                v-model="assayDateDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      :value="formatDate(assayDate)"
                      label="Assay Date *"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-month"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="assayDate"
                  @input="assayDateDialog = false"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
          </v-row>
          <div class="caption">
            <p>* Indicates a required field</p>
          </div>
          <v-row justify="center">
            <base-button
              :disabled="invalid || loading"
              :loading="loading"
              type="submit"
            >
              Submit
            </base-button>
          </v-row>
        </ValidationObserver>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ImportStillwater from './ImportStillwater'
import ImportBasf from '@/views/ShipmentSummaries/components/Write/ImportBasf.vue'

export default {
  name: 'WriteSummary',
  components: {
    ImportBasf,
    ImportStillwater
  },
  props: {
    resetForms: { type: Boolean, required: false, default: false }
  },
  data: () => ({
    settlementReport: null,
    shipmentId: null,
    customerShipmentId: null,
    shippedDateDialog: false,
    shippedDate: null,
    receivedDateDialog: false,
    receivedDate: null,
    assayDateDialog: false,
    assayDate: null,
    loading: false
  }),
  computed: {
    ...mapGetters({
      type: 'shipmentSummaryStore/getInputType',
      lots: 'shipmentSummaryStore/getLots',
      smelter: 'shipmentSummaryStore/getSmelter',
      shipmentSummary: 'shipmentSummaryStore/shipmentSummary'
    })
  },
  methods: {
    ...mapActions({
      createSummary: 'shipmentSummaryStore/createShipmentSummary'
    }),
    // creates shipment summary then increments step to create shipment summary lots
    createSS () {
      this.loading = true
      const formData = new FormData()
      formData.set('settlement_report', this.settlementReport)
      formData.set('smelter_id', this.smelter.id)
      formData.set('shipment_id', this.shipmentId)
      formData.set('customer_shipment_id', this.customerShipmentId)
      formData.set('shipped_date', this.shippedDate)
      formData.set('received_date', this.receivedDate)
      formData.set('assay_date', this.assayDate)
      this.createSummary(formData).then(() => {
        if (this.shipmentSummary) this.$emit('incStep')
        this.loading = false
      })
    },
    /**
     * Format the given date to adhere to the formatting throughout the app
     * @param {String} date
     */
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    }
  },
  watch: {
    resetForms () {
      if (this.resetForms) {
        this.$refs.form.reset()
      }
    }
  }
}
</script>
