<template>
  <v-toolbar
    flat
    dense
  >
    <v-spacer />
    <confirm-button
      class="mr-2"
      :disabled="!bs.status || bs.status !== 'CR' || !$store.getters.can('edit-brokered-settlements')"
      color="accent"
      text="delete"
      @accept="$emit('delete')"
    >
      <p class="font-weight-bold">
        Are you sure you want to delete this Brokered Settlement?
      </p>
    </confirm-button>
    <confirm-button
      class="mr-2"
      :disabled="!bs.status || bs.status !== 'CR' || !$store.getters.can('edit-brokered-settlements')"
      text="finalize"
      @accept="$emit('finalize')"
    >
      <p class="font-weight-bold">
        Are you sure you want to finalize this Brokered Settlement? <span class="red--text">(it may no longer be edited)</span>
      </p>
    </confirm-button>
    <v-btn
      color="primary"
      small
      v-if="bs.id"
      :to="{ name: 'print-brokered-settlement', params: { id: bs.id } }"
    >
      Print
    </v-btn>
  </v-toolbar>
</template>

<script>
import ConfirmButton from '@/base/components/ConfirmButton'
export default {
  name: 'BSToolbarForm',
  components: { ConfirmButton },
  props: {
    bs: {
      type: Object,
      required: true
    }
  }
}
</script>
