<template>
  <div>
    <v-dialog
      v-model="addLotSettlement"
      fullscreen
      persistent
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          bottom
          right
          absolute
          color="primary"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <base-button
          v-if="$store.getters.can('create-lots')"
          @clicked="addLotSettlement = false"
          other-style-value="text-right"
          class="pa-2"
          icon-bool
          right
          icon="mdi-close"
        />
        <v-card-text>
          <create-shipment-summary
            :lot="lot"
            :button-label="'Create'"
            @updateRoute="fetchLot"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreateShipmentSummary from '@/views/ShipmentSummaries/components/Write/CreateShipmentSummary'

export default {
  name: 'LotSettlementDialog',
  props: {
    lot: { type: Object, required: true }
  },
  data: () => ({
    addLotSettlement: false
  }),
  components: { CreateShipmentSummary },
  methods: {
    fetchLot () {
      this.addLotSettlement = false
      this.$emit('fetchLot')
    }
  }
}
</script>
