<template>
  <ValidationObserver
    v-slot="{ invalid }"
    ref="validationObserver"
  >
    <v-row justify="center">
      <ValidationProvider
        rules="required"
        v-slot="{ errors }"
      >
        <v-autocomplete
          label="Select PPM Calculator"
          v-model="calc"
          return-object
          item-text="name"
          item-value="id"
          :items="ppmCalcs"
          :error-messages="errors"
          @change="modified = true"
        />
      </ValidationProvider>
      <base-button
        icon-bool
        styles="mt-4"
        color="error"
        @clicked="$emit('cancel')"
        icon="mdi-close-circle-outline"
      />
      <base-button
        icon-bool
        styles="mt-4"
        color="success"
        :disabled="!modified || invalid"
        @clicked="updateLot"
        :loading="updatingLot"
        icon="mdi-plus-circle-outline"
      />
    </v-row>
  </ValidationObserver>
</template>

<script>

import axios from 'axios'

export default {
  name: 'EditPpm',
  props: {
    updatingLot: { type: Boolean, required: true },
    ppmCalc: { type: Object, required: false, default: null }
  },
  data: () => ({
    calc: null,
    modified: false,
    ppmCalcs: []
  }),
  methods: {
    updateLot () {
      let body = { ppm_calculator_id: this.calc.id }
      this.$emit('updateLot', body)
    }
  },
  async mounted () {
    if (this.ppmCalc !== null) this.calc = this.ppmCalc
    const response = await axios.get('/api/ppm_calculations/?page_size=0&ordering=name')
    this.ppmCalcs = response.data
  }
}
</script>
