<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-report')"
    message="Unauthorized to view this page"
  >
    <base-title title="Final Report List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <date-range-input
        :start-date="createdStartDate"
        :end-date="createdEndDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="createdStartDate = $event"
        @alterEndDate="createdEndDate = $event"
      />
      <v-text-field
        label="ID"
        v-model="id"
        type="number"
      />
      <v-text-field
        label="Notes"
        v-model="notes"
      />
    </search-filters>
    <v-data-table
      multi-sort
      :headers="headers"
      :server-items-length="frResults"
      :options.sync="pagination"
      @page-count="pageCount = $event"
      :items="resultList"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="lot-final-report"
        >
          <v-btn small>
            {{ item.id }}
          </v-btn>
        </router-link-id>
      </template>
      <template v-slot:item.lots="{ item }">
        <router-link
          v-for="lot in item.lots"
          :key="lot.id"
          :to="{ name: 'lot', params: { id: lot.id } }"
        >
          {{ lot.name }}
        </router-link>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | filterNA | formatDate }}
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="10">
        <result-pagination
          v-if="pagination"
          v-model="pagination.page"
          :pages="Math.ceil(totalCount / frResults)"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          label="Rows Per Page"
          :items="[10, 20, 50]"
          :value="frResults"
          @input="handleLimitChange"
        />
      </v-col>
    </v-row>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import paginationMixin from '@/base/filter/paginationMixin'
import chipsMixin from '@/base/filter/chipsMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import { mapGetters, mapMutations } from 'vuex'

export default {
  mixins: [chipsMixin, paginationMixin],
  name: 'FinalReportList',
  components: {
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'final-report-list',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Lots', value: 'lots' },
        { text: 'Notes', value: 'notes' },
        { text: 'Created', value: 'created_at' }
      ],
      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Notes', prop: 'notes', type: String, query: 'notes__icontains' },
        { name: 'createdStartDate', prop: 'createdStartDate', type: String, query: 'created_at__gte' },
        { name: 'createdEndDate', prop: 'createdEndDate', type: String, query: 'created_at__lte' }
      ])
    }
  },
  computed: {
    ...mapGetters('lotStore', [
      'frResults'
    ])
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    ...mapMutations('lotStore', [
      'setFinalReportsPerPage'
    ]),
    handleLimitChange (val) {
      this.pagination.page = 1
      this.setFinalReportsPerPage(val)
      this.executeSearch(val)
    },
    async executeSearch (val) {
      const params = this.getQueryParams()
      params['page_size'] = this.frResults
      axios.get('/api/final_reports/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
