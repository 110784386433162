<template>
  <unauthorized
    :authorized="$store.getters.can('view-lot-report')"
    message="You are not authorized to view Lots Reports"
  >
    <url-breadcrumbs
      v-if="lot"
      :items="[
        ['lot list', { name: 'lot-list' }],
        [`Lot #${lot.id}`, { name: 'lot' }],
        ['Finance Report']
      ]"
    />
    <base-title title="Lot Finance Report" />
    <v-container v-if="!report">
      <v-skeleton-loader type="heading" />
    </v-container>
    <v-container
      v-else
    >
      <v-alert
        v-if="editRequired"
        color="warning"
        outlined
        dense
      >
        <v-row>
          <v-col>
            <v-icon color="warning">
              mdi-alert
            </v-icon>
            {{ editRequired }}
          </v-col>
        </v-row>
      </v-alert>
      <v-card
        class="pa-2 mx-auto"
        flat
        max-width="1000px"
      >
        <v-row>
          <v-col
            class="title text-center"
          >
            Lot Finance Report {{ report.lot_number }}
          </v-col>
        </v-row>
        <hr>
        <final-report-overview
          class="mb-10"
          :report="report"
        />
        <h3>Lot Shipment Summary</h3>
        <hr>
        <final-report-shipment
          class="mb-10"
          :report="report"
        />
        <h3>Smelter Return Summary</h3>
        <hr>
        <final-report-summary
          class="mb-10"
          :report="report"
        />
        <h3>Metals</h3>
        <hr>
        <h4>Allocations</h4>
        <report-allocations
          class="mb-10"
          :report="report"
        />
        <h4>Metal Summary</h4>
        <final-report-summary-metals
          class="mb-10"
          :report="report"
        />
        <final-report-values
          class="mb-10"
          :report="report"
        />
        <h3>Profit Summary</h3>
        <hr>
        <final-report-profit
          class="mb-10"
          :report="report"
        />
        <!--there are no notes for lot finance-->
      </v-card>
      <lot-finance-edit
        v-if="report"
        v-model="edit"
        :report="report"
        @report-updated="reportUpdated"
      />
      <v-btn
        color="accent"
        fixed
        fab
        bottom
        right
        @click="edit = !edit"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-container>
  </unauthorized>
</template>

<script>
import lotFinance from '@/store/generics/genericLotFinance'
import FinalReportOverview from './components/FinalReportOverview'
import FinalReportShipment from './components/FinalReportShipment'
import FinalReportSummary from './components/FinalReportSummary'
import FinalReportSummaryMetals from './components/FinalReportSummaryMetals'
import FinalReportValues from './components/FinalReportValues'
import FinalReportProfit from './components/FinalReportProfit'
import ReportAllocations from '@/views/FinanceReport/components/ReportAllocations'
import LotFinanceEdit from '@/views/FinanceReport/components/LotFinanceEdit'
import shipmentSummary from '@/store/generics/genericShipmentSummary'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LotFinance',
  components: {
    LotFinanceEdit,
    ReportAllocations,
    FinalReportValues,
    FinalReportProfit,
    FinalReportSummaryMetals,
    FinalReportSummary,
    FinalReportShipment,
    FinalReportOverview
  },
  data: () => ({
    loading: false,
    edit: false
  }),
  computed: {
    editRequired () {
      if (this.report.warnings && this.report.warnings.length > 0) {
        return this.report.warnings.join(' ')
      }
      return false
    },
    report () {
      // We don't use the getter here because the call back can not be observed
      if (!this.$store.state.lotFinance) return null
      return this.$store.state.lotFinance.items[this.$route.params.id]
    },
    ...mapGetters({
      lot: 'lotStore/lot'
    })
  },
  methods: {
    async reportUpdated (payload) {
      this.loading = true
      const id = this.report.shipment_summary_id
      const lotId = this.$route.params.id
      if (id !== null) {
        await this.$store.dispatch('lotStore/updateLot', {
          id: lotId,
          lot_finance_complete: payload.lot_finance_complete
        })
        await this.$store.dispatch('shipmentSummary/patchSummary', { id, payload })
        await this.$store.dispatch('lotFinance/fetchReport', this.$route.params.id)
      }
      this.loading = false
    },
    ...mapActions({
      fetchLot: 'lotStore/fetchLot'
    }),
    initializeBreadcrumbs () {
      if (this.$route.params.lot) this.loading = false
      else {
        this.fetchLot(this.$route.params.id).then(() => {
          this.loading = false
        })
      }
    }
  },
  mounted () {
    this.$store.registerModule('lotFinance', lotFinance)
    this.$store.registerModule('shipmentSummary', shipmentSummary)
    this.$store.dispatch('lotFinance/fetchReport', this.$route.params.id)
    this.initializeBreadcrumbs()
  },
  beforeDestroy () {
    this.$store.unregisterModule('lotFinance')
    this.$store.unregisterModule('shipmentSummary')
  }
}
</script>
