<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <th>Hedge</th>
        <th>Platinum</th>
        <th>Palladium</th>
        <th>Rhodium</th>
      </tr>
    </thead>
    <tbody
      v-for="(allo, index) in report.metal_allocations"
      :key="index"
    >
      <tr>
        <td>
          {{ allo.hedge_name }}
        </td>
        <td>
          {{ allo.pt_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ allo.pt_rate | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ allo.pd_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ (allo.pd_rate) | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ allo.rh_ctoz | toFixed(3) }}
          <small>
            x<dollar-sign />{{ allo.rh_rate | toFixed(2) | addCommas }}
          </small>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="font-weight-medium">
        <td>
          Totals/Avg
        </td>
        <td>
          {{ report.metal_allocations_totals.pt_ctoz_total | toFixed(3) }}
          <small>
            x<dollar-sign />{{ report.metal_allocations_totals.pt_rate_average | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ report.metal_allocations_totals.pd_ctoz_total | toFixed(3) }}
          <small>
            x<dollar-sign />{{ report.metal_allocations_totals.pd_rate_average | toFixed(2) | addCommas }}
          </small>
        </td>
        <td>
          {{ report.metal_allocations_totals.rh_ctoz_total | toFixed(3) }}
          <small>
            x<dollar-sign />{{ report.metal_allocations_totals.rh_rate_average | toFixed(2) | addCommas }}
          </small>
        </td>
      </tr>
    </tfoot>
  </v-simple-table>
</template>

<script>

export default {
  name: 'ReportAllocations',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
