<template>
  <v-dialog
    v-model="editDialog"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        small
        color="primary"
      >
        Edit
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-sheet>
      <v-toolbar
        dense
        dark
      >
        <v-toolbar-title>
          Edit Shipment Summary
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            @click="editDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form ref="summaryForm">
            <h4> Cannot delete if Shipment Summary Lots are complete (CO).</h4>
            <div class="mb-3" />
            <v-btn
              small
              color="accent"
              :disabled="!correctLotStatus"
              @click="deleteDialog = true"
            >
              Delete
            </v-btn>
            <base-dialog
              v-model="deleteDialog"
              headline-text="Delete Shipment Summary"
              :message-text="`Are you sure you would like to delete Shipment Summary ID #${id}?`"
              decline-text="Cancel"
              accept-text="Delete"
              @accept="deleteSS()"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-2"
            text
            @click="editDialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="!(summaryFile || finalInvoiceFile)"
            @click="accept"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditShipmentSummary',
  data: () => ({
    editDialog: false,
    deleteDialog: false,
    summaryFile: null,
    finalInvoiceFile: null
  }),
  props: {
    id: { type: Number, required: true },
    correctLotStatus: { type: Boolean, required: true }
  },
  methods: {
    deleteSS () {
      this.$emit('delete')
    },
    accept () {
      let documents = {}
      this.$emit('update', documents)
      this.$refs.summaryForm.reset()
      this.editDialog = false
    }
  }
}
</script>
