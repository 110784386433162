<template>
  <v-card>
    <v-card-subtitle>
      Overview
    </v-card-subtitle>
    <v-row>
      <v-col>
        <card-list-item title="Smelter">
          {{ shipmentSummary.smelter | filterObject('name') | filterNA }}
        </card-list-item>
      </v-col>
      <v-col>
        <card-list-item title="Created Date">
          {{ shipmentSummary.created_at | filterNA | formatDate }}
        </card-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <card-list-item title="Shipped Date">
          {{ shipmentSummary.shipped_date | filterNA | formatDate }}
        </card-list-item>
      </v-col>
      <v-col>
        <card-list-item title="Received Date">
          {{ shipmentSummary.received_date | filterNA | formatDate }}
        </card-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <card-list-item title="Assay Date">
          {{ shipmentSummary.assay_date | filterNA | formatDate }}
        </card-list-item>
      </v-col>
    </v-row>
    <v-card-subtitle class="ml-4">
      Summary Lots
    </v-card-subtitle>
    <div
      v-if="shipmentSummary.shipmentsummarylot_set.length > 0"
      class="ml-4 pb-4"
    >
      <ul
        v-for="summaryLot in shipmentSummary.shipmentsummarylot_set"
        :key="summaryLot.id"
      >
        <li>
          <router-link
            v-if="summaryLot.lot"
            :to="{ name: 'lot', params: { id: summaryLot.lot.id }}"
          >
            {{ summaryLot.lot | filterObject('name') }}
          </router-link>
          <span
            class="accent"
            v-else
          >
            No Lot Attached
          </span>
          -- {{ summaryLot.external_lot_id }} / {{ summaryLot.internal_lot_id }} {{ summaryLot.material_type }}
        </li>
      </ul>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'SummaryOverview',
  props: {
    shipmentSummary: { type: Object, required: true },
    addingDoc: { type: Boolean, required: true },
    notBrokered: { type: Boolean, required: true }
  }
}
</script>
