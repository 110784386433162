<template>
  <div>
    <url-breadcrumbs
      v-if="breadcrumbs"
      :items="breadcrumbs"
    />
    <v-card
      v-if="settlement"
      class="pa-2 mx-auto"
      flat
      max-width="1000px"
    >
      <v-row>
        <v-col
          class="text-left"
          cols="3"
        >
          <v-img
            :src="config.logo"
            :alt="config.name"
            height="100px"
            contain
          />
        </v-col>
        <v-col
          class="text-center mt-6"
          cols="6"
        >
          <h2>
            {{ settlement.yard.name }}
          </h2>
        </v-col>
        <v-col
          class="text-right mt-4"
          cols="3"
        >
          <b>
            Date Shipped: {{ settlement.shipped_date | formatDate }}
            <br>
            Lot: {{ settlement.lot_name }}
          </b>
        </v-col>
      </v-row>
      <div>
        <span class="font-weight-bold">
          Catalyst Shipped
        </span>
      </div>
      <div
        v-if="settlement.show_averages"
        class="d-flex justify-space-between"
      >
        <div>
          <span class="font-weight-bold body-2">Converters Processed:</span>
          {{ settlement.converters_processed | addCommas }}
        </div>
        <div>
          <span class="font-weight-bold body-2">
            Average per CAT:
          </span>
          ${{ settlement.converter_average_price | addCommas }}
        </div>
        <div>
          <span class="font-weight-bold body-2">
            Average per LB:
          </span>
          ${{ settlement.average_per_lb | addCommas }}
        </div>
      </div>
      <v-row>
        <v-col
          class="text-left"
          cols="6"
        >
          <div v-if="!settlement.show_averages">
            <strong>Converters Processed:</strong>
            {{ settlement.converters_processed | addCommas }}
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-simple-table dense>
            <thead>
              <tr>
                <th>
                  Total Weight
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ settlement.received_lbs_total | addCommas }} lbs</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <h5>
            Assay Results
          </h5>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col
          cols="12"
        >
          <v-simple-table
            dense
          >
            <thead>
              <tr>
                <th>
                  Metal
                </th>
                <th>
                  Hedge ID
                </th>
                <th>
                  Contained Toz
                </th>
                <th>
                  Contract Return %
                </th>
                <th>
                  Returnable Toz
                </th>
                <th>
                  Hedge Value
                </th>
                <th>
                  Total Value
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="m in ['pt', 'pd', 'rh']">
                <template v-for="(metal, index) in settlement.metals_details.filter(row => row[m + '_returnable'] > 0)">
                  <tr
                    :key="index + '-' + m"
                  >
                    <td>
                      <!--Metal-->
                      <span v-if="index === 0">{{ m | capitalize }}</span>
                    </td>
                    <td>
                      <!--Hedge ID-->
                      {{ metal.hedge_id }}
                    </td>
                    <td />
                    <td />
                    <td>
                      <!--Returnable Toz-->
                      {{ Number(metal[m + '_client_returnable']).toFixed(3) }}
                    </td>
                    <td>
                      <!--Hedge Value-->
                      <fx-tag
                        :fx="metal[m + '_mk_fx']"
                        :value="metal[m + '_mk']"
                        :cur="metal.fx_currency"
                      />
                    </td>
                    <td>
                      <!--Total Value-->
                      <fx-tag
                        :fx="metal[m + '_value_fx']"
                        :value="metal[m + '_value']"
                        :cur="metal.fx_currency"
                      />
                    </td>
                  </tr>
                </template>
                <tr :key="m + '-totals'">
                  <td />
                  <td />
                  <td>
                    <!--Contained Toz-->
                    {{ settlement.metal_totals[m + '_returnable_toz_total'].toFixed(3) }}
                  </td>
                  <td>
                    <!--Contract Return-->
                    {{ settlement.metal_totals[m + '_return_rate_average'] | appendPercent }}
                  </td>
                  <td>
                    <!--Returnable Toz Total-->
                    {{ settlement.metal_totals[m + '_client_returnable_toz_total'].toFixed(3) }}
                  </td>
                  <td />
                  <td>
                    <!--Total Value-->
                    <fx-tag
                      :fx="settlement.metal_totals[m + '_value_total']"
                      :cur="settlement.fx_currency"
                    />
                  </td>
                </tr>
                <tr
                  v-if="m !== 'rh'"
                  :key="m + 'spacer'"
                >
                  <td colspan="8" />
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="purchasedOutright.length"
          cols="12"
        >
          <div>
            <h5>
              Units Purchased Outright
            </h5>
            <v-divider />
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>
                    Quantity
                  </th>
                  <th>
                    Unit Price
                  </th>
                  <th>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="u in purchasedOutright"
                  :key="u.id"
                >
                  <td>
                    {{ u.material_type }}
                  </td>
                  <td>
                    {{ u.quantity }}
                  </td>
                  <td>
                    <fx-tag
                      :fx="u.unit_price_fx"
                      :value="u.unit_price"
                      :cur="u.fx_currency"
                    />
                  </td>
                  <td>
                    <fx-tag
                      :fx="u.total_price_fx"
                      :value="u.total_price"
                      :cur="u.fx_currency"
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    class="text-center"
                    colspan="4"
                  >
                    Average Unit Value
                    <fx-tag
                      :fx="purchasedOutrightAverage"
                      :cur="settlement.fx_currency"
                    />
                  </td>
                </tr>
              </tfoot>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="10"
          md="6"
          offset-sm="2"
          offset-md="6"
        >
          <v-simple-table dense>
            <tbody>
              <tr>
                <td
                  class="text-lg-body-1 text-left"
                  colspan="2"
                >
                  Gross Value
                </td>
                <td>
                  <fx-tag
                    class="font-weight-bold"
                    :fx="settlement.gross_value_fx"
                    :value="settlement.gross_value"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr v-if="settlement.treatment_fee_fx * -1">
                <td />
                <td>
                  Treatment Fee
                </td>
                <td>
                  <fx-tag
                    :fx="settlement.treatment_fee_fx * -1"
                    :value="settlement.treatment_fee"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr v-if="settlement.small_lot_fee_fx">
                <td />
                <td>
                  Handling fee
                </td>
                <td>
                  <fx-tag
                    :fx="settlement.small_lot_fee_fx * -1"
                    :value="settlement.small_lot_fee"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr v-if="settlement.finance_charge_fx">
                <td />
                <td>
                  Finance Charge
                </td>
                <td>
                  <fx-tag
                    :fx="settlement.finance_charge_fx * -1"
                    :value="settlement.finance_charge"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr v-if="settlement.freight_charge_fx">
                <td />
                <td>
                  Freight Charge
                </td>
                <td>
                  <fx-tag
                    :fx="settlement.freight_charge_fx * -1"
                    :value="settlement.freight_charge"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr v-if="settlement.unpaid_units_fx">
                <td />
                <td>
                  AM/DPF/Foil Value
                </td>
                <td>
                  <fx-tag
                    :fx="settlement.unpaid_units_fx * -1"
                    :value="settlement.unpaid_units"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr
                v-for="fee in settlement.additional_fees.filter(f => Number(f.other_fee_value_fx) !== 0)"
                :key="fee.other_fee_name"
              >
                <td />
                <td>
                  {{ fee.other_fee_name }}
                </td>
                <td>
                  <fx-tag
                    :fx="fee.other_fee_value_fx * -1"
                    :value="fee.other_fee_value * -1"
                    :cur="fee.fx_currency"
                  />
                </td>
              </tr>
              <tr>
                <td
                  class="text-lg-body-1 text-left"
                  colspan="2"
                >
                  Final Settlement
                </td>
                <td class="font-weight-bold">
                  <fx-tag
                    :fx="settlement.net_value_fx"
                    :value="settlement.net_value"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
              <tr v-if="settlement.advances">
                <td />
                <td>
                  Advance Payment
                </td>
                <td>
                  <span v-if="Number(settlement.advances)">
                    <fx-tag
                      :fx="settlement.advances_fx"
                      :value="settlement.advances"
                      :cur="settlement.fx_currency"
                    />
                  </span>
                  <span v-else>
                    --
                  </span>
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td
                  class="text-lg-body-1 text-left"
                  colspan="2"
                >
                  Total Due
                </td>
                <td>
                  <fx-tag
                    :fx="settlement.amount_due_fx"
                    :value="settlement.amount_due"
                    :cur="settlement.fx_currency"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col v-if="settlement.notes">
          <v-subheader>
            Notes: {{ settlement.notes }}
          </v-subheader>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/../izzi_config'

export default {
  name: 'BrokeredSettlementPrint',
  data: () => ({
    config,
    breadcrumbs: [],
    settlement: null
  }),
  computed: {
    purchasedOutright () {
      return this.settlement.purchased_outright.filter(po => +po.quantity !== 0)
    },
    /**
     * Populated the purchased outright footer rows
     */
    purchasedOutrightAverage () {
      let quantity = 0
      let total = 0
      this.purchasedOutright.forEach(po => {
        quantity += +po.quantity
        total += +po.total_price_fx
      })
      return quantity ? total / quantity : 0
    }
  },
  mounted () {
    axios.get(`/api/brokered_settlements/${this.$route.params.id}/`).then(bs => {
      this.settlement = bs.data
      this.breadcrumbs = [
        ['Lot List', { name: 'lot-list' }],
        ['Lot #' + bs.data.lot_id, { name: 'lot', params: { id: bs.data.lot_id } }],
        ['Brokered Settlement #' + bs.data.id, { name: 'brokered-settlement', params: { id: bs.data.id } }],
        ['Print', { name: 'print-brokered-settlement', params: { id: bs.data.id } }]
      ]
    })
  }
}
</script>

<style scoped>
th, td {
  text-align: right !important;
}
</style>
