<template>
  <v-card>
    <v-card-subtitle>
      Documents
    </v-card-subtitle>
    <v-card-text>
      <div v-if="shipmentSummary.settlement_report">
        <external-link
          v-model="shipmentSummary.settlement_report"
          label="Shipment Summary"
        />
        <info-tooltip>
          The shipment summary may not be removed, instead delete the shipment summary and upload a new one.
        </info-tooltip>
      </div>
      <div v-if="shipmentSummary.final_invoice">
        <external-link
          v-model="shipmentSummary.final_invoice"
          label="Final Invoice"
        />
        <info-tooltip>
          This uses an older upload method, please contact for removal
        </info-tooltip>
      </div>
      <div
        v-for="(file, index) in files"
        :key="index"
      >
        <br>
        File #{{ file.id }}
        <external-link
          :value="file.upload"
          :label="`Final Invoice`"
        />
        <v-btn
          x-small
          icon
          @click="$emit('deleteFile', file)"
        >
          <v-icon>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </div>
      <v-file-input
        v-if="!shipmentSummary.settlement_report && $store.getters.can('edit-shipment-summary')"
        label="Shipment Summary"
        v-model="summaryDoc"
        prepend-icon="mdi-paperclip"
      />
      <v-file-input
        v-if="$store.getters.can('edit-shipment-summary')"
        label="Final Invoice"
        v-model="invoiceDoc"
        prepend-icon="mdi-paperclip"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :color="(invoiceDoc || summaryDoc) ? 'primary': ''"
        :loading="addingDoc"
        small
        @click="addDocuments"
      >
        Upload
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'SummaryDocuments',
  props: {
    shipmentSummary: {
      type: Object,
      required: true
    },
    addingDoc: {
      type: Boolean,
      required: true
    },
    notBrokered: {
      type: Boolean,
      required: true
    },
    files: {
      type: Array,
      default: Array
    }
  },
  data: () => ({
    invoiceDoc: null,
    summaryDoc: null,
    viewSummary: false,
    viewInvoice: false
  }),
  methods: {
    // Sets documents to be emitted to parent (Shipment Summary)
    addDocuments () {
      if (!this.invoiceDoc && !this.summaryDoc) return
      const documents = { summaryDoc: this.summaryDoc, invoiceDoc: this.invoiceDoc }
      this.$emit('addDocuments', documents)
      this.invoiceDoc = null
      this.summaryDoc = null
    }
  }
}
</script>
