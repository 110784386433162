var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-assay-result'),"message":"Unauthorized to view assay results"}},[_c('v-container',[_c('base-title',[_vm._v(" Assay Comparison by Lot ")]),_c('v-form',[_c('v-row',[_c('v-col',[_c('rules-date',{attrs:{"max":_vm.endDate || _vm.today,"label":"Lot Creation Date Start"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',[_c('rules-date',{attrs:{"min":_vm.startDate,"max":_vm.today,"label":"Lot Creation Date End"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{staticClass:"text-right mt-3",attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[(_vm.results.length)?_c('download-excel',{attrs:{"data":_vm.results,"name":("assay-comparison-between-" + _vm.startDate + "-" + _vm.endDate + ".csv"),"type":"csv"}},[_c('v-btn',{attrs:{"text":""}},[_vm._v(" Download Results ")])],1):_c('v-btn',{attrs:{"disabled":"","text":""}},[_vm._v(" Download Results ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-card-subtitle',[_vm._v(" Results: "+_vm._s(_vm.results.length)+" "),_c('info-tooltip',[_vm._v(" Lots are filtered by Created Date. Only lots with both Internal and Smelter assays will be displayed. ")])],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}}):(_vm.results.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","disable-pagination":"","item-class":_vm.rowClasses},scopedSlots:_vm._u([{key:"item.pt",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': item.pt < 0 }},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pt,2)))+" ")])]}},{key:"item.pd",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text' : item.pd < 0}},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pd,2)))+" ")])]}},{key:"item.rh",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text' : item.rh < 0}},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.rh,2)))+" ")])]}}])}):_c('div',[_c('v-card-text',[_vm._v(" No Results. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }