<template>
  <div>
    <h2>
      Averages
    </h2>
    <v-switch
      v-model="bs.show_averages"
      @change="$emit('setProp', ['show_averages', $event])"
      dense
      label="Display Averages on Report"
    />
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|min_value:0|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            class="dense-filled"
            readonly
            dense
            label="Catalyst AVG per lb"
            hint="(readonly) net value / received lbs"
            type="number"
            prepend-icon="mdi-currency-usd"
            :value="bs.average_per_lb"
            @input="$emit('setProp', ['average_per_lb', $event])"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|min_value:0|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            :readonly="!bs.avg_per_cat_manual"
            :class="{'dense-filled': !bs.avg_per_cat_manual}"
            dense
            type="number"
            :value="bs.converter_average_price"
            @input="$emit('setProp', ['converter_average_price', $event])"
            label="AVG per CAT"
            prepend-icon="mdi-currency-usd"
            hint="net value / converters processed"
            :append-icon="bs.avg_per_cat_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline'"
            :error-messages="errors"
            @click:append="$emit('setProp', ['avg_per_cat_manual', !bs.avg_per_cat_manual])"
          />
        </ValidationProvider>
      </v-col>
      <v-col>
        <span>
          <v-simple-checkbox
            :value="bs.oem_only"
            @input="$emit('setProp', ['oem_only', !bs.oem_only])"
          />
          OEM Only
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BSAveragesDue',
  props: {
    bs: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
}
</script>
