<template>
  <div>
    <v-card-subtitle class="subtitle-1 pb-0 font-weight-bold">
      Returnable Ounces
    </v-card-subtitle>
    <div
      v-for="(lot, index) in shipmentSummaries"
      :key="index"
    >
      <div v-if="shipmentSummaries.length > 1">
        <b class="ml-3 mt-5">{{ `Lot ${lot.internal_lot_id}` }}</b>
        <v-divider class="ma-2" />
      </div>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <card-list-item
            title="PT Troy Ounces"
            no-cols
          >
            {{ lot.returnable_pt_toz | toFixed(3) | filterZero | appendRtoz }}
          </card-list-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <card-list-item
            title="PD Troy Ounces"
            no-cols
          >
            {{ lot.returnable_pd_toz | toFixed(3) | filterZero | appendRtoz }}
          </card-list-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <card-list-item
            title="RH Troy Ounces"
            no-cols
          >
            {{ lot.returnable_rh_toz | toFixed(3) | filterZero | appendRtoz }}
          </card-list-item>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <card-list-item
            title="Value Date"
            no-cols
          >
            {{ lot.value_date | formatDate }}
          </card-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnableOunces',
  props: {
    shipmentSummaries: {
      type: Array,
      required: true
    }
  }
}
</script>
