<template>
  <v-btn
    :disabled="!$store.getters.can('view-cut-plans')"
    :color="color"
    :to="{ name: 'cut-plan-view', params: { id }}"
    elevation="0"
    small
  >
    <v-icon :class="getFriendlyTextClasses(color)">
      mdi-order-numeric-descending
    </v-icon>
  </v-btn>
</template>

<script>
import { getFriendlyTextClasses } from '@/helpers/utils.js'

export default {
  name: 'CutPlanViewButton',
  props: {
    id: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: '#FFF'
    }
  },
  methods: {
    getFriendlyTextClasses (color) {
      return getFriendlyTextClasses(color)
    }
  }
}
</script>
