var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.type === 'import')?_c('div',[(_vm.smelter.name.includes('Stillwater'))?_c('import-stillwater',{attrs:{"selected-lots":_vm.lots,"smelter":_vm.smelter},on:{"fetchSum":function($event){return _vm.$emit('skipLots')}}}):_vm._e(),(_vm.smelter.name.includes('BASF'))?_c('import-basf',{attrs:{"selected-lots":_vm.lots,"smelter":_vm.smelter},on:{"fetchSum":function($event){return _vm.$emit('skipLots')}}}):_vm._e()],1):_vm._e(),(_vm.type === 'manual')?_c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.createSS($event)}}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":"Settlement Report *","error-messages":errors,"prepend-icon":"mdi-paperclip"},model:{value:(_vm.settlementReport),callback:function ($$v) {_vm.settlementReport=$$v},expression:"settlementReport"}})]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Lot ID *","error-messages":errors},model:{value:(_vm.customerShipmentId),callback:function ($$v) {_vm.customerShipmentId=$$v},expression:"customerShipmentId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 6}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Smelter Lot ID *","error-messages":errors},model:{value:(_vm.shipmentId),callback:function ($$v) {_vm.shipmentId=$$v},expression:"shipmentId"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDate(_vm.shippedDate),"label":"Shipped Date *","readonly":"","append-icon":"mdi-calendar-month","error-messages":errors}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.shippedDateDialog),callback:function ($$v) {_vm.shippedDateDialog=$$v},expression:"shippedDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.receivedDate},on:{"input":function($event){_vm.shippedDateDialog = false}},model:{value:(_vm.shippedDate),callback:function ($$v) {_vm.shippedDate=$$v},expression:"shippedDate"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDate(_vm.receivedDate),"label":"Received Date *","readonly":"","append-icon":"mdi-calendar-month","error-messages":errors}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.receivedDateDialog),callback:function ($$v) {_vm.receivedDateDialog=$$v},expression:"receivedDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.shippedDate},on:{"input":function($event){_vm.receivedDateDialog = false}},model:{value:(_vm.receivedDate),callback:function ($$v) {_vm.receivedDate=$$v},expression:"receivedDate"}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 6}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDate(_vm.assayDate),"label":"Assay Date *","readonly":"","append-icon":"mdi-calendar-month","error-messages":errors}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.assayDateDialog),callback:function ($$v) {_vm.assayDateDialog=$$v},expression:"assayDateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.assayDateDialog = false}},model:{value:(_vm.assayDate),callback:function ($$v) {_vm.assayDate=$$v},expression:"assayDate"}})],1)],1)],1),_c('div',{staticClass:"caption"},[_c('p',[_vm._v("* Indicates a required field")])]),_c('v-row',{attrs:{"justify":"center"}},[_c('base-button',{attrs:{"disabled":invalid || _vm.loading,"loading":_vm.loading,"type":"submit"}},[_vm._v(" Submit ")])],1)]}}],null,false,1688529613)})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }