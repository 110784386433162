<template>
  <v-dialog
    v-model="hedgeAllocDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    persistent
    width="600"
  >
    <template v-slot:activator="{ on }">
      <div class="text-center">
        <v-btn
          v-on="on"
          bottom
          right
          fab
          absolute
          small
          color="primary"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </template>
    <v-card class="py-2">
      <v-container style="max-width:40rem">
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <v-form
            class="mx-2"
            ref="form"
            @submit.prevent="addAllocation"
          >
            <v-row>
              <v-card-title>
                {{ `Add Allocation To Lot #${lot}` }}
              </v-card-title>
              <v-spacer />
              <v-btn
                @click="hedgeAllocDialog = false"
                icon
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-row>
            <v-row>
              <v-col
                v-for="(metal, i) of metals"
                :key="i"
                class="py-0"
              >
                {{ capitalize(metal.name) }}:
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(metal, i) of metals"
                :key="i"
                class="pt-0"
              >
                {{ unallocatedMetals[metal.abbr] | trimDecimals | addCommas | appendToz }}
              </v-col>
            </v-row>
            <v-row>
              <v-card-title>
                {{ `From Hedge:` }}
              </v-card-title>
              <v-text-field
                v-if="loading"
                label="Select a Hedge"
                disabled
                loading
              />
              <ValidationProvider
                v-else
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  v-model="hedge"
                  :items="hedges"
                  class="ml-2"
                  label="Select a Hedge"
                  return-object
                  item-text="id"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-row>
            <v-row>
              <v-simple-table v-if="hedge">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th />
                      <th class="text-left">
                        Allocation
                      </th>
                      <th class="text-left">
                        Available
                      </th>
                      <th class="text-left">
                        Rate/oz
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(metal, i) of metals"
                      :key="i"
                    >
                      <td>
                        {{ $vuetify.breakpoint.smAndDown ? capitalize(metal.abbr) : capitalize(metal.name) }}
                      </td>
                      <td>
                        <ValidationProvider
                          :rules="`required|max_value:${Math.min(
                            hedge[`${metal.abbr}_available_toz`],
                            unallocatedMetals[metal.abbr]
                          )}`"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="metal.value"
                            type="number"
                            :error-messages="formatErrors(errors, metal)"
                          />
                        </ValidationProvider>
                      </td>
                      <td>{{ hedge[`${metal.abbr}_available_toz`] | trimDecimals | addCommas | appendToz }} </td>
                      <td>{{ hedge[`${metal.abbr}_rate_per_toz`] | trimDecimals | addCommas | dollarSign }} </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
            <div v-if="hedge">
              <v-row>
                <v-card-title>
                  Results
                </v-card-title>
              </v-row>
              <v-row>
                <v-card-title class="ml-2 pt-0">
                  {{ `Lot #${lot}` }}
                </v-card-title>
              </v-row>
              <v-row class="ml-2">
                <v-col
                  v-for="(metal, i) of metals"
                  :key="i"
                  class="py-0"
                >
                  {{ capitalize(metal.name) }}:
                </v-col>
              </v-row>
              <v-row class="ml-2">
                <v-col
                  v-for="(metal, i) of metals"
                  :key="i"
                  class="pt-0"
                >
                  {{ calculateResult(metal, unallocatedMetals[metal.abbr]) | trimDecimals | addCommas | appendToz }}
                </v-col>
              </v-row>
              <v-row>
                <v-card-title class="ml-2 pt-0">
                  Unallocated:
                </v-card-title>
              </v-row>
              <v-row class="ml-2">
                <v-col
                  v-for="(metal, i) of metals"
                  :key="i"
                  class="py-0"
                >
                  {{ capitalize(metal.name) }}:
                </v-col>
              </v-row>
              <v-row class="ml-2">
                <v-col
                  v-for="(metal, i) of metals"
                  :key="i"
                  class="pt-0"
                >
                  {{ calculateResult(metal, hedge[`${metal.abbr}_available_toz`]) | trimDecimals | addCommas | appendToz }}
                </v-col>
              </v-row>
            </div>
            <base-button
              auto-center
              :disabled="invalid"
              type="submit"
              label="Add Allocation"
            />
          </v-form>
        </ValidationObserver>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HedgeAllocationDialog',
  props: {
    unallocatedMetals: { type: Object, required: true },
    lot: { type: Number, required: true }
  },
  data: () => ({
    loading: false,
    hedgeAllocDialog: false,
    metals: [
      { abbr: 'pt', name: 'platinum', value: 0 },
      { abbr: 'pd', name: 'palladium', value: 0 },
      { abbr: 'rh', name: 'rhodium', value: 0 }
    ],
    hedge: null
  }),
  computed: {
    ...mapGetters({
      hedges: 'hedgeStore/hedges'
    })
  },
  watch: {
    hedgeAllocDialog: {
      immediate: true,
      async handler (value) {
        if (!value) return
        // triggers lazy load of hedges
        if (this.hedges.length === 0) {
          this.loading = true
          await this.$store.dispatch('hedgeStore/fetchHedgeBank')
          this.loading = false
        }
      }
    }
  },
  methods: {
    /**
     * Add an allocation to the given hedge and lot
     */
    addAllocation () {
      this.loading = true
      const body = {
        pt: this.metals[0].value,
        pd: this.metals[1].value,
        rh: this.metals[2].value,
        hedge_id: this.hedge.id,
        lot_id: this.lot
      }
      this.$store.dispatch('allocationStore/addAllocation', body).then(() => {
        this.$emit('addedAllocation')
        this.loading = false
        this.hedgeAllocDialog = false
        this.hedge = null
        this.metals = [
          { abbr: 'pt', name: 'platinum', value: 0 },
          { abbr: 'pd', name: 'palladium', value: 0 },
          { abbr: 'rh', name: 'rhodium', value: 0 }
        ]
        this.$refs.validationObserver.reset()
      })
    },
    /**
     * Calculate the result of a value minus the allocation
     * @param {String} metal
     * @param {Double} value
     */
    calculateResult (metal, value) {
      return !metal.value ? value : (value - parseFloat(metal.value)).toFixed(3)
    },
    /**
     * Provide users with more readable error messages
     * @param {Array[String]} errors
     * @param {Object} metal
     * @return {String}
     */
    formatErrors (errors, metal) {
      let error = errors[0]
      const maxValue = Math.min(this.hedge[`${metal.abbr}_available_toz`], this.unallocatedMetals[metal.abbr])
      if (error === 'max_value') return `Cannot be greater than ${maxValue}`
      return error
    }
  }
}
</script>
