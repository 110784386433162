<template>
  <div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th>Material Type</th>
          <th>Containers Shipped</th>
          <th>Total Gross</th>
          <th>Total Tare</th>
          <th>Total Net</th>
          <th>Intake Units</th>
          <!--report.show_avg_lbs_per_cat-->
          <th>Avg. Foil Lbs/Cat</th>
          <!--report.show_avg_catalyst_lbs_cat-->
          <th>Avg. Catalyst Lbs/Cat</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-no-wrap"
          v-for="(row, index) in report.lot_shipment_summary"
          :key="index"
        >
          <td>
            {{ row.material_type }}
          </td>
          <td>
            {{ row.containers_shipped | toFixed(0) }}
          </td>
          <td>
            <uom>{{ row.total_gross | toFixed(3) | addCommas }}</uom>
          </td>
          <td>
            <uom>{{ row.total_tare | toFixed(3) | addCommas }}</uom>
          </td>
          <td>
            <uom>{{ row.total_net | toFixed(3) | addCommas }}</uom>
          </td>
          <td>
            {{ row.processed_units | toFixed(0) | addCommas }}
          </td>
          <td>
            <uom tag="lbs/cat">
              {{ row.avg_foils_lbs_per_cat | toFixed(2) }}
            </uom>
          </td>
          <td>
            <uom tag="lbs/cat">
              {{ row.avg_catalyst_lbs_per_cat | toFixed(2) }}
            </uom>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>

export default {
  name: 'FinalReportShipment',
  data: () => ({
    weightObj: {
      text: 'lbs',
      fontSize: '8px'
    }
  }),
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
