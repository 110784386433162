<template>
  <div>
    <slot :toggle="() => { dialog = !dialog }" />
    <v-dialog
      v-model="dialog"
      scrollable
      hide-overlay
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            flat
            dense
            dark
          >
            <v-toolbar-title>
              Edit Shipment Summary
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <app-loading-indicator
          v-if="loading"
        />
        <v-card-text v-else-if="shipmentSummary">
          <v-alert
            class="my-2"
            type="warning"
            dense
            outlined
          >
            Enter values as USD
          </v-alert>
          <confirm-summary
            @updateRoute="fetchLot"
          />
        </v-card-text>
        <v-card-text v-else>
          <p>Unable to retrieve the shipment summary.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConfirmSummary from '@/views/ShipmentSummaries/components/Write/ConfirmSummary.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditShipmentSummaryDialog',
  components: { ConfirmSummary },
  props: {
    shipmentSummaryId: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false
  }),
  watch: {
    dialog: {
      immediate: true,
      handler (opened) {
        if (opened) this.fetch()
      }
    }
  },
  computed: {
    ...mapGetters({
      shipmentSummary: 'shipmentSummaryStore/shipmentSummary'
    })
  },
  methods: {

    ...mapActions({
      fetchLot: 'lotStore/fetchLot'
    }),
    // used to re-load the lot page data
    fetchLot () {
      this.$store.dispatch('lotStore/fetchLot', this.$route.params.id).finally(() => {
        this.dialog = false
      })
    },
    async fetch () {
      this.loading = true
      await this.$store.dispatch('shipmentSummaryStore/fetchShipmentSummary', this.shipmentSummaryId)
      this.loading = false
    }
  }
}
</script>
