<template>
  <v-dialog
    v-model="materialTypeDialog"
    width="550"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        small
        icon
        color="blue"
      >
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Update Shipment Summary Material Type
        <v-spacer />
        <v-btn
          icon
          right
          class="text-right"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-select
          label="Select Material Type"
          v-model="selectedMaterialType"
          :items="lotMaterialTypes"
          item-text="text"
          item-value="value"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red"
          text
          @click="cancelMaterialTypeChange"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          text
          @click="submitMaterialTypeChange"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'EditMaterialTypeDialog',
  props: {
    shipmentSummaryLot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    materialTypeDialog: false,
    selectedMaterialType: null
  }),
  computed: {
    lotMaterialTypes () {
      return this.$store.getters['lotStore/lotMaterialType']
    }
  },
  methods: {
    // close dialog and set input back to the current material type
    cancelMaterialTypeChange () {
      this.materialTypeDialog = false
      this.selectedMaterialType = this.shipmentSummaryLot.material_type_text
    },
    async submitMaterialTypeChange () {
      try {
        // update the shipment summary lot
        await axios.patch(`/api/shipment_summary_lots/${this.shipmentSummaryLot.id}/`, {
          material_type: this.selectedMaterialType
        })
        // fetch a fresh copy of the lot
        await this.$store.dispatch('lotStore/fetchLot', this.$route.params.id)
      } catch {
        this.$store.commit('setSnackbarError', 'An error occurred while attempting to change the ShipmentSummaryLot\'s material type')
      } finally {
        // close dialog
        this.materialTypeDialog = false
      }
    }
  }
}
</script>
