import axios from 'axios'

export async function fetchLotIdsInDateRange (startDate, endDate) {
  const params = {
    page_size: 0,
    fields: 'id',
    created_at__gte: startDate,
    created_at__lte: endDate
  }
  let ids = []
  const response = await axios.get(`/api/lots/`, { params })
  if (response.data) ids = response.data.map(lot => lot.id)
  return ids
}
