<template>
  <div>
    <ValidationObserver
      v-slot="{ invalid, reset }"
      ref="observer"
    >
      <edit-dialog
        headline-text="Edit Assay Result"
        :value="value"
        :inputs-valid="!invalid"
        accept-text="Save Changes"
        @input="$emit('input', $event)"
        @accept="submit"
      >
        <assay-result-form
          v-if="value"
          :assay-result="assayResult"
          @export="payload = $event"
        />
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import { fetchSources, TYPE, updateAssayResult } from '@/requests/assayResult'
import AssayResultForm from '@/views/Lots/components/AssayResult/AssayResultForm.vue'

export default {
  name: 'EditAssayResultDialog',
  components: { AssayResultForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    assayResult: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    payload: {},
    pt: 0,
    pd: 0,
    rh: 0,
    status: 'PR',
    percent_moisture: 0,
    percent_scrap: 0,
    date: new Date().toISOString().slice(0, 10),
    source: null,
    type: TYPE.XRF.value,
    sourceOptions: [],
    typeOptions: [
      TYPE.XRF,
      TYPE.ICP
    ]
  }),
  methods: {
    async submit () {
      try {
        const response = await updateAssayResult(this.assayResult.id, {
          pt_ppm: this.payload.pt_ppm,
          pd_ppm: this.payload.pd_ppm,
          rh_ppm: this.payload.rh_ppm,
          status: this.payload.status,
          percent_moisture: this.payload.percent_moisture,
          percent_scrap: this.payload.percent_scrap,
          date: this.payload.date,
          source: this.payload.source,
          assay_type: this.payload.assay_type
        })
        if (response.status === 200) {
          this.$emit('updated', response.data)
        }
      } catch (e) {
        this.$logger.error(e.message)
        this.$store.commit('setSnackbarError', 'failed to update assay result')
      }
    }
  },
  async mounted () {
    this.sourceOptions = await fetchSources()
  }
}
</script>
