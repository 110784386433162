var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',[_c('strong',[_vm._v("Designated Hedges")])]),_c('v-data-table',{attrs:{"single-expand":"","show-expand":"","expanded":_vm.expanded,"headers":_vm.hedgeTableHeaders,"items":_vm.hedges,"items-per-page":5},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":Number(item.id),"name":"hedge"}})]}},{key:"item.pt_initial_toz",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-color text-right"},[_vm._v(" "+_vm._s(_vm._f("appendToz")(_vm._f("toFixed")(item.pt_initial_toz,3)))+" "),_c('br'),_c('fx-tag',{attrs:{"fx":item.pt_rate_per_toz_fx,"value":item.pt_rate_per_toz,"cur":item.currency}})],1)]}},{key:"item.pd_initial_toz",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pd-color text-right"},[_vm._v(" "+_vm._s(_vm._f("appendToz")(_vm._f("toFixed")(item.pd_initial_toz,3)))+" "),_c('br'),_c('fx-tag',{attrs:{"fx":item.pd_rate_per_toz_fx,"value":item.pd_rate_per_toz,"cur":item.currency}})],1)]}},{key:"item.rh_initial_toz",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"rh-color text-right"},[_vm._v(" "+_vm._s(_vm._f("appendToz")(_vm._f("toFixed")(item.rh_initial_toz,3)))+" "),_c('br'),_c('fx-tag',{attrs:{"fx":item.rh_rate_per_toz_fx,"value":item.rh_rate_per_toz,"cur":item.currency}})],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.hedgeTableHeaders.length++}},[(item.memo)?_c('div',{staticClass:"text-center"},[_vm._v(" Notes: "),_c('br'),_vm._v(" "+_vm._s(item.memo)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" Notes: "),_c('br'),_c('b',[_vm._v("No notes have been added")])])])]}}])}),_c('v-btn',{attrs:{"color":"primary","small":"","fab":"","absolute":"","bottom":"","right":""},on:{"click":function($event){_vm.editDialog = !_vm.editDialog}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('edit-dialog',{attrs:{"headline-text":"Update Designated Hedges"},on:{"accept":_vm.updateHedges},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-autocomplete',{attrs:{"label":"Select Hedge(s)","items":_vm.combinedItems,"item-text":function (x) { return x.id + ' ' + x.code; },"search-input":_vm.search,"chips":"","return-object":"","multiple":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{attrs:{"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeChip(data.item)}}},[_vm._v(" "+_vm._s(data.item.id)+" "+_vm._s(data.item.code)+" ")])]}}]),model:{value:(_vm.chipHedges),callback:function ($$v) {_vm.chipHedges=$$v},expression:"chipHedges"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }