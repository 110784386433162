<!--
Brokered overview is a component that appears on the Lot.vue
It provides a card display of the settlement and presents the Create and Edit buttons
-->

<template>
  <v-card v-if="lot.shipment_summary_lot && expectsSettlement(lot.designation)">
    <v-card-subtitle>
      <b>Brokered Settlement Overview</b>
    </v-card-subtitle>
    <!--IF A BROKERED SETTLEMENT EXISTS-->
    <div v-if="!lot.shipment_summary_lot.brokeredsettlement">
      <card-list-item
        v-if="lot.yards.length === 0"
        no-cols
        title="Please add at least (1) Designated Yard to create Settlement"
      />
      <div v-else>
        <card-list-item
          title="No Brokered Settlement. Create one by clicking the (+) action button."
          no-cols
        />
        <create-brokered-settlement-dialog
          v-model="createBrokeredSettlementDialog"
          :lot-name="lot.name"
          :shipment-summary-lot="lot.shipment_summary_lot"
          :yards="lot.yards"
          @created="$emit('created')"
        />
        <v-btn
          v-if="lot.yards.length > 0"
          fab
          small
          bottom
          right
          absolute
          color="primary"
          @click="createBrokeredSettlementDialog = !createBrokeredSettlementDialog"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <view-brokered-settlement
        v-if="bs"
        :settlement="bs"
      />
      <v-btn
        v-if="bs && bs.status === 'CR'"
        fab
        small
        bottom
        right
        absolute
        color="primary"
        :to="{ name: 'brokered-settlement', params: { id: lot.shipment_summary_lot.brokeredsettlement_id } }"
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
  </v-card>
  <v-card v-else>
    <v-card-title>
      <b>Brokered Settlement Overview</b>
    </v-card-title>
    <v-card-text>
      <p v-if="!expectsSettlement(lot.designation)">
        Must Be: Brokered
      </p>
      <p v-if="!lot.shipment_summary_lot">
        Requires: Shipment Summary Lot
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import CreateBrokeredSettlementDialog from '@/views/Lots/components/Brokered/CreateBrokeredSettlementDialog'
import axios from 'axios'
import ViewBrokeredSettlement from '@/views/Lots/components/Brokered/ViewBrokeredSettlement'
import { expectsSettlement } from '@/helpers/utils'

export default {
  name: 'BrokeredOverview',
  methods: {
    expectsSettlement
  },
  components: { ViewBrokeredSettlement, CreateBrokeredSettlementDialog },
  props: {
    lot: { type: Object, required: true }
  },
  data: () => ({
    createBrokeredSettlementDialog: false,
    bs: null
  }),
  watch: {
    lot: {
      immediate: true,
      handler (lot) {
        if (lot?.shipment_summary_lot?.brokeredsettlement_id) {
          const id = lot.shipment_summary_lot.brokeredsettlement_id
          axios.get(`/api/brokered_settlements/${id}/`).then(r => {
            this.bs = r.data
          })
        }
      }
    }
  }
}
</script>
