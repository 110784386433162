<template>
  <div>
    <h2>
      Metals
    </h2>
    <v-card
      class="my-6"
      outlined
    >
      <v-card-text>
        <p>
          A id's represent the metal allocation id, full numbers are hedge id references.
        </p>
        <div
          v-for="(hedge, index) in metals"
          :key="index"
        >
          <div class="my-4">
            <v-card-actions>
              <span class="text-h6">
                <span v-if="hedge.hedge_id || hedge.id">
                  Hedge #{{ hedge.hedge_id || '*' + hedge.id }}
                </span>
                <span
                  v-else
                  class="yellow--text text--darken-3"
                >
                  Hedge Missing!
                </span>
              </span>
              <v-btn
                class="ml-4"
                x-small
                @click="openHedgeNumDialog(index)"
              >
                change id
              </v-btn>
              <v-btn
                color="accent"
                text
                x-small
                @click="removeMetal(index)"
              >
                remove
              </v-btn>
            </v-card-actions>
            <v-card-text class="pb-0">
              <div>
                <edit-metal
                  :hedge="hedge"
                  @setHedge="setMetal(index, $event)"
                />
              </div>
            </v-card-text>
          </div>
        </div>
        <v-expand-transition>
          <v-card
            v-show="!unallocatedMetals.every(x => +x === 0)"
            flat
          >
            <v-row>
              <v-col
                v-for="(um, index) in unallocatedMetals"
                :key="index"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  rules="max_value:0|min_value:0"
                  v-slot="{ errors }"
                  immediate
                >
                  <v-text-field
                    class="dense-filled"
                    dense
                    type="number"
                    :value="um"
                    :label="`Unallocated ${unallocatedInfo[index]} Toz`"
                    readonly
                    :error-messages="(errors.length) ? ['Must be zero'] : []"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
        <v-row>
          <v-col
            v-for="(um, index) in tozToAllocate"
            :key="index"
            cols="12"
            md="4"
          >
            <v-text-field
              class="dense-filled"
              dense
              type="number"
              :value="um"
              :label="`Total ${unallocatedInfo[index]} Toz`"
              readonly
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="bs.status && bs.status === 'FI'"
          @click="addMetalDialog = !addMetalDialog"
        >
          Add Allocation
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col
        cols="12"
        offset-md="8"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            :value="bs.gross_value"
            readonly
            filled
            type="number"
            label="Gross Value"
            prepend-icon="mdi-currency-usd"
            hint="(readonly) total of hedge values"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <edit-dialog
      headline-text="Add Metal"
      v-model="addMetalDialog"
      :show-decline="false"
      accept-text="done"
    >
      <p>
        Showing hedges that appeared on the lot.
      </p>
      <v-alert
        v-if="this.bt.ounce_payout_type === 'CO'"
        dense
        color="warning"
      >
        Returned values have been re-calculated to Contained Troy OZ values
      </v-alert>
      <v-data-table
        :headers="allocationHeaders"
        :items="getAllocations"
      >
        <template v-slot:item.use="{ item }">
          <v-btn
            color="primary"
            small
            @click="addMetal(item)"
          >
            add
          </v-btn>
        </template>
      </v-data-table>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            small
            @click="addMetal()"
          >
            add auto
          </v-btn>
        </v-col>
      </v-row>
    </edit-dialog>
    <hedge-search-dialog
      v-model="showHedgeSearchDialog"
      @selected="hedgeSelection"
    />
  </div>
</template>

<script>
import EditMetal from '@/views/Lots/components/Brokered/components/EditMetal'
import { BigNumber } from 'bignumber.js'
import HedgeSearchDialog from '@/components/HedgeSearchDialog.vue'

export default {
  name: 'BSMetalsForm',
  components: { HedgeSearchDialog, EditMetal },
  props: {
    bs: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    bt: {
      type: Object,
      required: true
    },
    tozToAllocate: {
      type: Array,
      required: true
    },
    allocations: {
      type: Array,
      default: Array
    },
    smelter: {
      type: Object,
      default: Object
    }
  },
  data: () => ({
    showHedgeSearchDialog: false,
    hedgeSearchAllocationIndex: null,
    addMetalDialog: false,
    unallocatedInfo: [
      'Platinum',
      'Palladium',
      'Rhodium'
    ],
    allocationHeaders: [
      { text: 'id', value: 'hedge.id' },
      { text: 'code', value: 'hedge.code' },
      { text: 'Pt Toz', value: 'pt' },
      { text: 'Pd Toz', value: 'pd' },
      { text: 'Rh Toz', value: 'rh' },
      { text: 'Pt Rate', value: 'hedge.pt_rate_per_toz' },
      { text: 'Pd Rate', value: 'hedge.pd_rate_per_toz' },
      { text: 'Rh Rate', value: 'hedge.rh_rate_per_toz' },
      { text: 'Use', value: 'use' }
    ]
  }),
  computed: {
    unallocatedMetals () {
      return this.metals.reduce((tum, m) => {
        return [
          BigNumber(tum[0]).minus(m.pt_returnable),
          BigNumber(tum[1]).minus(m.pd_returnable),
          BigNumber(tum[2]).minus(m.rh_returnable)
        ]
      }, this.tozToAllocate)
    },
    /**
     * Returns the metals from the Brokered Settlement
     * @returns {any}
     */
    metals () {
      if (this.bs.metals && this.bs.metals.length) return this.bs.metals
      return []
    },
    // Returns adjusted Troy oz for metals depending on whether yard terms are Contained or Returned
    getAllocations () {
      if (this.bt.ounce_payout_type === 'CO') {
        const ptReturnRate = +this.smelter.pt_return_rate
        const pdReturnRate = +this.smelter.pd_return_rate
        const rhReturnRate = +this.smelter.rh_return_rate
        return this.allocations.map(a => Object.assign({}, a, {
          pt: ptReturnRate ? (a.pt / ptReturnRate).toFixed(3) : 0,
          pd: pdReturnRate ? (a.pd / pdReturnRate).toFixed(3) : 0,
          rh: rhReturnRate ? (a.rh / rhReturnRate).toFixed(3) : 0
        })
        )
      }
      return this.allocations
    }
  },
  methods: {
    hedgeSelection (hedge) {
      const index = this.hedgeSearchAllocationIndex
      if (index < this.metals.length) {
        let metal = this.metals[index]
        metal.hedge_id = hedge.id
        this.setMetal(index, metal)
      } else {
        this.$store.commit('setSnackbarWarning', 'Setting value failed')
      }
    },
    openHedgeNumDialog (allocationIndex) {
      this.hedgeSearchAllocationIndex = allocationIndex
      this.showHedgeSearchDialog = true
    },
    /**
     * Calculates the value line for a metal
     * @param metal
     */
    updateMetalValues (metal) {
      for (const key of ['pt', 'pd', 'rh']) {
        metal[key + '_value'] = BigNumber(metal[key + '_returnable'])
          .multipliedBy(metal[key + '_mk'])
          .multipliedBy(metal[key + '_return_rate'])
          .dividedBy(100)
          .toFixed(2)
        metal[key + '_client_returnable'] = BigNumber(metal[key + '_returnable'])
          .multipliedBy(metal[key + '_return_rate'])
          .dividedBy(100)
          .toFixed(3)
      }
    },
    addMetal (allocation) {
      let clone = [...this.metals]

      let metal = {
        // remainder from unallocated
        pt_returnable: this.unallocatedMetals[0],
        pd_returnable: this.unallocatedMetals[1],
        rh_returnable: this.unallocatedMetals[2],
        // get from hedge
        pt_mk: 0,
        pd_mk: 0,
        rh_mk: 0,
        // get from terms
        pt_return_rate: this.bt.pt_rate || 100,
        pd_return_rate: this.bt.pd_rate || 100,
        rh_return_rate: this.bt.rh_rate || 100,
        // calculated totals
        pt_value: 0,
        pd_value: 0,
        rh_value: 0
      }
      if (allocation) {
        metal.pt_returnable = allocation.pt
        metal.pd_returnable = allocation.pd
        metal.rh_returnable = allocation.rh
        if (allocation?.hedge) {
          metal.hedge_id = allocation.hedge.id
          metal.pt_mk = allocation.hedge.pt_rate_per_toz
          metal.pd_mk = allocation.hedge.pd_rate_per_toz
          metal.rh_mk = allocation.hedge.rh_rate_per_toz
        }
      }

      this.updateMetalValues(metal)
      clone.push(metal)
      this.$emit('setProp', ['metals', clone])
      this.calculateGrossValue(clone)
      this.$store.commit('setSnackbarSuccess', 'Added Metal to Settlement')
    },
    setMetal (index, metal) {
      // recalculate each of the totals
      this.updateMetalValues(metal)

      // Copy the complete metal set and replace this metal group
      let clone = [...this.metals]
      clone[index] = metal
      this.$emit('setProp', ['metals', clone])
      this.calculateGrossValue(clone)
    },
    removeMetal (index) {
      if (confirm('Are you sure you want to remove the metal?')) {
        let clone = this.metals.slice(0, index).concat(this.metals.slice(index + 1))
        this.$emit('setProp', ['metals', clone])
        this.calculateGrossValue(clone)
      }
    },
    calculateGrossValue (metals) {
      const total = metals.reduce((t, m) => {
        return BigNumber.sum(t, m.pt_value, m.pd_value, m.rh_value)
      }, 0)
      this.$emit('setProp', ['gross_value', BigNumber(total).toFixed(2)])
    }
  }
}
</script>
