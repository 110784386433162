<template>
  <v-form
    v-model="valid"
    @submit.prevent="updateLot"
  >
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        :inputs-valid="valid && (!statusIsChangedMessage || confirmStatusChange)"
        :disabled="invalid"
        headline-text="Edit Lot"
        @input="$emit('input', $event)"
        @accept="updateLot"
      >
        <v-expand-transition>
          <app-loading-indicator
            v-if="loading"
          />
          <div v-else>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                :disabled="lotFinalized"
                v-model="status"
                :items="lotStatuses"
                label="Status"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                :disabled="lotFinalized"
                v-model="entityUrl"
                :items="entities"
                item-value="url"
                item-text="name"
                label="Entity"
                :error-messages="errors"
              />
            </ValidationProvider>
            <v-expand-transition>
              <ValidationProvider
                v-if="statusIsChangedMessage"
                rules="required"
                type="checkbox"
                v-slot="{ errors }"
              >
                <v-checkbox
                  v-model="confirmStatusChange"
                  :label="statusIsChangedMessage"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-expand-transition>
            Primary lot
            <div v-if="lot.children_data.length">
              <em>this lot is a primary lot</em>
            </div>
            <div v-else-if="lot.parent">
              <em>this lot is already a child lot</em>
              <v-checkbox
                v-model="disconnectFromParent"
                label="disconnect from parent lot"
              />
              <strong
                v-if="disconnectFromParent"
                class="red--text"
              >
                Disconnecting from parent lot will clear the "inventory lines". They will need to be manually re-allocated off of the parent lot.
              </strong>
            </div>
            <div v-else>
              <div>
                <strong>Primary: {{ lot.parent_data ? lot.parent_data.name : 'None' }}</strong>
                <v-btn
                  @click="editParent = !editParent"
                  small
                  icon
                >
                  <v-icon small>
                    {{ editParent ? 'mdi-pencil-off' : 'mdi-pencil' }}
                  </v-icon>
                </v-btn>
              </div>
              <v-expand-transition>
                <div v-if="editParent">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <search-autocomplete
                      v-model="parentLot"
                      label="Primary Lot (search)"
                      api-path="/api/lots/"
                      :params="{ fields: 'url,id,name', parent__isnull: true }"
                      :format-entry="l => Object.assign({ friendlyName: l.name }, l)"
                      clearable
                    />
                    <span
                      v-for="err in errors"
                      :key="err"
                      class="error--text"
                    >
                      {{ err }}
                    </span>
                  </ValidationProvider>
                </div>
              </v-expand-transition>
            </div>
            <div>
              <strong>Account Manager: {{ lot.account_manager__username || 'None' }}</strong>
              <v-btn
                @click="editAccountManager = !editAccountManager"
                small
                icon
              >
                <v-icon small>
                  {{ editAccountManager ? 'mdi-pencil-off' : 'mdi-pencil' }}
                </v-icon>
              </v-btn>
            </div>
            <v-expand-transition>
              <div v-if="editAccountManager">
                <ValidationProvider
                  v-slot="{ errors }"
                >
                  <search-autocomplete
                    v-model="accountManagerUrl"
                    label="Account Manager"
                    item-text="username"
                    item-value="url"
                    api-path="/api/users/"
                    :params="{ fields: 'username,url', is_active: true }"
                    :error-messages="errors"
                    :format-entry="x => Object.assign({ friendlyName: x.username }, x)"
                  />
                </ValidationProvider>
              </div>
            </v-expand-transition>
            <div class="my-4" />
            <ValidationProvider
              rules="required|max:55"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="name"
                label="Lot Name"
                counter="55"
                :error-messages="errors"
              />
            </ValidationProvider>
            <v-select
              label="Smelter"
              item-text="name"
              item-value="id"
              v-model="smelterId"
              :items="$store.getters['smelterStore/smelters']"
            />
            <v-text-field
              v-model="smelterLotId"
              label="Smelter Lot ID"
            />
            <v-select
              v-model="lotType"
              :items="lotMaterialType"
              label="Lot Material Type"
            />
            <v-select
              v-model="designation"
              :items="lotTypes"
              label="Designation"
            />
            <ValidationProvider
              rules="min_value:0"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="pieceCount"
                type="number"
                label="Manual Piece Count"
                :error-messages="errors"
              />
            </ValidationProvider>
            <date-input
              v-model="processingDate"
              label="Processing Date"
            />
            <date-input
              v-model="shipDate"
              label="Ship date"
            />
            <div class="grey--text mb-8">
              The ship date value will be automatically updated by the outbound transit.
            </div>
            <ValidationProvider
              rules="max:280"
              v-slot="{ errors }"
            >
              <v-textarea
                v-model="notes"
                label="Notes"
                counter="280"
                :error-messages="errors"
              />
            </ValidationProvider>
            <br>
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-btn
                  :disabled="!(lot.status === 'CO' && $store.getters.can('update-lot-status-back'))"
                  @click="pushBack"
                >
                  Push back to Reconcile
                </v-btn>
              </template>
              Available when status is Complete. Requires 'update-lot-status-back'
            </info-tooltip>
          </div>
        </v-expand-transition>
      </edit-dialog>
    </ValidationObserver>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'
import { fetchEntities } from '@/requests/entities'

export default {
  name: 'EditLot',
  components: { SearchAutocomplete },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    lot: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      loading: false,
      disconnectFromParent: false,
      editParent: false,
      parentLot: null,
      confirmStatusChange: false,
      status: null,
      entityUrl: null,
      valid: true,
      pieceCount: null,
      lotType: null,
      designation: null,
      notes: null,
      smelterId: null,
      smelterLotId: '',
      shipDate: null,
      processingDate: null,
      accountManagerUrl: null,
      editAccountManager: false,
      entities: []
    }
  },
  watch: {
    value: function (val) {
      if (!val) return
      this.initialize()
      this.entityUrl = this.lot.entity
      this.confirmStatusChange = false
      this.status = this.lot.status
      this.name = this.lot.name
      this.pieceCount = this.lot.piece_count
      this.lotType = this.lot.lot_type
      this.designation = this.lot.designation
      this.notes = this.lot.notes
      this.accountManagerUrl = null
      if (this.lot.smelter) {
        this.smelterId = this.lot.smelter.id
        this.smelterLotId = this.lot.smelter_lot_id
      }
      this.shipDate = this.lot.ship_date
      this.processingDate = this.lot.processing_date
      this.editParent = false
      this.editAccountManager = false
      this.parentLot = null
    }
  },
  computed: {
    ...mapGetters('lotStore', [
      'lotTypes',
      'lotMaterialType',
      'lotStatuses'
    ]),
    lotFinalized () {
      return this.$store.getters['lotStore/finalStatuses'].includes(this.lot.status)
    },
    statusIsChanged () {
      return this.lot.status !== this.status
    },
    statusIsChangedMessage () {
      if (this.statusIsChanged) {
        switch (this.status) {
          case 'DS':
            return 'Boxes in Inventory will be marked as "ACTIVE" and disconnected from this lot'
          case 'SH':
            return 'Boxes in Inventory will be marked as "SHIPPED"'
        }
      }
      return ''
    }
  },
  methods: {
    async getEntities () {
      if (this.entities.length === 0) {
        const response = await fetchEntities()
        if (response.status === 200) {
          this.entities = response.data
        }
      }
    },
    async pushBack () {
      if (confirm('Are you sure you want to push the lot status back?')) {
        await this.$store.dispatch('lotStore/updateLot', { id: this.lot.id, status: 'RC' })
        return this.$store.dispatch('lotStore/fetchLot', this.lot.id)
      }
    },
    initialize () {
      this.loading = true
      Promise.all([
        this.getEntities(),
        this.$store.dispatch('smelterStore/fetchSmelters')
      ]).finally(() => {
        this.loading = false
      })
    },
    async updateLot () {
      if (this.statusIsChangedMessage && !this.confirmStatusChange) {
        this.$store.commit('setSnackbarError', 'Please acknowledge status change warning')
        return
      }
      const payload = {
        id: this.$route.params.id,
        status: this.status,
        entity: this.entityUrl,
        name: this.name,
        piece_count: this.pieceCount,
        lot_type: this.lotType,
        designation: this.designation,
        notes: this.notes,
        smelter_id: this.smelterId,
        smelter_lot_id: this.smelterLotId,
        ship_date: this.shipDate,
        processing_date: this.processingDate,
        parent: null
      }
      if (this.editAccountManager) {
        payload.account_manager = this.accountManagerUrl
      }
      if (this.parentLot) {
        if (Number(this.$route.params.id) === this.parentLot.id) {
          this.$store.commit('setSnackbarError', 'Unable to assign lot it itself')
          return
        }
        payload.parent = this.parentLot.url
      }

      if (this.disconnectFromParent) {
        payload.parent = null
        if (this.lot.inventory) {
          await this.$store.dispatch('lotStore/deleteInventoryLines', this.lot.inventory.id)
        }
      }

      await this.$store.dispatch('lotStore/updateLot', payload)
      await this.$store.dispatch('lotStore/fetchLot', this.$route.params.id)
      this.$emit('input', false)
    }
  }
}
</script>
