<template>
  <unauthorized
    :authorized="$store.getters.can('edit-cut-plans')"
    message="You are not authorized to view Reallocate Purchases requires edit-cut-plans"
  >
    <app-loading-indicator
      v-if="loading"
    />
    <div v-if="lot.id">
      <url-breadcrumbs
        v-if="lot"
        :items="[
          ['lot list', { name: 'lot-list' }],
          [`Lot #${lot.id}`, { name: 'lot' }],
          ['Purchase Reallocation'],
        ]"
      />
      <v-alert
        v-for="(alertText, index) in alerts"
        :key="index"
        outlined
        color="warning"
      >
        {{ alertText }}
      </v-alert>
      <base-title>
        Lot {{ lot.name }}
      </base-title>
      <div class="my-4" />
      <search-autocomplete
        v-model="targetLot"
        label="Select a Target Lot"
        item-text="friendlyName"
        api-path="/api/lots/"
        :params="{ fields: 'url,id,name' }"
        :format-entry="lot => Object.assign({}, { friendlyName: lot.name }, lot)"
      />
      <div class="my-4" />
      <v-expand-transition>
        <div v-if="targetLot && targetLot.id !== lot.id">
          <v-data-table
            v-model="selected"
            :disabled="transferring"
            :headers="headers"
            :search="filter"
            :items="purchaseList"
            item-key="allocationId"
            show-select
          >
            <template v-slot:top>
              <div class="d-flex justify-space-between align-center px-4">
                <div class="subtitle-1">
                  {{ selected.length }} Items Selected
                </div>
                <v-sheet width="300px">
                  <v-text-field
                    v-model="filter"
                    label="Filter"
                    clearable
                  />
                </v-sheet>
              </div>
            </template>
            <template v-slot:item.checkIns="{ item }">
              <div class="d-flex justify-space-between">
                <span>
                  {{ item.checkIns }}
                </span>
                <span class="caption">{{ item.purchaseId }}</span>
              </div>
            </template>
          </v-data-table>
          <div class="my-4" />
          <v-expand-transition>
            <div
              v-if="!transferring"
              class="d-flex justify-end"
            >
              <v-btn
                :loading="transferring"
                :disabled="selected.length === 0"
                color="primary"
                @click="transferAllocations"
              >
                Transfer Allocations
              </v-btn>
            </div>
            <v-progress-linear
              v-else
              :value="transferPercent"
            />
          </v-expand-transition>
        </div>
      </v-expand-transition>
    </div>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'

export default {
  name: 'LotReallocatePurchases',
  components: { SearchAutocomplete },
  data: () => ({
    targetLot: null,
    transferPercent: 0,
    selected: [],
    purchases: {},
    loading: true,
    transferring: false,
    filter: '',
    headers: [
      { text: 'CheckIn (Purchase)', value: 'checkIns' },
      { text: 'type', value: 'type' }
    ]
  }),
  computed: {
    ...mapGetters({
      lot: 'lotStore/lot',
      getConverterTypes: 'getConverterTypes'
    }),
    alerts () {
      const alerts = []
      if (this.lot && this.lot.status === 'CO') alerts.push('The source lot is COMPLETE')
      if (this.lot && this.lot.status === 'DS') alerts.push('The source lot is DISSOLVED')
      if (this.targetLot && this.targetLot.status === 'DS') alerts.push('The target lot is DISSOLVED')
      if (this.targetLot && this.targetLot.status === 'CO') alerts.push('The target lot is COMPLETE')
      return alerts
    },
    converterTypes () {
      return this.getConverterTypes.reduce((carry, ct) => {
        carry[ct.value] = ct.text
        return carry
      }, {})
    },
    purchaseList () {
      return this.lot.purchases.map(pl => {
        let checkIns = ''
        try {
          const p = this.purchases?.[pl.purchase_id]
          const cis = p?.check_ins_details
          if (cis) checkIns = cis.map(ci => `${ci.id} ${ci.yard_name}`).join(', ')
        } catch (e) {
          this.$store.commit('setSnackbarWarning', `${e.message} for purchase ${pl.purchase_id}`)
        }
        return {
          allocationId: pl.id,
          purchaseId: pl.purchase_id,
          checkIns,
          type: this.converterTypes[pl.converter_type]
        }
      })
    }
  },
  methods: {
    ...mapActions({
      fetchLot: 'lotStore/fetchLot'
    }),
    async transferAllocations () {
      if (confirm(`Transfer ${this.selected.length} Allocations`)) {
        this.transferPercent = 0
        this.transferring = true
        for (const selected in this.selected) {
          this.transferPercent = Math.round(selected / this.selected.length * 100)
          await axios.patch(`/api/purchase_lots/${this.selected[selected].allocationId}/`, { lot_id: this.targetLot.id })
        }
        this.selected = []
        this.transferring = false
        this.$store.commit('setSnackbarSuccess', 'Transferring Purchases Complete')
        this.initialize()
      }
    },
    async fetchPurchases (purchaseIds) {
      if (purchaseIds.length === 0) return
      const response = await axios.get('/api/purchases/', { params: { id__in: purchaseIds.join(','), page_size: 0 } })
      if (response.data) {
        this.purchases = response.data.reduce((carry, purch) => {
          carry[purch.id] = purch
          return carry
        }, {})
      } else {
        this.$store.commit('setSnackbarWarning', 'Unable to fetch related purchases')
      }
    },
    async initialize () {
      await this.fetchLot(this.$route.params.id)
      const purchaseIds = [...new Set(this.lot.purchases.map(x => x.purchase_id))]
      await this.fetchPurchases(purchaseIds)
      this.loading = false
    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
