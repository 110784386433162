<template>
  <unauthorized
    :authorized="$store.getters.can('view-internal-proforma')"
    message="You are not authorized to view lots"
  >
    <brokered-settlement-skeleton
      v-if="loading"
      title="Internal Proforma"
    />
    <ValidationObserver
      v-else
      v-slot="{ invalid, reset }"
    >
      <v-form
        :readonly="isLocked"
        :value="invalid"
        class="mb-16"
      >
        <v-container>
          <url-breadcrumbs
            v-if="breadcrumbs"
            :items="breadcrumbs"
          />
          <h1>
            Internal Proforma
          </h1>
          <v-toolbar
            flat
            dense
            color="transparent"
          >
            <v-btn
              :color="isLocked ? 'error' : ''"
              small
              rounded
              @click="isLocked = !isLocked"
            >
              {{ isLocked ? 'Locked' : 'unlocked' }}
              <v-icon
                small
                class="ml-2"
              >
                {{ isLocked ? 'mdi-lock-outline' : 'mdi-lock-open-outline' }}
              </v-icon>
            </v-btn>
            <v-spacer />
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-btn
                  :disabled="isLocked"
                  text
                  @click="refreshData"
                >
                  Refresh Data
                </v-btn>
              </template>
              Replace values with the latest lot data.
            </info-tooltip>
            <v-btn
              text
              class="ml-2"
              target="_blank"
              :to="{ name: 'internal-proforma-print', params: { id: $route.params.id } }"
            >
              Print
            </v-btn>
          </v-toolbar>
          <v-select
            :value="historyVersion"
            label="Revision History"
            :items="history"
            :item-text="historyLabelFormat"
            item-value="id"
            @change="setVersionFromHistoryById"
          />
          <div>
            <h2>
              Properties
            </h2>
            <v-sheet class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="lotName"
                    readonly
                    class="dense-filled"
                    label="Lot Name"
                    validation-rules="required|max:400"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    immediate
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="Yard"
                      v-model="yardId"
                      :items="$store.getters['internalProformaStore/getYardChoices']"
                      :item-text="x => `${x.id} - ${x.name} ${x.address || ''}, ${x.city || ''} ${x.state || ''}`"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    label="Est. Ship Date"
                    v-model="shipDate"
                    :show-date-picker="!isLocked"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                    v-model="convertersProcessed"
                    label="Converters Processed"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    validation-rules="required|max_value:999999999|min_value:0|decimal:3"
                    v-model="mixedCatalystWeight"
                    label="Mixed Catalyst Weight/Received Lbs"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-date
                    label="Client Value Date"
                    v-model="clientValueDate"
                    :show-date-picker="!isLocked"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Outright Units
            </h2>
            If the units are already paid, the are not included in the deposit calculation
            <v-sheet class="pa-4 mb-6">
              <v-simple-table>
                <thead>
                  <tr>
                    <th
                      v-for="h in ['Type', 'Quantity', 'Unit Price', 'Total Price', 'Mark Paid']"
                      :key="h"
                      :style="'min-width:' + (['Type', 'Mark Paid'].includes(h)) ? 50 : 100 + 'px;'"
                      v-text="h"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="outright in $store.getters['internalProformaStore/getPurchasedOutright']"
                    :key="outright.materialType"
                  >
                    <td>
                      <strong>{{ outright.materialType }}</strong>
                    </td>
                    <td>
                      <rules-text
                        validation-rules="required|max_value:2147483647|min_value:0|decimal:0"
                        :value="outright.quantity"
                        type="number"
                        @input="updateOutrightValue(outright.materialType, 'quantity', $event)"
                      />
                    </td>
                    <td>
                      <rules-text
                        validation-rules="required|max_value:9999999999|min_value:0|decimal:2"
                        :value="outright.price"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                        @input="updateOutrightValue(outright.materialType, 'price', $event)"
                      />
                    </td>
                    <td class="pt-2">
                      <rules-text
                        dense
                        class="dense-filled"
                        readonly
                        validation-rules="required|max_value:9999999999|min_value:0|decimal:2"
                        :value="outright.totalPrice"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                      />
                    </td>
                    <td class="pb-4 pl-6">
                      <v-simple-checkbox
                        :value="outright.paid"
                        color="primary"
                        @input="updateOutrightValue(outright.materialType, 'paid', $event)"
                      />
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-row>
                <v-col
                  cols="12"
                  offset-md="9"
                  md="3"
                >
                  <rules-text
                    class="dense-filled"
                    :value="grossOutrightUnpaidValue"
                    label="Gross Unpaid Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Sum of Unpaid Outright"
                  />
                </v-col>
              </v-row>
            </v-sheet>
            <div class="py-4" />
            <h2>
              Metal Allocations
            </h2>
            <v-sheet class="pa-4">
              <span class="h2">Expected Return Values for Lot</span>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="availablePt"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Pt"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedPt / availablePt * 100"
                    color="#0068b9"
                    rounded
                  >
                    <span class="font-weight-medium white--text">
                      {{ allocatedPt }} / {{ availablePt }} rtoz
                    </span>
                  </v-progress-linear>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="availablePd"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Pd"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedPd / availablePd * 100"
                    color="#d00e00"
                    rounded
                  >
                    <span class="font-weight-medium white--text">
                      {{ allocatedPd }} / {{ availablePd }} rtoz
                    </span>
                  </v-progress-linear>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    v-model="availableRh"
                    readonly
                    type="number"
                    class="dense-filled"
                    validation-rules="required|max_value:99999999|min_value:0|decimal:3"
                    label="Available Rh"
                  />
                  <v-progress-linear
                    height="24"
                    :value="allocatedRh / availableRh * 100"
                    color="#006104"
                    rounded
                  >
                    <span class="font-weight-medium white--text">
                      {{ allocatedRh }} / {{ availableRh }} rtoz
                    </span>
                  </v-progress-linear>
                </v-col>
              </v-row>
              <div
                v-for="allocation in allocations"
                :key="allocation.hedgeId"
              >
                <div class="d-flex justify-space-between align-center">
                  <div class="text-h6">
                    Hedge #{{ allocation.hedgeId }}
                  </div>
                  <v-btn
                    :disabled="isLocked"
                    outlined
                    x-small
                    @click="removeAllocation(allocation)"
                  >
                    Remove
                  </v-btn>
                </div>
                <v-row>
                  <v-col>
                    <internal-proforma-metal
                      alternate-style
                      :hedge="allocation"
                      @setHedge="updateAllocations(allocation.hedgeId, $event)"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="mt-8">
                <div class="text-h6">
                  Add Hedges
                </div>
                <div class="d-flex align-center">
                  <search-autocomplete
                    v-model="selectedHedge"
                    class="mx-4"
                    label="Search for Hedges"
                    item-text="friendlyName"
                    api-path="/api/hedges/"
                    :params="{ ordering: '-id' }"
                    :format-entry="x => Object.assign({}, { friendlyName: `Hedge #${x.id} ${x.pt_available_toz}@$${x.pt_rate_per_toz_fx} / ${x.pd_available_toz}@$${x.pd_rate_per_toz_fx} / ${x.rh_available_toz}@$${x.rh_rate_per_toz_fx}` }, x)"
                  />
                  <v-btn
                    :disabled="!selectedHedge || isLocked"
                    class="mr-4"
                    color="primary"
                    outlined
                    x-small
                    @click="addSearchedHedge(selectedHedge)"
                  >
                    Add
                  </v-btn>
                </div>
                <v-list>
                  <v-list-item
                    v-for="hedge in unallocatedHedges"
                    :key="hedge.id"
                  >
                    <v-list-item-content>
                      {{
                        `Hedge #${hedge.id} ${hedge.ptAvailableToz}@${hedge.ptRate} / ${hedge.pdAvailableToz}@${hedge.pdRate} / ${hedge.rhAvailableToz}@${hedge.rhRate}`
                      }}
                      <span class="text-caption">designated</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="isLocked"
                        color="accent"
                        outlined
                        x-small
                        @click="addHedge(hedge)"
                      >
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-content>
                      Empty Hedge
                      <span class="text-caption">custom</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="isLocked"
                        outlined
                        x-small
                        @click="addBlankHedge"
                      >
                        Add
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="allocatedReturnablePt"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Pt rtoz"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    :value="allocatedReturnablePd"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Pd rtoz"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <rules-text
                    immediate
                    :value="allocatedReturnableRh"
                    class="dense-filled"
                    readonly
                    type="number"
                    label="Total Returnable Rh rtoz"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  offset-md="9"
                  md="3"
                >
                  <rules-text
                    class="dense-filled"
                    :value="grossMetalValue"
                    label="Gross Metal Value"
                    prepend-icon="mdi-currency-usd"
                    hint="Sum Hedge Values"
                  />
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <div class="py-4" />
          <h2>
            Fees
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="refiningFee"
                  type="number"
                  label="Refining Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="processingFee"
                  type="number"
                  label="Processing Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="handlingFee"
                  type="number"
                  label="Handling Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="freightCharge"
                  type="number"
                  label="Freight Charge"
                  hint="Users total from allocated transits."
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="assayFee"
                  type="number"
                  label="Assay Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="shreddingFee"
                  type="number"
                  label="Shredding Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="brokerageFee"
                  type="number"
                  label="Brokerage Fee"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  v-model="netOtherFees"
                  type="number"
                  label="Net Other Fees"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  class="px-4"
                  v-model="consolidateFees"
                  label="Consolidate Fees When Printing"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="totalFees"
                  readonly
                  class="dense-filled"
                  type="number"
                  label="Total Fees"
                  prepend-icon="mdi-currency-usd"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  :value="netValue"
                  readonly
                  class="dense-filled"
                  validation-rules="required|max_value:99999999|min_value:0|decimal:2"
                  type="number"
                  label="Net Value"
                  prepend-icon="mdi-currency-usd"
                  hint="Unpaid Value + Metal Value - Fees"
                />
              </v-col>
            </v-row>
          </v-sheet>
          <div class="py-4" />
          <h2>
            Finance / Amount Due
          </h2>
          <v-sheet class="pa-4">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <rules-date
                  v-model="expectedPaymentDate"
                  label="Expected Payment Date"
                  prepend-icon="mdi-calendar"
                  :show-date-picker="!isLocked"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  readonly
                  class="dense-filled"
                  v-model="daysOfInterest"
                  label="Days of Interest"
                  type="number"
                  hint="Client Value Date - Expected Payment Date"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="interestPercent"
                  label="Interest Rate %"
                  type="number"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <rules-text
                  v-model="financeCharge"
                  class="dense-filled"
                  readonly
                  label="Finance Charge"
                  type="number"
                  hint="Net Value * Interest Percent / 360 * Days of Interest"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                offset-md="9"
                md="3"
              >
                <rules-text
                  :value="settlementAmountDue"
                  readonly
                  class="dense-filled"
                  label="Settlement Amount Due"
                  type="number"
                  hint="Net Value + Finance Charge"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-container>
        <text-fab
          v-if="!isLocked"
          bottom
          right
          fixed
          icon-text="mdi-content-save-outline"
          :color="isModified ? 'primary': ''"
          @click="save"
        >
          Save Changes
        </text-fab>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>

import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton.vue'
import RulesText from '@/components/ValidationFields/RulesText.vue'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import moment from 'moment'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'
import { mapGetters } from 'vuex'
import InternalProformaMetal from '@/views/Lots/InternalProforma/InternalProformaMetal.vue'

export default {
  name: 'InternalProforma',
  components: {
    InternalProformaMetal,
    SearchAutocomplete,
    RulesDate,
    RulesText,
    BrokeredSettlementSkeleton
  },
  data () {
    return {
      isModified: false,
      selectedHedge: null,
      breadcrumbs: [
        ['lot list', { name: 'lot-list' }],
        [`Lot #${this.$route.params.id}`, { name: 'lot' }],
        [`Internal Proforma`, { name: 'internal-proforma' }]
      ],
      yardChoices: [],
      loading: false,
      readonly: true
    }
  },
  watch: {
    yardId () {
      this.$store.dispatch('internalProformaStore/fetchYardTerms')
    },
    snapshot () {
      this.isModified = true
    }
  },
  computed: {
    advanceQuery () {
      return {
        lot: this.lotId,
        amount: this.recommendedAdvance,
        redirect: JSON.stringify({
          name: 'advance-calculator',
          params: { id: this.lotId }
        })
      }
    },
    snapshot () {
      return [
        this.$store.getters['internalProformaStore/getStateSnapshot']
      ]
    },
    isLocked: {
      get () {
        return this.$store.getters['internalProformaStore/getIsLocked']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setIsLocked', value)
      }
    },
    yardId: {
      get () {
        return this.$store.getters['internalProformaStore/getYardId']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setYardId', value)
      }
    },
    shipDate: {
      get () {
        return this.$store.getters['internalProformaStore/getShipDate']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setShipDate', value)
      }
    },
    convertersProcessed: {
      get () {
        return this.$store.getters['internalProformaStore/getConvertersProcessed']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setConvertersProcessed', value)
      }
    },
    mixedCatalystWeight: {
      get () {
        return this.$store.getters['internalProformaStore/getMixedCatalystWeight']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setMixedCatalystWeight', value)
      }
    },
    clientValueDate: {
      get () {
        return this.$store.getters['internalProformaStore/getClientValueDate']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setClientValueDate', value)
      }
    },
    assayFee: {
      get () {
        return this.$store.getters['internalProformaStore/getAssayFee']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setAssayFee', value)
      }
    },
    shreddingFee: {
      get () {
        return this.$store.getters['internalProformaStore/getShreddingFee']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setShreddingFee', value)
      }
    },
    brokerageFee: {
      get () {
        return this.$store.getters['internalProformaStore/getBrokerageFee']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setBrokerageFee', value)
      }
    },
    refiningFee: {
      get () {
        return this.$store.getters['internalProformaStore/getRefiningFee']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setRefiningFee', value)
      }
    },
    processingFee: {
      get () {
        return this.$store.getters['internalProformaStore/getProcessingFee']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setProcessingFee', value)
      }
    },
    handlingFee: {
      get () {
        return this.$store.getters['internalProformaStore/getHandlingFee']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setHandlingFee', value)
      }
    },
    freightCharge: {
      get () {
        return this.$store.getters['internalProformaStore/getFreightCharge']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setFreightCharge', value)
      }
    },
    netOtherFees: {
      get () {
        return this.$store.getters['internalProformaStore/getNetOtherFees']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setNetOtherFees', value)
      }
    },
    consolidateFees: {
      get () {
        return this.$store.getters['internalProformaStore/getConsolidateFees']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setConsolidateFees', value)
      }
    },
    expectedPaymentDate: {
      get () {
        return this.$store.getters['internalProformaStore/getExpectedPaymentDate']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setExpectedPaymentDate', value)
      }
    },
    interestPercent: {
      get () {
        return this.$store.getters['internalProformaStore/getInterestPercent']
      },
      set (value) {
        this.$store.commit('internalProformaStore/setInterestPercent', value)
      }
    },
    allocations () {
      return this.$store.getters['internalProformaStore/getAllocations'].map(allocation => ({
        hedgeId: allocation.hedgeId,
        pt_returnable: allocation.ptToz,
        pt_return_rate: allocation.ptRate,
        pt_client_returnable: allocation.ptRtoz,
        pt_mk: allocation.ptMarketRate,
        pt_value: allocation.ptValue,
        pd_returnable: allocation.pdToz,
        pd_return_rate: allocation.pdRate,
        pd_client_returnable: allocation.pdRtoz,
        pd_mk: allocation.pdMarketRate,
        pd_value: allocation.pdValue,
        rh_returnable: allocation.rhToz,
        rh_return_rate: allocation.rhRate,
        rh_client_returnable: allocation.rhRtoz,
        rh_mk: allocation.rhMarketRate,
        rh_value: allocation.rhValue
      }))
    },
    ...mapGetters('internalProformaStore', {
      termsRefiningFeeTotal: 'getTermsRefiningFeeTotal',
      termsTreatmentCharge: 'getTermsTreatmentCharge',
      termsHandlingFee: 'getTermsHandlingFee',
      allocatedPd: 'getAllocatedPd',
      allocatedPt: 'getAllocatedPt',
      allocatedRh: 'getAllocatedRh',
      allocatedReturnablePd: 'getAllocatedReturnablePd',
      allocatedReturnablePt: 'getAllocatedReturnablePt',
      allocatedReturnableRh: 'getAllocatedReturnableRh',
      availablePd: 'getAvailablePd',
      availablePt: 'getAvailablePt',
      availableRh: 'getAvailableRh',
      daysOfInterest: 'getDaysOfInterest',
      financeCharge: 'getFinanceCharge',
      grossMetalValue: 'getGrossMetalValue',
      grossOutrightUnpaidValue: 'getGrossOutrightUnpaidValue',
      history: 'getAdvanceCalculatorHistory',
      historyVersion: 'getHistoryVersion',
      lotId: 'getLotId',
      lotName: 'getLotName',
      netValue: 'getNetValue',
      settlementAmountDue: 'getSettlementAmountDue',
      totalFees: 'getTotalFees',
      unallocatedHedges: 'getUnallocatedHedges',
      unallocatedPd: 'getUnallocatedPd',
      unallocatedPt: 'getUnallocatedPt',
      unallocatedRh: 'getUnallocatedRh'
    })
  },
  methods: {
    historyLabelFormat (history) {
      let text = `${history.id}. ${moment(history.created_at).format('MM/DD/YYYY HH:mm:ss')} ${history.fx_currency}`
      if (this.history.length > 0 && history.id === this.history[0].id) text += ' (Latest)'
      return text
    },
    addSearchedHedge (hedge) {
      this.addHedge({
        id: hedge.id,
        ptAvailableToz: hedge.pt_available_toz,
        ptRate: hedge.pt_rate_per_toz_fx,
        pdAvailableToz: hedge.pd_available_toz,
        pdRate: hedge.pd_rate_per_toz_fx,
        rhAvailableToz: hedge.rh_available_toz,
        rhRate: hedge.rh_rate_per_toz_fx
      })
    },
    addBlankHedge () {
      function getNextCSequence (ids) {
        let cValue = 0
        for (const id of ids) {
          if (!(typeof id === 'string') || !id.startsWith('C')) continue
          const x = parseInt(id.slice(1))
          if (isNaN(x) || x < cValue) continue
          cValue = x
        }
        // Return the next C sequence value up to the target sequence (e.g., "C5")
        return 'C' + (cValue + 1)
      }

      this.addHedge({
        id: getNextCSequence(this.allocations.map(x => x.hedgeId)),
        ptAvailableToz: this.unallocatedPt,
        ptRate: 0,
        pdAvailableToz: this.unallocatedPd,
        pdRate: 0,
        rhAvailableToz: this.unallocatedRh,
        rhRate: 0
      })
    },
    addHedge (hedge) {
      if (this.allocations.find(alloc => alloc.hedgeId === hedge.id)) {
        this.$store.commit('setSnackbarWarning', 'Allocation for Hedge already exists.')
        return
      }
      const addHedge = {
        hedgeId: hedge.id,
        ptRtoz: +hedge.ptAvailableToz < +this.unallocatedPt ? hedge.ptAvailableToz : this.unallocatedPt,
        ptMarketRate: hedge.ptRate,
        pdRtoz: +hedge.pdAvailableToz < +this.unallocatedPd ? hedge.pdAvailableToz : this.unallocatedPd,
        pdMarketRate: hedge.pdRate,
        rhRtoz: +hedge.rhAvailableToz < +this.unallocatedRh ? hedge.rhAvailableToz : this.unallocatedRh,
        rhMarketRate: hedge.rhRate
      }
      this.$store.commit('internalProformaStore/addHedgeToAllocations', addHedge)
      this.isModified = true
    },
    updateAllocations (hedgeId, allocation) {
      let updatedAllocations = []
      for (const line of this.$store.getters['internalProformaStore/getAllocations']) {
        if (line.hedgeId === hedgeId) {
          // line.ptToz = allocation.pt_returnable
          // line.ptRate = allocation.pt_return_rate
          line.ptRtoz = allocation.pt_client_returnable
          line.ptMarketRate = allocation.pt_mk
          line.ptValue = allocation.pt_value
          // line.pdToz = allocation.pd_returnable
          // line.pdRate = allocation.pd_return_rate
          line.pdRtoz = allocation.pd_client_returnable
          line.pdMarketRate = allocation.pd_mk
          line.pdValue = allocation.pd_value
          // line.rhToz = allocation.rh_returnable
          // line.rhRate = allocation.rh_return_rate
          line.rhRtoz = allocation.rh_client_returnable
          line.rhMarketRate = allocation.rh_mk
          line.rhValue = allocation.rh_value
        }
        updatedAllocations.push(line)
      }
      this.$store.commit('internalProformaStore/setAllocations', updatedAllocations)
    },
    updateOutrightValue (material, key, value) {
      let updatedLines = []
      for (const line of this.$store.getters['internalProformaStore/getPurchasedOutright']) {
        if (line.materialType === material) {
          line[key] = value
        }
        updatedLines.push(line)
      }
      this.$store.commit('internalProformaStore/setPurchasedOutright', updatedLines)
    },
    removeAllocation (allocation) {
      this.isModified = true
      this.$store.commit('internalProformaStore/removeAllocation', allocation.hedgeId)
    },
    save () {
      this.$store.dispatch('internalProformaStore/saveAdvanceCalculator')
      this.isModified = false
    },
    async initialize () {
      return Promise.all([
        this.$store.dispatch('internalProformaStore/fetchAdvanceCalculators'),
        this.$store.dispatch('internalProformaStore/fetchDesignatedHedges')
      ])
    },
    setVersionFromHistoryById (id) {
      const history = this.history.find(h => h.id === id)
      if (history) {
        this.$store.commit('internalProformaStore/setContextFromHistory', history)
        return true
      }
      return false
    },
    refreshData () {
      return this.$store.dispatch('internalProformaStore/refreshDataFromLotData')
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isModified) {
      if (!window.confirm('Leave without saving?')) {
        return
      }
    }
    next()
  },
  /*
   * Fetch the history, if no history then fetch the latest lot seed data
   */
  async mounted () {
    this.loading = true
    this.$store.commit('internalProformaStore/clearState')
    this.$store.commit('internalProformaStore/setLotId', this.$route.params.id)
    await this.initialize()
    if (this.history.length) {
      this.$store.commit('internalProformaStore/setContextFromHistory', this.history[0])
    } else {
      // if there is no history, then seed data
      await this.refreshData()
      this.isLocked = false
    }
    this.loading = false
  }
}
</script>
