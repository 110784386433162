<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-lots')"
    message="You are not authorized to view Lots"
  >
    <v-container fluid>
      <router-view />
    </v-container>
  </unauthorized>
</template>

<script>
export default {
  name: 'LotParent'
}
</script>
