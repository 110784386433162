import axios from 'axios'

export const TYPE = {
  LAB: { text: 'XRF/ICP Lab', value: 'LAB' },
  XRF: { text: 'XRF Lab', value: 'XRF' },
  ICP: { text: 'ICP Lab', value: 'ICP' },
  SMT: { text: 'Smelter Assay', value: 'SMT' }
}

/**
 * @param lot {int} the id of the lot for which results are requested
 * @returns {Promise<AxiosResponse<any>>}
 */
export function fetchResultsForLot (lot) {
  if (!lot) {
    throw new Error('Missing required parameter')
  }

  let config = {}
  config.params = { lot, page_size: 0 }

  return axios.get('/api/assay_result/', config)
}

export function createAssayResult (pt, pd, rh, status, percentMoisture, percentScrap, lot, date, source, assayType) {
  if (pt === undefined || pd === undefined || rh === undefined || !lot || !date) {
    throw new Error('Missing required parameter(s)')
  }

  let payload = {
    pt_ppm: pt,
    pd_ppm: pd,
    rh_ppm: rh,
    status: status,
    percent_moisture: percentMoisture,
    percent_scrap: percentScrap,
    date: date,
    lot: lot,
    source: source
  }
  if (assayType) {
    if (!TYPE[assayType]) {
      throw new Error('An invalid assay type was provided')
    }
    payload.assay_type = assayType
  }

  return axios.post('/api/assay_result/', payload)
}

export function updateAssayResult (id, payload) {
  if (payload.assay_type) {
    if (!TYPE[payload.assay_type]) {
      throw new Error('An invalid assay type was provided')
    }
  }

  return axios.patch(`/api/assay_result/${id}/`, payload)
}

/**
 *
 * @param ids {str}
 * @returns {Promise<any>}
 */
export async function getAssayResultComparisons (...ids) {
  if (!ids.length) return []
  const params = { ids: ids.join(',') }
  const response = await axios.get('/api/assay_result/lot_comparison/', { params })
  if (response.data) return response.data
}

let sourceOptions = []
export async function fetchSources () {
  if (sourceOptions.length === 0) {
    const response = await axios.get('/api/assay_result_sources/')
    sourceOptions = response.data
  }
  return sourceOptions
}
