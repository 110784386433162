import axios from 'axios'

export function fetchTransitById (id) {
  return axios.get(`/logistics/transits/${id}/`)
}

export function sendTransitDeliveredEmail (transitId) {
  return axios.post(`/logistics/transits/${transitId}/send_delivered_emails/`)
}

export function sendOutboundInTransitEmail (transitId) {
  return axios.post(`/logistics/transits/${transitId}/send_in_transit_email/`)
}

export function patchTransitById (id, payload) {
  return axios.patch(`/logistics/transits/${id}/`, payload)
}

export function deleteTransitById (id) {
  return axios.delete(`/logistics/transits/${id}/`)
}
export function fetchAllBrokers () {
  return axios.get('/logistics/brokers/?page_size=0&status=GS&fields=url,name')
}
export function fetchAllCarriers () {
  return axios.get('/logistics/carriers/?page_size=0&status=GS&fields=url,name')
}

export function findDetachedLotManifestByLotName (lotName) {
  return axios.get(`/api/lot_manifests/?transit__isnull=true&search=${lotName}`)
}

export function findAllTransitRequestedLotManifests () {
  return axios.get(`/api/lot_manifests/?transit_requested=true&transit__isnull=true&page_size=0`)
}

export function updateLotManifestById (id, payload) {
  return axios.patch(`/api/lot_manifests/${id}/`, payload)
}

export function fetchLotManifestById (id) {
  return axios.get(`/api/lot_manifests/${id}/`)
}

export function deleteLotManifestById (manifestId) {
  return axios.delete(`/api/lot_manifests/${manifestId}/`)
}

export function submitForApproval (manifestId) {
  return axios.post(`/api/lot_manifests/${manifestId}/request_approval/`)
}

export function submitTransitRequest (manifestId) {
  return axios.post(`/api/lot_manifests/${manifestId}/request_transit/`)
}
