<script>
import BrokeredSettlementForm from './components/BrokeredSettlementForm'
import axios from 'axios'

export default {
  extends: BrokeredSettlementForm,
  name: 'EditBrokeredSettlement',
  methods: {
    /**
     * Sets the yard choices, and ensures the yard option is present even if removed from Lot
     * @param lot
     * @param bs
     */
    setYardChoices (lot, bs) {
      let yards = [...lot.yards]
      if (bs.yard && !yards.find(y => y.id === bs.yard.id)) yards.push(bs.yard)
      this.config = ['yardChoices', yards]
    },
    /**
     * Sets the config date to use for calculating finance charge
     * @param lot
     */
    setReceivedDate (lot) {
      if (lot?.shipment_summary_lot?.shipment_summary?.received_date) {
        this.config = ['receivedDate', lot.shipment_summary_lot.shipment_summary.received_date]
      }
    },
    applyBSDefaults (lot, bs, bt) {
      if (!bs.shipped_date && lot.ship_date) {
        bs.shipped_date = lot.ship_date // Applying default ship_date
        this.setBsProperty('shipped_date', lot.ship_date)
      }
      if (!bs.converters_processed && lot.piece_count) {
        bs.converters_processed = lot.piece_count // default pieces
        this.setBsProperty('converters_processed', lot.piece_count)
      }
      if (!bs.received_lbs_total && lot?.shipment_summary_lot?.adj_net_wet_received_lbs) {
        bs.received_lbs_total = lot.shipment_summary_lot.adj_net_wet_received_lbs // default received lbs
        this.setBsProperty('received_lbs_total', lot.shipment_summary_lot.adj_net_wet_received_lbs)
      }
      if (!bs.small_lot_fee && bt.handling_fee &&
        bs.received_lbs_total && bs.received_lbs_total < 2000) {
        // Apply the handling fee if not set and over 2k lbs
        bs.small_lot_fee = bt.handling_fee
        this.setBsProperty('small_lot_fee', bt.handling_fee)
      }
      return bs
    },
    setTozToAllocate (lot, bt) {
      if (!bt || !lot.shipment_summary_lot) return
      const ssl = lot.shipment_summary_lot
      switch (bt.ounce_payout_type) {
        case 'CO':
          this.tozToAllocate = [
            ssl.contained_pt_toz,
            ssl.contained_pd_toz,
            ssl.contained_rh_toz
          ]
          break
        case 'RE':
          this.tozToAllocate = [
            ssl.returnable_pt_toz,
            ssl.returnable_pd_toz,
            ssl.returnable_rh_toz
          ]
      }
    },
    setupBrokeredForm (lot, bs, bt) {
      // cleaning up the formatting for working within the form
      bs = this.applyBSDefaults(lot, bs, bt)
      bs.metals = bs.metals_details
      delete bs.metals_details
      delete bs.yard_id
      this.bs = bs
      this.$store.commit('setSnackbarInfo', 'Auto-fields are not re-calculated until data is changed.')
    },
    setAllocations (lot) {
      this.allocations = lot.allocations.filter(a => a.status === 'FIN')
    },
    setSmelter (lot) {
      this.smelter = lot.smelter
    },
    initializeBreadcrumbs (brokeredSettlement) {
      this.breadcrumbs = [
        ['Lot List', { name: 'lot-list' }],
        ['Lot #' + brokeredSettlement.lot_id, { name: 'lot', params: { id: brokeredSettlement.lot_id } }],
        ['Brokered Settlement #' + brokeredSettlement.id, {
          name: 'brokered-settlement',
          params: { id: brokeredSettlement.id }
        }]
      ]
    },
    /**
     * Loads the brokered Settlement and gets the related lot data
     * Passes lot and settlement to setup
     * @returns {Promise<void>}
     */
    async setBS () {
      this.loading = true
      const bs = await axios.get(`/api/brokered_settlements/${this.$route.params.id}/`)
      if (bs.data) {
        const lot = await axios.get(`/api/lots/${bs.data.lot_id}/`)
        this.setup(lot.data, bs.data)
        this.initializeBreadcrumbs(bs.data)
      } else {
        this.$store.commit('Unable to retrieve Brokered Settlement')
      }
      this.loading = false
    },
    /**
     * Setups the data objects under the hood, passing lot and settlement data to setter methods
     * @param lot
     * @param bs
     */
    setup (lot, bs) {
      const bt = bs?.yard?.brokerageterm || Object()
      this.setAllocations(lot)
      this.setYardChoices(lot, bs)
      this.setReceivedDate(lot)
      this.setupBrokeredForm(lot, bs, bt)
      this.setTozToAllocate(lot, bt)
      this.setSmelter(lot)
    },
    destroy () {
      if (!this.bs.id) {
        this.$emit('Missing Record key, unable to destroy Brokered Settlement')
        return
      }
      const settlementId = this.bs.id
      const lotId = this.bs.lot_id
      axios.delete(`/api/brokered_settlements/${settlementId}/`).then(res => {
        if (res.status === 204) {
          this.$store.commit('setSnackbarSuccess', `Brokered Settlement #${settlementId} Deleted`)
          this.$router.push({ name: 'lot', params: { id: lotId } })
        }
      })
    },
    finalize () {
      if (!this.bs.id) {
        this.$emit('Missing Record key, unable to Finalize Settlement')
        return
      }
      const settlementId = this.bs.id
      axios.patch(`/api/brokered_settlements/${settlementId}/`, { status: 'FI' }).then(res => {
        if (res.status === 200) {
          this.$store.commit('setSnackbarSuccess', `Brokered Settlement #${settlementId} Finalized`)
          this.$router.push({ name: 'lot', params: { id: this.bs.lot_id } })
        }
      })
    },
    /**
     * Create a TO SAVE object that has brokered settlement as bs and optionally metals and outright
     * metals and outright wil return false if there are none
     * @returns {{bs: any}}
     */
    getBSToSave () {
      // Shape the data to be saved here
      const bs = Object.assign({}, this.bsChanged, { yard_id: this.bsChanged.yard?.id })
      const toSave = {
        bs,
        metals: bs.metals || false,
        outright: bs.purchased_outright || false,
        additionalFees: bs.additional_fees || false
      }
      delete bs.yard
      delete bs.metals
      delete bs.purchased_outright
      delete bs.additional_fees
      return toSave
    },
    save () {
      const bsId = this.bs.id
      if (!bsId) {
        this.$emit('Missing Record key, unable to save Settlement')
        return
      }

      if (!this.$store.getters.can('edit-brokered-settlements')) {
        this.$emit('Unauthorized')
      }
      const { bs, metals, outright, additionalFees } = this.getBSToSave()

      let queries = []
      queries.push(axios.patch(`/api/brokered_settlements/${bsId}/`, bs))
      if (metals !== false) {
        queries.push(axios.patch(`/api/brokered_settlements/${bsId}/metals/`, metals))
      }
      if (outright !== false) {
        queries.push(axios.patch(`/api/brokered_settlements/${bsId}/outright/`, outright))
      }
      if (additionalFees !== false) {
        queries.push(axios.patch(`/api/brokered_settlements/${bsId}/additional_fees/`, additionalFees))
      }

      Promise.all(queries).then(() => {
        this.$store.commit('setSnackbarSuccess', `Brokered Settlement Saved!`)
        this.unsavedChanges = false
        this.bsChanged = Object()
      })
    }
  },
  mounted () {
    this.setBS()
  }
}
</script>
