<template>
  <v-card>
    <v-card-subtitle>
      <strong>Inventory Overview</strong>
    </v-card-subtitle>
    <div v-if="inventory">
      <div class="text-center pb-4 subtitle-2 text-center">
        Inventory
        <br>
        <router-link :to="{ name: 'inventory', params: { id: inventory.id }}">
          {{ inventory.name }}
        </router-link>
      </div>
      <v-expansion-panels v-if="inventory.inventoryline_set.length > 0">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-simple-table v-if="!open">
                <thead>
                  <tr>
                    <th>
                      Lines
                    </th>
                    <th>
                      Gross
                    </th>
                    <th>
                      Tare
                    </th>
                    <th>
                      Net
                    </th>
                    <th>
                      Pieces
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      {{ inventory.inventoryline_set.length }}
                    </th>
                    <th>
                      {{ inventory.line_summary.gross | formatNumber }} lbs
                    </th>
                    <th>
                      {{ inventory.line_summary.tare | formatNumber }} lbs
                    </th>
                    <th>
                      {{ inventory.line_summary.net | formatNumber }} lbs
                    </th>
                    <th>
                      {{ inventory.line_summary.pieces | formatNumber }}
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <v-simple-table>
              <thead>
                <tr>
                  <th>
                    Count
                  </th>
                  <th>
                    Gross
                  </th>
                  <th>
                    Tare
                  </th>
                  <th>
                    Net
                  </th>
                  <th>
                    Pieces
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in invLines"
                  :key="item.id"
                >
                  <td>
                    {{ item.count }}
                  </td>
                  <td>
                    {{ item.gross | formatNumber }} lbs
                  </td>
                  <td>
                    {{ item.tare | formatNumber }} lbs
                  </td>
                  <td>
                    {{ item.net | formatNumber }} lbs
                  </td>
                  <td>
                    {{ item.pieces | formatNumber }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <b>
                      Totals
                    </b>
                  </td>
                  <td>
                    <b>
                      {{ inventory.line_summary.gross | formatNumber }} lbs
                    </b>
                  </td>
                  <td>
                    <b>
                      {{ inventory.line_summary.tare | formatNumber }} lbs
                    </b>
                  </td>
                  <td>
                    <b>
                      {{ inventory.line_summary.net | formatNumber }} lbs
                    </b>
                  </td>
                  <td>
                    <b>
                      {{ inventory.line_summary.pieces | formatNumber }}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div
        v-else
        class="text-center pb-4 font-weight-thin"
      >
        No Inventory Lines have been added
      </div>
    </div>
    <card-list-item
      title="No Inventory Found"
      v-else
    />
  </v-card>
</template>

<script>
export default {
  name: 'InventoryOverview',
  props: {
    inventory: { type: Object, required: false, default: null }
  },
  computed: {
    grossTotal () {
      return this.inventory.inventoryline_set.reduce((accu, g) => accu + parseFloat(g.gross), 0)
    },
    tareTotal () {
      return this.inventory.inventoryline_set.reduce((accu, t) => accu + parseFloat(t.tare), 0)
    },
    netTotal () {
      return this.inventory.inventoryline_set.reduce((accu, n) => accu + parseFloat(n.net), 0)
    },
    invLines () {
      return this.inventory.inventoryline_set.map((line, index) => {
        line.count = index + 1
        return line
      })
    }
  }
}
</script>
