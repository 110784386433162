<template>
  <div>
    <v-dialog
      v-model="dialog"
    >
      <ValidationObserver
        v-slot="{ invalid }"
      >
        <v-form
          @submit.prevent="updateReport"
        >
          <v-card>
            <v-toolbar
              flat
              dense
            >
              Edit Summary Values {{ shipmentSummary.id }}
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  icon
                  @click="dialog = false"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <date-input
                label="Smelter Payment Date"
                v-model="smelterPaymentDate"
              />
              <ValidationProvider
                rules="required|decimal:2"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="financeCharge"
                  type="number"
                  label="Smelter Finance Charge"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-currency-usd"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="required|decimal:2"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="locFinanceCharge"
                  type="number"
                  label="Line of Credit Finance Charge"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-currency-usd"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="required|decimal:2"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="freightCharge"
                  type="number"
                  label="Freight Charge"
                  :error-messages="errors"
                  prepend-inner-icon="mdi-currency-usd"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :disabled="invalid"
                color="primary"
                type="submit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
    <slot :toggle="() => { dialog = !dialog }" />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LotFinanceEditDialog',
  props: {
    shipmentSummary: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    smelterPaymentDate: '',
    financeCharge: 0,
    locFinanceCharge: 0,
    freightCharge: 0,
    financeComplete: false
  }),
  watch: {
    dialog (opened) {
      if (opened) {
        if (this.shipmentSummary.smelter_payment_date) {
          this.smelterPaymentDate = new Date(this.shipmentSummary.smelter_payment_date).toISOString().slice(0, 10)
        }
        this.financeCharge = this.shipmentSummary.finance_charge
        this.freightCharge = this.shipmentSummary.freight_charge
        this.locFinanceCharge = this.shipmentSummary.loc_finance_charge
      }
    }
  },
  methods: {
    async updateReport () {
      const payload = {
        finance_charge: this.financeCharge,
        freight_charge: this.freightCharge,
        loc_finance_charge: this.locFinanceCharge
      }
      if (this.smelterPaymentDate) payload.smelter_payment_date = this.smelterPaymentDate
      axios.patch(`api/shipment_summaries/${this.shipmentSummary.id}/`, payload).then(() => {
        this.$store.commit('setSnackbarSuccess', 'Shipment Summary Updated')
        this.$emit('updated')
        this.dialog = false
      })
    }
  }
}
</script>
