<template>
  <v-container>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <h3> Weights </h3>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <v-select
            v-model="materialType"
            label="Material Type"
            :items="converterTypes"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="grossLbs"
              label="Gross Received LBS"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="tareLbs"
              label="Tare LBS"
              type="number"
              persistent-hint
              hint="Scrap from Decanning"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="netWetLbs"
              label="Net Wet Received LBS"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="scrapLbs"
              label="Scrap LBS"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="required|max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="adjNetLbs"
              type="number"
              label="Adjusted Net Wet Received LBS *"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="required|max_value:100|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="percentMoisture"
              label="Moisture Percentage *"
              :error-messages="errors"
              type="number"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="moistureMeasurement"
              label="Moisture Measurement *"
              :items="measurementTypes"
              :error-messages="errors"
              item-text="text"
              item-value="value"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="netDryLbs"
              label="Net Dry LBS"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:9999999999999|decimal:3"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="sampleWeight"
              label="Sample Weight LBS"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3> Contained Assays </h3>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="cPtPpm"
              label="PT PPM"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="cPdPpm"
              label="PD PPM"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="min_value:0|max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="cRhPpm"
              label="RH PPM"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="min_value:0|max_value:100|decimal:8"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="percentCarbon"
              label="Carbon Percent"
              :error-messages="errors"
              append-icon="mdi-percent-outline"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3> Contained Metals </h3>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="cPtToz"
              @keyup="rPtToz = calcMetal('contained', cPtToz, smelter.pt_return_rate)"
              label="PT Troy Ounce"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="cPdToz"
              label="PD Troy Ounce"
              type="number"
              :error-messages="errors"
              @keyup="rPdToz = calcMetal('contained', cPdToz, smelter.pd_return_rate)"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value: 99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="cRhToz"
              label="RH Troy Ounce"
              type="number"
              :error-messages="errors"
              @keyup="rRhToz = calcMetal('contained', cRhToz, smelter.rh_return_rate)"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3>
        Returnable Ounces
      </h3>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="rPtToz"
              label="PT Troy Ounce"
              :error-messages="errors"
              @keyup="cPtToz = calcMetal('returnable', rPtToz, smelter.pt_return_rate)"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="rPdToz"
              label="PD Troy Ounce"
              type="number"
              :error-messages="errors"
              @keyup="cPdToz = calcMetal('returnable', rPdToz, smelter.pd_return_rate)"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <ValidationProvider
            rules="max_value:99999999|decimal:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="rRhToz"
              label="RH Troy Ounce"
              type="number"
              :error-messages="errors"
              @keyup="cRhToz = calcMetal('returnable', rRhToz, smelter.rh_return_rate)"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3">
          <v-menu
            v-model="valueDateDialog"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="formatDate(valueDate)"
                label="Value Date"
                readonly
                v-on="on"
                append-icon="mdi-calendar-month"
              />
            </template>
            <v-date-picker
              v-model="valueDate"
              @input="valueDateDialog = false"
              no-title
              scrollable
            />
          </v-menu>
        </v-col>
      </v-row>
      <h3>
        Summary of Charges
      </h3>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="chargePerLb"
              label="Treatment $/LBS"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <!-- net wet * chargePerLb -->
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="treatmentCharge"
              label="Treatment Charge"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="smallLot"
              label="Handling Charge"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <!-- treatment charge + smallLot -->
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="totalTreatmentCharge"
              label="Total Treatment Charge"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="totalRefineCharge"
              label="Total Refining Charge"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <!-- totalrefi charge + total treatment -->
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="totalProcessCharge"
              label="Total Processing Charge"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="assayCharge"
              label="Assay Charge"
              hint="i.e. Lot or Lab fee"
              persistent-hint
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <ValidationProvider
            rules="max_value:99999999|decimals:2"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="freightCharge"
              label="Freight Charge"
              prepend-icon="mdi-currency-usd"
              type="number"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <div class="caption">
        <p>* Indicates a required field</p>
      </div>
      <base-button
        @clicked="addSsl"
        :disabled="invalid || loading"
        :loading="loading"
        auto-center
        label="Add Lot"
      />
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WriteSummaryLot',
  data: () => ({
    internalLotId: null,
    materialType: null,
    grossLbs: null,
    tareLbs: null,
    netWetLbs: null,
    scrapLbs: null,
    adjNetLbs: null,
    percentMoisture: null,
    moistureMeasurement: null,
    sampleWeight: null,
    netDryLbs: null,
    cPtPpm: null,
    cPdPpm: null,
    cRhPpm: null,
    percentCarbon: null,
    cPtToz: null,
    cPdToz: null,
    cRhToz: null,
    rPtToz: null,
    rPdToz: null,
    rRhToz: null,
    valueDate: null,
    valueDateDialog: false,
    chargePerLb: null,
    treatmentCharge: null,
    smallLot: null,
    assayCharge: null,
    freightCharge: null,
    totalTreatmentCharge: null,
    totalRefineCharge: null,
    totalProcessCharge: null,
    loading: false,
    measurementTypes: [
      { text: 'Pounds', value: 'lbs' },
      { text: 'Percent', value: 'per' }
    ]
  }),
  props: {
    lot: { type: Object, required: true }
  },
  methods: {
    // Calculates either contained or returnable based off given values
    calcMetal (type, currentVal, returnRate) {
      let v = 0
      if (type === 'contained') v = currentVal * returnRate
      else if (type === 'returnable') v = currentVal / returnRate
      return v.toFixed(3)
    },
    ...mapActions({
      createSLot: 'shipmentSummaryStore/createSSL',
      deleteShipmentSummary: 'shipmentSummaryStore/deleteShipmentSummary'
    }),
    deleteSS () {
      this.deleteShipmentSummary(this.shipmentSummary)
    },
    getPercentMoisture () {
      if (this.adjNetLbs === '0') {
        return 0
      }
      if (this.moistureMeasurement === 'lbs' && this.percentMoisture !== '0') {
        return (this.percentMoisture / +this.adjNetLbs * 100).toFixed(3)
      }
      return this.percentMoisture
    },
    // Creates shipment summary lot
    addSsl () {
      this.loading = true
      let body = {
        shipment_summary_id: this.shipmentSummary.id,
        lot_id: this.lotId,
        lot: this.lot,
        internal_lot_id: this.internalLotId,
        material_type: this.materialType ? this.materialType : 'OM',
        gross_lbs: this.grossLbs,
        tare_lbs: this.tareLbs,
        net_wet_lbs: this.netWetLbs,
        scrap_lbs: this.scrapLbs,
        adj_net_wet_received_lbs: this.adjNetLbs,
        percent_moisture: this.getPercentMoisture(),
        sample_weight: this.sampleWeight,
        net_dry_lbs: this.netDryLbs,
        contained_pt_ppm: this.cPtPpm,
        contained_pd_ppm: this.cPdPpm,
        contained_rh_ppm: this.cRhPpm,
        carbon_percent: this.percentCarbon,
        contained_pt_toz: this.cPtToz,
        contained_pd_toz: this.cPdToz,
        contained_rh_toz: this.cRhToz,
        returnable_pt_toz: this.rPtToz,
        returnable_pd_toz: this.rPdToz,
        returnable_rh_toz: this.rRhToz,
        value_date: this.valueDate,
        treatment_charge_per_lb: this.chargePerLb,
        treatment_charge_total: this.treatmentCharge,
        small_lot_charge: this.smallLot,
        total_treatment_charge: this.totalTreatmentCharge,
        total_refining_charge: this.totalRefineCharge,
        total_processing_charge: this.totalProcessCharge,
        assay_charge: this.assayCharge,
        freight_charge: this.freightCharge
      }
      this.createSLot(body).then(() => {
        this.$emit('incStep')
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      converterTypes: 'lotStore/lotMaterialType'
    }),
    ...mapGetters({
      shipmentSummary: 'shipmentSummaryStore/shipmentSummary',
      smelter: 'shipmentSummaryStore/getSmelter'
    })
  },
  mounted () {
    this.internalLotId = this.lot.name
    this.lotId = this.lot.id
    this.lotUrl = this.lot.url
  }
}
</script>
