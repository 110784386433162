<template>
  <tbody>
    <tr>
      <td>Allocated Values</td>
      <td class="pt-color text-right">
        <fx-tag
          :fx="allocationTotals.total_value_pt_fx"
        />
      </td>
      <td class="pd-color text-right">
        <fx-tag
          :fx="allocationTotals.total_value_pd_fx"
        />
      </td>
      <td class="rh-color text-right">
        <fx-tag
          :fx="allocationTotals.total_value_rh_fx"
        />
      </td>
    </tr>
    <tr>
      <td>Average Values</td>
      <td class="pt-color text-right">
        <fx-tag
          :fx="allocationTotals.average_value_pt_fx"
        />
      </td>
      <td class="pd-color text-right">
        <fx-tag
          :fx="allocationTotals.average_value_pd_fx"
        />
      </td>
      <td class="rh-color text-right">
        <fx-tag
          :fx="allocationTotals.average_value_rh_fx"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'AllocAveValues',
  props: {
    allocationTotals: { type: Object, required: true }
  }
}
</script>
