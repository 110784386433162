<template>
  <div>
    <h2>
      Properties
    </h2>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max:400"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Lot Name"
            :value="bs.lot_name"
            @input="$emit('setProp', ['lot_name', $event])"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            label="Yard"
            :value="bs.yard"
            @input="$emit('setProp', ['yard', $event])"
            :items="yardChoices"
            :item-text="x => `${x.id} - ${x.name} ${x.address || ''}, ${x.city || ''} ${x.state || ''}`"
            return-object
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required"
          v-slot="{ errors }"
        >
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="bs.shipped_date"
                @input="$emit('setProp', ['shipped_date', $event])"
                :label="shippedLabel"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                :error-messages="errors"
              />
            </template>
            <v-date-picker
              :value="bs.shipped_date"
              @input="$emit('setProp', ['shipped_date', $event])"
            />
          </v-menu>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:2147483647|min_value:0|decimal:0"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Converters Processed"
            type="number"
            :value="bs.converters_processed"
            @input="$emit('setProp', ['converters_processed', $event])"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:999999999|min_value:0|decimal:3"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Mixed Catalyst Weight/Received Lbs"
            type="number"
            :value="bs.received_lbs_total"
            @input="$emit('setProp', ['received_lbs_total', $event])"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BSPropertiesForm',
  props: {
    bs: {
      type: Object,
      required: true
    },
    yardChoices: {
      type: Array,
      required: true
    }
  },
  computed: {
    shippedLabel () {
      let s = 'Shipped Date'
      if (this.bs.shipped_date) {
        const d = moment(this.bs.shipped_date)
          .add(115, 'days')
          .format('YYYY-MM-DD')
        s += ` (Return ${d})`
      }
      return s
    }
  }
}
</script>
