var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" Fees ")]),_c('v-card',{staticClass:"my-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{'dense-filled': !_vm.bs.treatment_fee_manual},attrs:{"readonly":!_vm.bs.treatment_fee_manual,"dense":"","type":"number","value":_vm.bs.treatment_fee,"label":"Processing Fee","prepend-icon":"mdi-currency-usd","hint":"weight * processing per lb","append-icon":_vm.bs.treatment_fee_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline',"error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['treatment_fee', $event])},"click:append":function($event){return _vm.$emit('setProp', ['treatment_fee_manual', !_vm.bs.treatment_fee_manual])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:{'dense-filled': !_vm.bs.freight_charge_manual},attrs:{"readonly":!_vm.bs.freight_charge_manual,"dense":"","type":"number","value":_vm.bs.small_lot_fee,"label":"Handling Fee","prepend-icon":"mdi-currency-usd","hint":"Populated when weight < 2000lbs and Freight Responsibility is Vendor","append-icon":_vm.bs.freight_charge_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline',"error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['small_lot_fee', $event])},"click:append":function($event){return _vm.$emit('setProp', ['freight_charge_manual', !_vm.bs.freight_charge_manual])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","value":_vm.bs.freight_charge,"label":"Freight Charge","prepend-icon":"mdi-currency-usd","error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['freight_charge', $event])}}})]}}])})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.sumOtherFees,"label":"Net Other Fees","prepend-icon":"mdi-currency-usd","dense":"","readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:99999999|min_value:0|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"dense-filled",attrs:{"dense":"","readonly":"","type":"number","value":_vm.bs.net_value,"label":"Net Value","prepend-icon":"mdi-currency-usd","hint":"(readonly) gross - (processing fee + handling fee)","error-messages":errors}})]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.addFeeDialog = !_vm.addFeeDialog}}},[_vm._v(" Add Fee ")])],1)],1),_c('edit-dialog',{attrs:{"headline-text":"Additional Fees","show-decline":false,"accept-text":"done"},model:{value:(_vm.addFeeDialog),callback:function ($$v) {_vm.addFeeDialog=$$v},expression:"addFeeDialog"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bs.additional_fees},scopedSlots:_vm._u([{key:"item.other_fee_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.other_fee_value)+" ")]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.removeFee(item)}}},[_vm._v(" remove ")])]}}])}),_c('br'),_c('h3',[_vm._v(" Add Fee ")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"text","label":"Fee Description"},model:{value:(_vm.field.other_fee_name),callback:function ($$v) {_vm.$set(_vm.field, "other_fee_name", $$v)},expression:"field.other_fee_name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","min":"0","label":"Fee Amount","prepend-icon":"mdi-currency-usd"},model:{value:(_vm.field.other_fee_value),callback:function ($$v) {_vm.$set(_vm.field, "other_fee_value", $$v)},expression:"field.other_fee_value"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"disabled":_vm.field.other_fee_name === '' || _vm.field.other_fee_value === 0,"color":"primary","small":""},on:{"click":_vm.addFee}},[_vm._v(" Add fee ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }