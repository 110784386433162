<template>
  <unauthorized
    class="pb-8"
    :authorized="$store.getters.can('create-lots')"
    message="Unauthorized to create Lots"
  >
    <app-loading-indicator v-if="loading" />
    <ValidationObserver
      v-else
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <base-title title="Create Lot" />
      <v-sheet class="pa-4">
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="required|max:55"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Name*"
                  v-model="name"
                  hint="exp. 12345 (HOUSE)"
                  counter="55"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="max:20"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Suffix"
                  v-model="suffix"
                  hint="rarely used exp. 1234"
                  counter="20"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-radio-group
                  label="Entity*"
                  v-model="entityUrl"
                  :error-messages="errors"
                >
                  <v-radio
                    v-for="et in entities"
                    :key="et.name"
                    :label="et.name"
                    :value="et.url"
                  />
                </v-radio-group>
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-radio-group
                  label="Designation*"
                  v-model="lotType"
                  :error-messages="errors"
                >
                  <v-radio
                    v-for="lt in lotTypes"
                    :key="lt.value"
                    :label="lt.text + (lt['expects_settlement'] ? '*' : '')"
                    :value="lt.value"
                  />
                  <span class="text-caption">*Yard Required</span>
                </v-radio-group>
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-radio-group
                  label="Division*"
                  v-model="division"
                  :error-messages="errors"
                >
                  <v-radio
                    v-for="division in divisions"
                    :key="division.id"
                    :label="division.name"
                    :value="division.id"
                  />
                </v-radio-group>
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-radio-group
                label="Material Type"
                v-model="materialType"
              >
                <v-radio
                  label="(None)"
                  value="none"
                />
                <v-radio
                  v-for="lmt in lotMaterialTypes"
                  :key="lmt.text"
                  :label="lmt.text"
                  :value="lmt.value"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                v-slot="{ errors }"
              >
                <search-autocomplete
                  v-model="accountManager"
                  label="Account Manager"
                  item-text="username"
                  item-value="url"
                  api-path="/api/users/"
                  :params="{ fields: 'username,url', is_active: true }"
                  :error-messages="errors"
                  :format-entry="x => Object.assign({ friendlyName: x.username }, x)"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <paginated-autocomplete
                  label="PPM Calculator*"
                  v-model="ppmCalc"
                  item-text="name"
                  item-value="id"
                  base-url="/api/ppm_calculations/"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <paginated-autocomplete
                label="Smelter"
                v-model="smelter"
                item-text="name"
                item-value="id"
                base-url="/api/smelters/"
                :search-params="{ is_active: true }"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                :rules="yardRequired ? 'required' : null"
                v-slot="{ errors }"
              >
                <paginated-autocomplete
                  v-model="chipYards"
                  :label="yardRequired ? 'Select a Yard*' : 'Select a Yard'"
                  :item-text="x => `${x.id} - ${x.name} | ${x.full_address || ''} | ${x.status}`"
                  base-url="/api/yards/"
                  :params="{ status: 'GS' }"
                  multiple
                />
                <div
                  class="red--text"
                  v-for="(err, index) in errors"
                  :key="index"
                >
                  <small>
                    {{ err }}
                  </small>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <paginated-autocomplete
                v-model="designatedHedges"
                label="Designated Hedge(s)"
                item-text="id"
                base-url="/api/hedges/"
                multiple
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <rules-date
                v-model="processingDate"
                validation-rules=""
                label="Processing Date"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                name="notes"
                label="Notes"
                v-model="notes"
                counter
                :rows="4"
                auto-grow
              />
            </v-col>
          </v-row>
          <text-fab
            :disabled="invalid || creatingLot"
            bottom
            right
            fixed
            icon-text="mdi-invoice-text-plus"
            color="primary"
            @click="create"
          >
            Create
          </text-fab>
        </v-form>
      </v-sheet>
    </ValidationObserver>
  </unauthorized>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import { fetchEntities } from '@/requests/entities'
import lot from '@/views/Lots/Lot.vue'
import { fetchAllDivisions } from '@/requests/divisions'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'

export default {
  name: 'CreateLot',
  components: { SearchAutocomplete, RulesDate },
  computed: {
    lot () {
      return lot
    },
    ...mapGetters({
      lotTypes: 'lotStore/lotTypesActive'
    }),
    yardRequired () {
      for (const lt of this.lotTypes) {
        if (this.lotType === lt.value && lt['expects_settlement']) {
          return true
        }
      }
      return false
    },
    lotMaterialTypes () {
      return this.$store.getters['getLotTypes']
    }
  },
  data: () => ({
    accountManager: null,
    loading: false,
    name: null,
    entityUrl: null,
    entities: [],
    suffix: null,
    smelter: null,
    division: null,
    notes: null,
    ppmCalc: null,
    creatingLot: false,
    lotType: null,
    materialType: 'none',
    designatedHedges: null,
    chipYards: [],
    timeout: null,
    selectableYards: [],
    search: null,
    processingDate: null,
    divisions: []
  }),
  watch: {
    search (val) {
      if (!val) {
        this.selectableYards = []
        return
      }
      this.fetchResults(val)
    }
  },
  methods: {
    formatYardResult (yard) {
      return Object.assign({
        friendlyName: `${yard.name} (${yard.city || '--'}, ${yard.state || '--'})`
      }, yard)
    },
    ...mapActions({
      createLot: 'lotStore/createLot',
      updateLot: 'lotStore/updateLot'
    }),
    /**
     * Create a new lot given the user input
     */
    async create () {
      this.creatingLot = true
      let payload = {
        name: this.name,
        designation: this.lotType,
        division_id: this.division,
        ppm_calculator_id: this.ppmCalc,
        entity: this.entityUrl
      }
      if (this.suffix) payload.suffix = this.suffix
      if (this.smelter) payload.smelter_id = this.smelter
      if (this.notes) payload.notes = this.notes
      if (this.materialType !== 'none') payload.lot_type = this.materialType
      if (this.processingDate) payload.processing_date = this.processingDate
      if (this.accountManager) payload.account_manager = this.accountManager
      const id = await this.createLot({ payload })
      if (this.chipYards || this.designatedHedges) {
        let patchPayload = { id }
        if (this.chipYards) patchPayload.yards = this.chipYards.map(h => h.id)
        if (this.designatedHedges) {
          patchPayload.designated_hedges = this.designatedHedges.map(h => h.id)
        }
        await this.updateLot(patchPayload)
      }
      this.creatingLot = false
      this.$router.push({ name: 'lot', params: { id } })
    },
    fetchResults (val) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const params = { status: 'GS', search: val, fields: 'id,name,full_address' }
        const response = await axios.get('/api/yards/', { params })
        this.selectableYards = response.data.results
      }, 300)
    }
  },
  async mounted () {
    this.loading = true
    try {
      const divResponse = await fetchAllDivisions({ is_inactive: false, ordering: 'name' })
      this.divisions = divResponse.data
      const entResponse = await fetchEntities()
      if (entResponse.data) this.entities = entResponse.data
    } catch (err) {
      this.$store.commit('setSnackbarError', err.message)
    }
    this.loading = false
  }
}
</script>
