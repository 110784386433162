var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"persistent":"","width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._g({attrs:{"bottom":"","right":"","fab":"","absolute":"","small":"","color":"primary"}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]}}]),model:{value:(_vm.hedgeAllocDialog),callback:function ($$v) {_vm.hedgeAllocDialog=$$v},expression:"hedgeAllocDialog"}},[_c('v-card',{staticClass:"py-2"},[_c('v-container',{staticStyle:{"max-width":"40rem"}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",staticClass:"mx-2",on:{"submit":function($event){$event.preventDefault();return _vm.addAllocation($event)}}},[_c('v-row',[_c('v-card-title',[_vm._v(" "+_vm._s(("Add Allocation To Lot #" + _vm.lot))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.hedgeAllocDialog = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-row',_vm._l((_vm.metals),function(metal,i){return _c('v-col',{key:i,staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.capitalize(metal.name))+": ")])}),1),_c('v-row',_vm._l((_vm.metals),function(metal,i){return _c('v-col',{key:i,staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm._f("appendToz")(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm.unallocatedMetals[metal.abbr]))))+" ")])}),1),_c('v-row',[_c('v-card-title',[_vm._v(" "+_vm._s("From Hedge:")+" ")]),(_vm.loading)?_c('v-text-field',{attrs:{"label":"Select a Hedge","disabled":"","loading":""}}):_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ml-2",attrs:{"items":_vm.hedges,"label":"Select a Hedge","return-object":"","item-text":"id","error-messages":errors},model:{value:(_vm.hedge),callback:function ($$v) {_vm.hedge=$$v},expression:"hedge"}})]}}],null,true)})],1),_c('v-row',[(_vm.hedge)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-left"},[_vm._v(" Allocation ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Available ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Rate/oz ")])])]),_c('tbody',_vm._l((_vm.metals),function(metal,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? _vm.capitalize(metal.abbr) : _vm.capitalize(metal.name))+" ")]),_c('td',[_c('ValidationProvider',{attrs:{"rules":("required|max_value:" + (Math.min(
                          _vm.hedge[((metal.abbr) + "_available_toz")],
                          _vm.unallocatedMetals[metal.abbr]
                        )))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.formatErrors(errors, metal)},model:{value:(metal.value),callback:function ($$v) {_vm.$set(metal, "value", $$v)},expression:"metal.value"}})]}}],null,true)})],1),_c('td',[_vm._v(_vm._s(_vm._f("appendToz")(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm.hedge[((metal.abbr) + "_available_toz")]))))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("dollarSign")(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm.hedge[((metal.abbr) + "_rate_per_toz")]))))+" ")])])}),0)]},proxy:true}],null,true)}):_vm._e()],1),(_vm.hedge)?_c('div',[_c('v-row',[_c('v-card-title',[_vm._v(" Results ")])],1),_c('v-row',[_c('v-card-title',{staticClass:"ml-2 pt-0"},[_vm._v(" "+_vm._s(("Lot #" + _vm.lot))+" ")])],1),_c('v-row',{staticClass:"ml-2"},_vm._l((_vm.metals),function(metal,i){return _c('v-col',{key:i,staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.capitalize(metal.name))+": ")])}),1),_c('v-row',{staticClass:"ml-2"},_vm._l((_vm.metals),function(metal,i){return _c('v-col',{key:i,staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm._f("appendToz")(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm.calculateResult(metal, _vm.unallocatedMetals[metal.abbr])))))+" ")])}),1),_c('v-row',[_c('v-card-title',{staticClass:"ml-2 pt-0"},[_vm._v(" Unallocated: ")])],1),_c('v-row',{staticClass:"ml-2"},_vm._l((_vm.metals),function(metal,i){return _c('v-col',{key:i,staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.capitalize(metal.name))+": ")])}),1),_c('v-row',{staticClass:"ml-2"},_vm._l((_vm.metals),function(metal,i){return _c('v-col',{key:i,staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm._f("appendToz")(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm.calculateResult(metal, _vm.hedge[((metal.abbr) + "_available_toz")])))))+" ")])}),1)],1):_vm._e(),_c('base-button',{attrs:{"auto-center":"","disabled":invalid,"type":"submit","label":"Add Allocation"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }