<template>
  <unauthorized
    :authorized="$store.getters.can('view-partial-lots')"
    message="You are not authorized to view Lots"
  >
    <app-loading-indicator
      v-if="loading"
    />
    <div v-else-if="lot.id">
      <url-breadcrumbs
        v-if="lot"
        :items="[
          ['lot list', { name: 'lot-list' }],
          [`Lot #${lot.id}`, { name: 'lot' }]
        ]"
      />
      <base-title>
        Lot {{ lot.name }}
        <template v-slot:subtitle>
          <div v-if="lot.parent_data">
            - <router-link :to="{ name: 'lot', params: { id: lot.parent_data.id } }">
              {{ lot.parent_data.name }}
            </router-link> <i>primary</i>
          </div>
          <div
            v-for="(child, index) in lot.children_data"
            :key="index"
          >
            - <router-link :to="{ name: 'lot', params: { id: child.id } }">
              {{ child.name }}
            </router-link> <i>sub</i>
            <br>
          </div>
          {{ lot.code }}<span v-if="lot.suffix"> / </span>{{ lot.suffix }}
        </template>
      </base-title>
      <user-upload-dialog
        class="my-4"
        :file-links="fileLinks"
      />
      <lot-toolbar />
      <status-stepper
        v-if="stepperStatuses.length"
        :key="lot.status"
        :status="lot.status"
        :statuses="stepperStatuses"
      />
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <lot-overview
            :lot="lot"
            :allocations="allocations"
            :market-rate="marketRate"
            @updateLot="updateLot"
          />
          <designated-yards
            v-if="$store.getters.can('view-lots')"
            class="my-8"
            @fetchLot="fetchLot($route.params.id)"
            :yards="lot.yards"
            :url="lot.url"
          />
          <lot-settlement-overview
            v-if="$store.getters.can('view-lots')"
            class="my-8"
            :settlement="lot.shipment_summary_lot"
            :lot="lot"
            @fetchLot="fetchLot($route.params.id)"
          />
          <brokered-overview
            v-if="lot.shipment_summary_lot && expectsSettlement(lot.designation) && $store.getters.can('view-lots')"
            class="mt-8"
            :yards="lot.yards"
            :lot="lot"
            @created="fetchLot($route.params.id)"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          flex-column
        >
          <designated-hedges
            v-if="$store.getters.can('view-lots')"
            @fetchLot="fetchLot($route.params.id)"
            :hedges="lot.designated_hedges"
            :url="lot.url"
          />
          <hedging-overview
            v-if="$store.getters.can('view-lots')"
            class="my-8"
            :lot="lot"
            :final-toz="finalToz"
            :allocations="allocations"
            :allocation-totals="allocationTotals"
            :hedges="[]"
            :updating-lot="updatingLot"
            @updateLot="updateLot"
            @addedAllocation="fetchAllocations({ lot_id: $route.params.id, page_size: 0 })"
          />
          <warehouse-manager-hedging-overview
            v-if="$store.getters.can('view-lot-est-return-oz') && !$store.getters.can('view-lots')"
            :lot="lot"
          />
          <inventory-overview
            class="mt-8"
            :inventory="lot.inventory"
          />
        </v-col>
        <v-col
          v-if="lot && $store.getters.can('view-assay-result')"
        >
          <assay-results
            :lot="lot"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <intake-check-in-section
            :lot="lot"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <intake-check-in-section-loosely-related
            :lot="lot"
          />
        </v-col>
      </v-row>
      <v-row v-if="$store.getters.can('view-transit')">
        <v-col
          cols="12"
        >
          <transit-lot-table :lot-id="lot.id" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <lot-pieces-by-category :lot-id="lot.id" />
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        bottom
        right
        fixed
        fab
        @click="editDialog = !editDialog"
      >
        <v-icon>
          mdi-pencil-outline
        </v-icon>
      </v-btn>
      <edit-lot
        v-if="lot && $store.getters.can('create-lots')"
        v-model="editDialog"
        :lot="lot"
      />
    </div>
  </unauthorized>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HedgingOverview from './components/Hedging/HedgingOverview'
import WarehouseManagerHedgingOverview from './components/Hedging/WarehouseManagerHedgingOverview'
import LotOverview from './components/LotOverview'
import LotSettlementOverview from './components/Settlement/LotSettlementOverview'
import InventoryOverview from './components/InventoryOverview'
import StatusStepper from '@/base/components/StatusStepper'
import BrokeredOverview from './components/Brokered/BrokeredOverview'
import EditLot from './components/EditLot'
import IntakeCheckInSection from './components/IntakesCheckIns/IntakeCheckInSection'
import DesignatedHedges from './components/DesignatedHedges'
import DesignatedYards from './components/DesignatedYards'
import AssayResults from '@/views/Lots/components/AssayResult/AssayResults.vue'
import LotToolbar from '@/views/Lots/components/LotToolbar.vue'
import LotPiecesByCategory from '@/views/Lots/components/IntakesCheckIns/LotPieceByCategory.vue'
import UserUploadDialog from '@/base/components/UserUploadDialog.vue'
import fileLinks from '@/helpers/fileLinks'
import { expectsSettlement } from '@/helpers/utils'
import IntakeCheckInSectionLooselyRelated
  from '@/views/Lots/components/IntakesCheckIns/IntakeCheckInSectionLooselyRelated.vue'
import TransitLotTable from '@/views/Lots/components/TransitLotTable.vue'

export default {
  name: 'Lot',
  components: {
    TransitLotTable,
    IntakeCheckInSectionLooselyRelated,
    UserUploadDialog,
    LotPiecesByCategory,
    LotToolbar,
    AssayResults,
    IntakeCheckInSection,
    EditLot,
    HedgingOverview,
    WarehouseManagerHedgingOverview,
    LotOverview,
    LotSettlementOverview,
    InventoryOverview,
    StatusStepper,
    BrokeredOverview,
    DesignatedHedges,
    DesignatedYards
  },
  data: () => ({
    editDialog: false,
    loading: true,
    updatingLot: false,
    finalToz: {},
    terms: null,
    showProfitEstimationDialog: false
  }),
  methods: {
    ...mapActions({
      fetchLot: 'lotStore/fetchLot',
      fetchAllocations: 'allocationStore/fetchAllocations',
      fetchAllocationTotals: 'allocationStore/fetchAllocationTotals',
      updatePurchaseLot: 'purchaseStore/updatePurchaseLot',
      fetchMarketRate: 'marketRate/fetch'
    }),
    expectsSettlement,
    /**
     * Add a purchase to the current lot
     * @param {Object} purchase
     */
    // addPurchase (purchase) {
    //   const params = { purchases: [purchase], id: this.lot.id }
    //   this.updatePurchaseLot(params).then(() => {
    //     this.fetchLot(this.lot.id)
    //   })
    // },
    async updateLot (payload) {
      payload.id = this.$route.params.id
      await this.$store.dispatch('lotStore/updateLot', payload)
      await this.$store.dispatch('lotStore/fetchLot', this.$route.params.id)
    },
    /**
     * If a contained oz value is present use it rather than the estimated value
     * Used in the 'allocations' component in hedging overview
     * @return {{pt: methods.cPtToz, pd: methods.cPdToz, rh: methods.cRhToz}}
     */
    calcFinalOzMetal () {
      let pt = this.lot.estimated_pt
      let pd = this.lot.estimated_pd
      let rh = this.lot.estimated_rh
      if (this.lot.shipment_summary_lot) {
        pt = this.lot.shipment_summary_lot.contained_pt_toz
        pd = this.lot.shipment_summary_lot.contained_pd_toz
        rh = this.lot.shipment_summary_lot.contained_rh_toz
      }
      return { pt, pd, rh }
    },
    initialize () {
      let r = [
        this.fetchLot(this.$route.params.id),
        this.fetchMarketRate()
      ]
      if (this.$store.getters.can('view-lot-allocations')) {
        // only fetch allocations if the user has access to them
        r.push(this.fetchAllocations({ lot_id: this.$route.params.id, page_size: 0 }))
        r.push(this.fetchAllocationTotals({ lot_id: this.$route.params.id, page_size: 0 }))
      }
      return Promise.all(r).then(() => {
        this.yard = this.lot.yard
        this.finalToz = this.calcFinalOzMetal()
        this.loading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      lot: 'lotStore/lot',
      lotTypes: 'lotStore/lotTypes',
      lotMaterialType: 'lotStore/lotMaterialType',
      lotStatuses: 'lotStore/lotStatuses',
      allocations: 'allocationStore/allocations',
      allocationTotals: 'allocationStore/allocationTotals',
      marketRate: 'marketRate/get',
      settlement: 'shipmentSummaryStore/brokeredSettlement'
    }),
    /**
     * Returns an empty array if in an ALTERNATE final state
     * @returns {Array}
     */
    stepperStatuses () {
      const alternateFinalStatuses = this.$store.getters['lotStore/finalStatuses'].filter(x => x !== 'CO')
      if (alternateFinalStatuses.includes(this.lot.status)) return []
      // return the normal list without the alternate final statuses
      return this.lotStatuses.filter(ls => !alternateFinalStatuses.includes(ls.value))
    },
    fileLinks () {
      return fileLinks.lotFileLink(this.lot)
    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
