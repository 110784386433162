<template>
  <div>
    <div class="d-flex">
      <v-checkbox
        v-model="showValues"
        label="Show Values"
        dense
      />
      <v-checkbox
        class="mx-2"
        v-model="showCreatedDate"
        label="Show Date"
        dense
      />
      <v-checkbox
        class="mx-2"
        v-model="showIntake"
        label="Show Intake"
        dense
      />
    </div>
    <v-data-table
      :loading="loading"
      :headers="headersFiltered"
      :items="rows"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.purchase="{ item }">
        <router-link-id
          v-if="item.purchase"
          :id="item.purchase"
          target="_blank"
          name="purchase"
        >
          {{ item.purchase }}
        </router-link-id>
      </template>
      <template v-slot:item.check_in="{ item }">
        <router-link-id
          v-if="item.check_in"
          :id="item.check_in"
          target="_blank"
          name="check-in"
        >
          {{ item.check_in }}
        </router-link-id>
      </template>
      <template v-slot:item.intake="{ item }">
        <router-link-id
          v-if="item.intake"
          :id="item.intake"
          target="_blank"
          name="intake"
        >
          {{ item.intake }}
        </router-link-id>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.transits="{ item }">
        <div
          v-for="id in item.transits"
          :key="id"
        >
          <router-link-id
            :id="id"
            target="_blank"
            name="transit-view"
          >
            {{ id }}
          </router-link-id>
        </div>
      </template>
      <template v-slot:body.append>
        <tr>
          <th
            v-for="header in headersFiltered"
            :key="header.value"
            class="text-right"
          >
            {{ totalsRow[header.value] }}
          </th>
        </tr>
      </template>
    </v-data-table>
    <div class="d-flex justify-end mt-2">
      <div>
        <v-btn
          v-if="!loading"
          color="primary"
        >
          <download-excel
            :data="Object.values(checkIns)"
            :fields="jsonFields"
            worksheet="My Worksheet"
            name="lot_check_ins.xls"
          >
            Download Data
          </download-excel>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency, formatNumber } from '@/filters'

function saveToLocalStorage () {
  localStorage.setItem('lotCheckinsTable_showValues', this.showValues)
  localStorage.setItem('lotCheckinsTable_showCreatedDate', this.showCreatedDate)
  localStorage.setItem('lotCheckinsTable_showIntake', this.showIntake)
}
export default {
  name: 'LotCheckInsTable',
  props: {
    checkIns: {
      type: Object,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: 'Purchase', value: 'purchase' },
      { text: 'Check In', value: 'check_in' },
      { text: 'Currency', value: 'currency' },
      { text: 'Cart', value: 'cart' },
      { text: 'Intake', value: 'intake' },
      { text: 'Transit', value: 'transits' },
      { text: 'Created', value: 'created_at' },
      { text: 'Status', value: 'purchase_status' },
      { text: 'Yard', value: 'yard_name' },
      { text: 'OEM', value: 'OM_count', align: 'right' },
      { text: 'OEM$', value: 'OM_price', align: 'right' },
      { text: 'AM', value: 'AM_count', align: 'right' },
      { text: 'AM$', value: 'AM_price', align: 'right' },
      { text: 'Diesel', value: 'DS_count', align: 'right' },
      { text: 'Diesel$', value: 'DS_price', align: 'right' },
      { text: 'Bead', value: 'BD_count', align: 'right' },
      { text: 'Bead$', value: 'BD_price', align: 'right' },
      { text: 'DPF', value: 'DP_count', align: 'right' },
      { text: 'DPF$', value: 'DP_price', align: 'right' },
      { text: 'IF', value: 'IF_count', align: 'right' },
      { text: 'IF$', value: 'IF_price', align: 'right' },
      { text: 'DF', value: 'DF_count', align: 'right' },
      { text: 'DF$', value: 'DF_price', align: 'right' },
      { text: 'Generic', value: 'GN_count', align: 'right' },
      { text: 'Generic$', value: 'GN_price', align: 'right' },
      { text: 'Total', value: 'total_count', align: 'right' },
      { text: 'Total $', value: 'total_price', align: 'right' }
    ],
    showValues: true,
    showCreatedDate: false,
    showIntake: false
    // showUnrelated: false
  }),
  computed: {
    headersFiltered () {
      let headers = this.headers
      const headersPresent = this.rows.map(row => Object.keys(row)).flat(1)
      const headersPresentList = [...new Set(headersPresent)]
      headers = this.headers.filter(h => headersPresentList.includes(h.value))
      if (!this.showValues) headers = headers.filter(h => !h.value.includes('_price'))
      if (!this.showCreatedDate) headers = headers.filter(h => h.value !== 'created_at')
      if (!this.showIntake) headers = headers.filter(h => h.value !== 'intake')
      return headers
    },
    totalsRow () {
      return this.formatRow(this.totals)
    },
    jsonFields () {
      let c = {}
      this.headers.forEach(v => {
        c[v.text] = v.value
      })
      return c
    },
    rows () {
      const rows = Object.values(JSON.parse(JSON.stringify(this.checkIns)))
      for (const rowKey in rows) {
        rows[rowKey] = this.formatRow(rows[rowKey])
      }
      return rows
    }
  },
  watch: {
    showValues: saveToLocalStorage,
    showCreatedDate: saveToLocalStorage,
    showIntake: saveToLocalStorage
  },
  methods: {
    formatRow (row) {
      for (const key in row) {
        if (key.endsWith('_count')) {
          row[key] = formatNumber(row[key])
        }
        if (key.endsWith('_price')) {
          row[key] = formatCurrency(row[key])
        }
      }
      return row
    },
    loadFromLocalStorage () {
      if (localStorage.getItem('lotCheckinsTable_showValues')) {
        this.showValues = localStorage.getItem('lotCheckinsTable_showValues') === 'true'
      }
      if (localStorage.getItem('lotCheckinsTable_showCreatedDate')) {
        this.showCreatedDate = localStorage.getItem('lotCheckinsTable_showCreatedDate') === 'true'
      }
      if (localStorage.getItem('lotCheckinsTable_showIntake')) {
        this.showIntake = localStorage.getItem('lotCheckinsTable_showIntake') === 'true'
      }
    }
  },
  mounted () {
    this.loadFromLocalStorage()
  }
}
</script>
