<template>
  <div>
    <create-location-dialog
      @created="setValueFromCreated"
    >
      <template v-slot:activator="{ on }">
        <v-autocomplete
          v-model="local"
          :label="label"
          :hint="hint"
          :items="items"
          :error-messages="errorMessages"
          :loading="isLoading"
          :search-input.sync="search"
          :item-text="formatLocation"
          persistent-hint
          clearable
          return-object
          append-outer-icon="mdi-plus"
          @click:append-outer="on"
          @change="$emit('input', $event)"
        />
        <location-block
          v-if="value"
          :location="value"
        />
      </template>
    </create-location-dialog>
  </div>
</template>

<script>
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock.vue'
import axios from 'axios'
import CreateLocationDialog from '@/components/CreateLocationDialog.vue'

export default {
  name: 'BaseLocationField',
  components: { CreateLocationDialog, LocationBlock },
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Location'
    },
    hint: {
      type: String,
      default: 'Start typing to search or + to create'
    },
    errorMessages: {
      type: Array,
      default: Array
    }
  },
  data: () => ({
    local: null,
    isLoading: false,
    items: [],
    search: null
  }),
  methods: {
    setValueFromCreated (value) {
      this.$emit('input', value)
      this.items = [value]
      this.local = value
    },
    formatLocation (location) {
      return `${location.id}. ${location.name} / ${location.address || 'Not Available'}`
    }
  },
  watch: {
    search (val) {
      const id = this.local?.id
      if (val.startsWith(id)) return
      this.isLoading = true
      axios.get('/logistics/locations/', { params: { search: val } })
        .then(res => {
          this.items = res.data.results
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
