<!--
Manifest Lines should be an array of lines like this
manifestLines = [
  {
    catalog_name:"Mixed Honeycomb"
    container:"BAG"
    gross:"2437.00"
    id:8216
    lot:"823 (Timos)"
    net:"2323.00"
    notes:""
    tare:"114.00"
    url:"http://localhost:8000/api/inventory_lines/8216/"
  }
]
-->

<template>
  <v-simple-table
    dense
  >
    <thead>
      <tr>
        <th>Container Number</th>
        <th>Lot</th>
        <th>Container Type</th>
        <th>Material Type</th>
        <th>Gross</th>
        <th>Tare</th>
        <th>Net</th>
        <th>Pieces</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(line, index) in manifestLines"
        :key="index"
      >
        <td>
          {{ index + 1 }}
        </td>
        <td>
          {{ cleanLotName(line.lot) }}
        </td>
        <td>
          {{ line.container }}
        </td>
        <td>
          {{ line.catalog_name }}
        </td>
        <td>
          {{ line.gross | appendLbs }}
        </td>
        <td>
          {{ line.tare | appendLbs }}
        </td>
        <td>
          {{ line.net | appendLbs }}
        </td>
        <td>
          {{ line.pieces | appendPcs }}
        </td>
      </tr>
      <tr>
        <td><b>Totals</b></td>
        <td />
        <td />
        <td />
        <td><b>{{ weightTotals.gross | appendLbs }}</b></td>
        <td><b>{{ weightTotals.tare | appendLbs }}</b></td>
        <td><b>{{ weightTotals.net | appendLbs }}</b></td>
        <td><b>{{ weightTotals.pieces | appendPcs }}</b></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { removeParenthesesText } from '@/helpers/utils'

export default {
  name: 'LotManifestTable',
  props: {
    manifestLines: {
      type: Array,
      required: true
    }
  },
  methods: {
    cleanLotName (name) {
      return removeParenthesesText(name)
    }
  },
  computed: {
    // if container type !== 'BAG', then the net needs to be calculated
    // because the 'net' in this instance is piece counts
    weightTotals () {
      return this.manifestLines.reduce((accu, line) => {
        accu.gross += parseInt(line.gross)
        accu.tare += parseInt(line.tare)
        accu.net += parseInt(line.net)
        accu.pieces += parseInt(line.pieces)
        return accu
      }, { gross: 0, tare: 0, net: 0, pieces: 0 })
    }
  }
}
</script>
