var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{staticClass:"pb-8",attrs:{"authorized":_vm.$store.getters.can('create-lots'),"message":"Unauthorized to create Lots"}},[(_vm.loading)?_c('app-loading-indicator'):_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('base-title',{attrs:{"title":"Create Lot"}}),_c('v-sheet',{staticClass:"pa-4"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:55"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name*","hint":"exp. 12345 (HOUSE)","counter":"55","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Suffix","hint":"rarely used exp. 1234","counter":"20","error-messages":errors},model:{value:(_vm.suffix),callback:function ($$v) {_vm.suffix=$$v},expression:"suffix"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"label":"Entity*","error-messages":errors},model:{value:(_vm.entityUrl),callback:function ($$v) {_vm.entityUrl=$$v},expression:"entityUrl"}},_vm._l((_vm.entities),function(et){return _c('v-radio',{key:et.name,attrs:{"label":et.name,"value":et.url}})}),1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"label":"Designation*","error-messages":errors},model:{value:(_vm.lotType),callback:function ($$v) {_vm.lotType=$$v},expression:"lotType"}},[_vm._l((_vm.lotTypes),function(lt){return _c('v-radio',{key:lt.value,attrs:{"label":lt.text + (lt['expects_settlement'] ? '*' : ''),"value":lt.value}})}),_c('span',{staticClass:"text-caption"},[_vm._v("*Yard Required")])],2)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"label":"Division*","error-messages":errors},model:{value:(_vm.division),callback:function ($$v) {_vm.division=$$v},expression:"division"}},_vm._l((_vm.divisions),function(division){return _c('v-radio',{key:division.id,attrs:{"label":division.name,"value":division.id}})}),1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{attrs:{"label":"Material Type"},model:{value:(_vm.materialType),callback:function ($$v) {_vm.materialType=$$v},expression:"materialType"}},[_c('v-radio',{attrs:{"label":"(None)","value":"none"}}),_vm._l((_vm.lotMaterialTypes),function(lmt){return _c('v-radio',{key:lmt.text,attrs:{"label":lmt.text,"value":lmt.value}})})],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('search-autocomplete',{attrs:{"label":"Account Manager","item-text":"username","item-value":"url","api-path":"/api/users/","params":{ fields: 'username,url', is_active: true },"error-messages":errors,"format-entry":function (x) { return Object.assign({ friendlyName: x.username }, x); }},model:{value:(_vm.accountManager),callback:function ($$v) {_vm.accountManager=$$v},expression:"accountManager"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('paginated-autocomplete',{attrs:{"label":"PPM Calculator*","item-text":"name","item-value":"id","base-url":"/api/ppm_calculations/","error-messages":errors},model:{value:(_vm.ppmCalc),callback:function ($$v) {_vm.ppmCalc=$$v},expression:"ppmCalc"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('paginated-autocomplete',{attrs:{"label":"Smelter","item-text":"name","item-value":"id","base-url":"/api/smelters/","search-params":{ is_active: true }},model:{value:(_vm.smelter),callback:function ($$v) {_vm.smelter=$$v},expression:"smelter"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":_vm.yardRequired ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('paginated-autocomplete',{attrs:{"label":_vm.yardRequired ? 'Select a Yard*' : 'Select a Yard',"item-text":function (x) { return ((x.id) + " - " + (x.name) + " | " + (x.full_address || '') + " | " + (x.status)); },"base-url":"/api/yards/","params":{ status: 'GS' },"multiple":""},model:{value:(_vm.chipYards),callback:function ($$v) {_vm.chipYards=$$v},expression:"chipYards"}}),_vm._l((errors),function(err,index){return _c('div',{key:index,staticClass:"red--text"},[_c('small',[_vm._v(" "+_vm._s(err)+" ")])])})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('paginated-autocomplete',{attrs:{"label":"Designated Hedge(s)","item-text":"id","base-url":"/api/hedges/","multiple":""},model:{value:(_vm.designatedHedges),callback:function ($$v) {_vm.designatedHedges=$$v},expression:"designatedHedges"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('rules-date',{attrs:{"validation-rules":"","label":"Processing Date","clearable":""},model:{value:(_vm.processingDate),callback:function ($$v) {_vm.processingDate=$$v},expression:"processingDate"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"name":"notes","label":"Notes","counter":"","rows":4,"auto-grow":""},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('text-fab',{attrs:{"disabled":invalid || _vm.creatingLot,"bottom":"","right":"","fixed":"","icon-text":"mdi-invoice-text-plus","color":"primary"},on:{"click":_vm.create}},[_vm._v(" Create ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }