<template>
  <v-container>
    <v-form
      @submit.prevent="setData"
      ref="form"
    >
      <ValidationObserver
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <div class="caption">
          <p>Only lots without a shipment summary attached will be shown below</p>
        </div>
        <ValidationProvider
          v-if="!lot"
          rules="required"
          v-slot="{ errors }"
        >
          <paginated-autocomplete
            v-model="selectedLots"
            label="Select Included Lots *"
            multiple
            item-text="name"
            item-value="id"
            base-url="/api/lots/"
            @change="checkData"
            :search-params="{ shipment_summary_lot__id__isnull: true }"
            :error-messages="errors"
          />
        </ValidationProvider>
        <card-list-item
          v-else
          title="Lot"
        >
          {{ lot.name }}
        </card-list-item>
        <ValidationProvider
          rules="required"
          v-slot="{ errors }"
        >
          <paginated-autocomplete
            label="Select Smelter *"
            v-model="smelter"
            item-text="name"
            item-value="id"
            base-url="/api/smelters/"
            @change="checkData"
            return-object
            :error-messages="errors"
          />
        </ValidationProvider>
        <v-list>
          <v-list-item>
            <h3>Input option: </h3>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              color="primary"
              label="Manual Entry"
              v-model="manualEntry"
              :disabled="disabledManual"
            />
          </v-list-item>
          <v-list-item>
            <v-checkbox
              color="primary"
              label="Import Summary"
              v-model="importEntry"
              :disabled="disabledImport"
            />
          </v-list-item>
        </v-list>
        <div class="caption">
          <p>* Indicates a required field</p>
        </div>
        <base-button
          auto-center
          :disabled="invalid || noEntryOp || loading"
          :loading="loading"
          type="submit"
        >
          Submit
        </base-button>
      </ValidationObserver>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'WriteSmelterLot',
  data: () => ({
    smelter: null,
    selectedLots: null,
    importEntry: false,
    manualEntry: false,
    loading: false
  }),
  props: {
    lot: { type: Object, required: false, default: null },
    resetForms: { type: Boolean, required: false, default: false }
  },
  methods: {
    ...mapMutations('shipmentSummaryStore', [
      'setLots',
      'setSmelter',
      'setInputType'
    ]),
    // checks conditions that determine if check boxes should be unchecked
    checkData () {
      this.importEntry = this.importable
      this.manualEntry = !this.importable
    },
    // sets data in shipment summary store
    setData () {
      this.loading = true
      this.setLots(this.selectedLots)
      this.setSmelter(this.smelter)
      if (this.importEntry) this.setInputType('import')
      if (this.manualEntry) this.setInputType('manual')
      this.loading = false
      this.$emit('incStep')
    }
  },
  computed: {
    importable () {
      if (!this.smelter) return false
      for (const name of ['Stillwater', 'BASF']) {
        if (this.smelter.name.includes(name)) return true
      }
      return false
    },
    // cannot submit if no entry option has been chosen
    noEntryOp () {
      if (!this.importEntry && !this.manualEntry) return true
      return false
    },
    // conditions that disable importing docs (can only be 1 lot && stillwater smelter)
    disabledImport () {
      if (this.manualEntry || !this.selectedLots) return true
      return !this.importable
    },
    // conditions that disable manual (no smelter, no lot, import selected)
    disabledManual () {
      return !this.smelter || !this.selectedLots === null || this.importEntry
    }
  },
  watch: {
    resetForms () {
      if (this.resetForms) this.$refs.form.reset()
    }
  },
  mounted () {
    // Checks if a lot been provided via lot/settlementoverview
    if (this.lot) {
      this.selectedLots = [this.lot]
      this.smelter = this.lot.smelter
    }
  }
}
</script>
