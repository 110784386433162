<template>
  <div>
    <v-btn
      v-if="$store.getters.can('create-assay-result')"
      color="primary"
      small
      fab
      absolute
      bottom
      right
      @click="editDialog = !editDialog"
    >
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <ValidationObserver
      v-slot="{ invalid, reset }"
      ref="observer"
    >
      <edit-dialog
        headline-text="Add Assay Result"
        v-model="editDialog"
        :inputs-valid="!invalid"
        accept-text="Add Assay"
        @accept="submit"
      >
        <assay-result-form
          v-if="editDialog"
          @export="payload = $event"
        />
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import { createAssayResult } from '@/requests/assayResult'
import AssayResultForm from '@/views/Lots/components/AssayResult/AssayResultForm.vue'

export default {
  name: 'CreateAssayResultDialog',
  components: { AssayResultForm },
  props: {
    lotId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    editDialog: false,
    payload: {}
  }),
  methods: {
    async submit () {
      if (!this.payload) {
        this.$store.commit('setSnackbarError', 'Unable to process data')
        return
      }
      // eslint-disable-next-line camelcase
      const { pt_ppm, pd_ppm, rh_ppm, status, percent_moisture, percent_scrap, date, source, assay_type } = this.payload
      try {
        const response = await createAssayResult(pt_ppm, pd_ppm, rh_ppm, status, percent_moisture, percent_scrap, this.lotId, date, source, assay_type)
        if (response.status === 201) {
          this.$emit('created', response.data)
        }
      } catch (e) {
        this.$logger.error(e.message)
        this.$store.commit('setSnackbarError', 'failed to create assay result')
      }
    }
  }
}
</script>
