<template>
  <v-card>
    <v-card-subtitle>
      <strong>Lot Overview</strong>
    </v-card-subtitle>
    <v-row>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Status"
        >
          <template>
            {{ formatLotStatus() | filterNA }}
          </template>
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Entity"
        >
          <template>
            {{ lot.entity_code }}
          </template>
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          title="Designation"
          no-cols
        >
          {{ getDesignation }}
        </card-list-item>
      </v-col>
      <v-col
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Created Date"
        >
          {{ lot.created_at | formatDate }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Days On Hand"
        >
          <info-tooltip>
            <template v-slot:wrap-me>
              <span :class="{'red--text': lot.days_on_hand > 7 }">
                {{ lot.days_on_hand }}
              </span>
            </template>
            Days since processing began until shipped.
          </info-tooltip>
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Processing Date"
        >
          {{ lot.processing_date | formatDate }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Ship Date"
        >
          {{ lot.ship_date | formatDate }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Smelter"
        >
          {{ lot.smelter | filterObject('name') }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Smelter Lot ID"
        >
          {{ lot.smelter_lot_id || '--' }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Lot Type"
        >
          {{ getLotType }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Manual Pc Count"
        >
          {{ lot.piece_count }}
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Average Catalyst/Cat"
          v-if="lot.inventory"
        >
          {{ aveCatalystPerCat | toFixed(2) }} lbs
        </card-list-item>
      </v-col>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          title="Account Manager"
          dense
          no-cols
        >
          {{ lot.account_manager__username || 'None' }}
        </card-list-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        xs="12"
      >
        <card-list-item
          dense
          no-cols
          title="Notes"
        >
          {{ lot.notes || 'No Notes' }}
        </card-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LotOverview',
  data: function () {
    return {
      editingInfo: false
    }
  },
  props: {
    lot: { type: Object, required: true },
    allocations: { type: Array, required: true },
    marketRate: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('lotStore', [
      'lotTypes',
      'lotMaterialType',
      'lotStatuses'
    ]),
    getLotType () {
      const dd = this.lotMaterialType.find(mt => mt.value === this.lot.lot_type)
      return dd ? dd.text : this.lot.lot_type
    },
    getDesignation () {
      const dd = this.lotTypes.find(lt => lt.value === this.lot.designation)
      return dd ? dd.text : this.lot.designation
    },
    aveCatalystPerCat () {
      if (this.lot.inventory.inventoryline_set.length !== 0 && this.lot.piece_count !== 0) {
        let netWeight = this.lot.inventory.inventoryline_set.reduce((accu, line) => {
          accu += (line.gross - line.tare)
          return accu
        }, 0)
        return netWeight / this.lot.piece_count > 0 ? netWeight / this.lot.piece_count : 0
      } else return 0
    }
  },
  methods: {
    // Used for updating lot overview section
    updateStatus (status) {
      let body = {}
      if (status.value) body.status = status.value
      else { body = status }
      this.editingInfo = false
      this.$emit('updateLot', body)
    },
    /**
     * Get the more human readable version of a lot status
     */
    formatLotStatus () {
      const status = this.lot.status
      if (!status) return null
      for (const obj of this.lotStatuses) {
        if (obj.value === status) return obj.text
      }
      return null
    }
  }
}
</script>
