<template>
  <unauthorized
    :authorized="$store.getters.can('view-client-proforma')"
    message="You are not authorized to view lots"
  >
    <url-breadcrumbs
      v-if="breadcrumbs"
      :items="breadcrumbs"
    />
    <div v-if="error">
      {{ error }}
    </div>
    <brokered-settlement-skeleton
      v-else-if="loading"
      title="Client Proforma"
    />
    <v-card
      v-else
      class="pa-2 mx-auto"
      flat
      max-width="1000px"
    >
      <v-row>
        <v-col
          class="text-left"
          cols="3"
        >
          <v-img
            :src="config.logo"
            :alt="config.name"
            height="100px"
            contain
          />
        </v-col>
        <v-col
          class="text-center mt-4"
          cols="6"
        >
          <div class="text-h4 font-weight-bold">
            Client Proforma
          </div>
          <div class="text-h6">
            {{ yardName }}
          </div>
        </v-col>
        <v-col
          class="text-right"
          cols="3"
        >
          <div class="mt-6">
            <div class="font-weight-medium">
              Date Shipped: {{ shipDate | formatDate }}
              <br>
              Lot: {{ lotName }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-simple-table
            dense
          >
            <thead>
              <tr>
                <th>
                  Metal
                </th>
                <th>
                  Hedge ID
                </th>
                <th class="text-right">
                  Contract Return %
                </th>
                <th class="text-right">
                  Returnable Toz
                </th>
                <th class="text-right">
                  Hedge Value
                </th>
                <th class="text-right">
                  Total Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(alloc, index) in allocations.filter(a => +a.ptValue > 0)"
                :key="'pt:' + index"
                class="text-right"
              >
                <td class="text-left">
                  <span v-if="index === 0">
                    Pt
                  </span>
                </td>
                <td class="text-left">
                  {{ alloc.hedgeId }}
                </td>
                <td />
                <td>
                  {{ alloc.ptRtoz }}
                </td>
                <td>
                  {{ alloc.ptMarketRate | currencyUSD }}
                </td>
                <td>
                  {{ alloc.ptValue | currencyUSD }}
                </td>
              </tr>
              <tr class="text-right font-weight-medium">
                <td />
                <td />
                <td>
                  {{ ptTermRate }} %
                </td>
                <td>
                  {{ allocatedReturnablePt }}
                </td>
                <td />
                <td>
                  {{ allocatedReturnablePtValue | currencyUSD }}
                </td>
              </tr>
              <tr><v-col colspan="6" /></tr>
              <tr
                v-for="(alloc, index) in allocations.filter(a => +a.pdValue > 0)"
                :key="'pd:' + index"
                class="text-right"
              >
                <td class="text-left">
                  <span v-if="index === 0">
                    Pd
                  </span>
                </td>
                <td class="text-left">
                  {{ alloc.hedgeId }}
                </td>
                <td />
                <td>
                  {{ alloc.pdRtoz }}
                </td>
                <td>
                  {{ alloc.pdMarketRate | currencyUSD }}
                </td>
                <td>
                  {{ alloc.pdValue | currencyUSD }}
                </td>
              </tr>
              <tr class="text-right font-weight-medium">
                <td />
                <td />
                <td>
                  {{ pdTermRate }} %
                </td>
                <td>
                  {{ allocatedReturnablePd }}
                </td>
                <td />
                <td>
                  {{ allocatedReturnablePdValue | currencyUSD }}
                </td>
              </tr>
              <tr><v-col colspan="6" /></tr>
              <tr
                v-for="(alloc, index) in allocations.filter(a => +a.rhValue > 0)"
                :key="'rh:' + index"
                class="text-right"
              >
                <td class="text-left">
                  <span v-if="index === 0">
                    Rh
                  </span>
                </td>
                <td class="text-left">
                  {{ alloc.hedgeId }}
                </td>
                <td />
                <td>
                  {{ alloc.rhRtoz }}
                </td>
                <td>
                  {{ alloc.rhMarketRate | currencyUSD }}
                </td>
                <td>
                  {{ alloc.rhValue | currencyUSD }}
                </td>
              </tr>
              <tr class="text-right font-weight-medium">
                <td />
                <td />
                <td>
                  {{ rhTermRate }} %
                </td>
                <td>
                  {{ allocatedReturnableRh }}
                </td>
                <td />
                <td>
                  {{ allocatedReturnableRhValue | currencyUSD }}
                </td>
              </tr>
              <tr><v-col colspan="6" /></tr>
              <tr>
                <td
                  class="text-right font-weight-bold"
                  colspan="5"
                >
                  Estimated Gross Value
                </td>
                <td class="text-right">
                  {{ grossMetalValue | currencyUSD }}
                </td>
              </tr>
              <tr v-if="+grossOutrightUnpaidValue > 0">
                <td
                  class="text-right font-weight-bold"
                  colspan="5"
                >
                  Gross Unpaid Unit Value (not shown)
                </td>
                <td class="text-right">
                  {{ grossOutrightUnpaidValue | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +refiningFee > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Refining Fee
                </td>
                <td class="text-right">
                  {{ refiningFee | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +processingFee > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Processing Fee
                </td>
                <td class="text-right">
                  {{ processingFee | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +handlingFee > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Handling Fee
                </td>
                <td class="text-right">
                  {{ handlingFee | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +freightCharge > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  FreightCharge
                </td>
                <td class="text-right">
                  {{ freightCharge | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +assayFee > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Assay Fee
                </td>
                <td class="text-right">
                  {{ assayFee | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +shreddingFee > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Shredding Fee
                </td>
                <td class="text-right">
                  {{ shreddingFee | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +brokerageFee > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Brokerage Fee
                </td>
                <td class="text-right">
                  {{ brokerageFee | currencyUSD }}
                </td>
              </tr>
              <tr v-if="!consolidateFees && +netOtherFees > 0">
                <td
                  class="text-right font-weight-light"
                  colspan="5"
                >
                  Net Other Fees
                </td>
                <td class="text-right">
                  {{ netOtherFees | currencyUSD }}
                </td>
              </tr>
              <tr v-if="+totalFees !== 0">
                <td
                  class="text-right font-weight-bold"
                  colspan="5"
                >
                  Estimated Total Fees
                </td>
                <td class="text-right">
                  <span v-if="+totalFees > 0">
                    ({{ totalFees | currencyUSD }})
                  </span>
                  <span v-else>
                    {{ totalFees | currencyUSD }}
                  </span>
                </td>
              </tr>
              <tr v-if="+financeCharge > 0">
                <td
                  class="text-right font-weight-bold"
                  colspan="5"
                >
                  Finance Charge
                </td>
                <td class="text-right">
                  ({{ financeCharge | currencyUSD }})
                </td>
              </tr>
              <tr><td colspan="6" /></tr>
              <tr>
                <td
                  class="text-right font-weight-bold"
                  colspan="5"
                >
                  Estimated Net Value
                </td>
                <td class="text-right">
                  {{ netValue | currencyUSD }}
                </td>
              </tr>
              <tr>
                <td
                  class="text-right font-weight-bold"
                  colspan="5"
                >
                  Deposit {{ depositPercent || 0 }}%
                </td>
                <td class="text-right">
                  {{ depositTotal | currencyUSD }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
  </unauthorized>
</template>

<script>
import BrokeredSettlementSkeleton from '@/views/Lots/components/Brokered/components/BrokeredSettlementSkeleton.vue'
import config from '@/../izzi_config'

export default {
  name: 'ClientProformaPrint',
  components: {
    BrokeredSettlementSkeleton
  },
  data () {
    return {
      config,
      error: null,
      breadcrumbs: [
        ['lot list', { name: 'lot-list' }],
        [`Lot #${this.$route.params.id}`, { name: 'lot' }],
        [`Client Proforma`, { name: 'client-proforma' }],
        ['Print', { name: 'client-proforma-print' }]
      ],
      loading: false
    }
  },
  computed: {
    lotName () {
      return this.$store.getters['clientProformaStore/getLotName']
    },
    lotId () {
      return this.$store.getters['clientProformaStore/getLotId']
    },
    yardName () {
      const yardId = this.$store.getters['clientProformaStore/getYardId']
      const yards = this.$store.getters['clientProformaStore/getYardChoices']
      const yard = yards.find(y => y.id === yardId)
      return yard?.name
    },
    shipDate () {
      return this.$store.getters['clientProformaStore/getShipDate']
    },
    convertersProcessed () {
      return this.$store.getters['clientProformaStore/getConvertersProcessed']
    },
    mixedCatalystWeight () {
      return this.$store.getters['clientProformaStore/getMixedCatalystWeight']
    },
    clientValueDate () {
      return this.$store.getters['clientProformaStore/getClientValueDate']
    },
    refiningFee () {
      return this.$store.getters['clientProformaStore/getRefiningFee']
    },
    processingFee () {
      return this.$store.getters['clientProformaStore/getProcessingFee']
    },
    handlingFee () {
      return this.$store.getters['clientProformaStore/getHandlingFee']
    },
    freightCharge () {
      return this.$store.getters['clientProformaStore/getFreightCharge']
    },
    assayFee () {
      return this.$store.getters['clientProformaStore/getAssayFee']
    },
    shreddingFee () {
      return this.$store.getters['clientProformaStore/getShreddingFee']
    },
    brokerageFee () {
      return this.$store.getters['clientProformaStore/getBrokerageFee']
    },
    netOtherFees () {
      return this.$store.getters['clientProformaStore/getNetOtherFees']
    },
    totalFees () {
      return this.$store.getters['clientProformaStore/getTotalFees']
    },
    consolidateFees () {
      return this.$store.getters['clientProformaStore/getConsolidateFees']
    },
    netValue () {
      return this.$store.getters['clientProformaStore/getNetValue']
    },
    expectedPaymentDate () {
      return this.$store.getters['clientProformaStore/getExpectedPaymentDate']
    },
    interestPercent () {
      return this.$store.getters['clientProformaStore/getInterestPercent']
    },
    financeCharge () {
      return this.$store.getters['clientProformaStore/getFinanceCharge']
    },
    depositPercent () {
      return this.$store.getters['clientProformaStore/getDepositPercent']
    },
    allocations () {
      return this.$store.getters['clientProformaStore/getAllocations']
    },
    grossOutrightUnpaidValue () {
      return this.$store.getters['clientProformaStore/getGrossOutrightUnpaidValue']
    },
    availablePt () {
      return this.$store.getters['clientProformaStore/getAvailablePt']
    },
    availablePd () {
      return this.$store.getters['clientProformaStore/getAvailablePd']
    },
    availableRh () {
      return this.$store.getters['clientProformaStore/getAvailableRh']
    },
    grossMetalValue () {
      return this.$store.getters['clientProformaStore/getGrossMetalValue']
    },
    depositTotal () {
      return this.$store.getters['clientProformaStore/getDepositTotal']
    },
    brokerageTerms () {
      return this.$store.getters['clientProformaStore/getCleanedBrokerageTerms']
    },
    ptTermRate () {
      return this.$store.getters['clientProformaStore/getPtTermRate']
    },
    pdTermRate () {
      return this.$store.getters['clientProformaStore/getPdTermRate']
    },
    rhTermRate () {
      return this.$store.getters['clientProformaStore/getRhTermRate']
    },
    allocatedReturnablePt () {
      return this.$store.getters['clientProformaStore/getAllocatedReturnablePt']
    },
    allocatedReturnablePd () {
      return this.$store.getters['clientProformaStore/getAllocatedReturnablePd']
    },
    allocatedReturnableRh () {
      return this.$store.getters['clientProformaStore/getAllocatedReturnableRh']
    },
    allocatedReturnablePtValue () {
      return this.$store.getters['clientProformaStore/getAllocatedReturnablePtValue']
    },
    allocatedReturnablePdValue () {
      return this.$store.getters['clientProformaStore/getAllocatedReturnablePdValue']
    },
    allocatedReturnableRhValue () {
      return this.$store.getters['clientProformaStore/getAllocatedReturnableRhValue']
    },
    advances () {
      return this.$store.getters['clientProformaStore/getAdvances']
    },
    history () {
      return this.$store.getters['clientProformaStore/getAdvanceCalculatorHistory']
    }
  },
  methods: {
    async initialize () {
      return Promise.all([
        this.$store.dispatch('clientProformaStore/fetchAdvanceCalculators'),
        this.$store.dispatch('clientProformaStore/fetchDesignatedHedges'),
        this.$store.dispatch('clientProformaStore/fetchAdvances')
      ])
    }
  },
  /*
   * Fetch the history, if no history then fetch the latest lot seed data
   */
  async mounted () {
    this.loading = true
    this.$store.commit('clientProformaStore/clearState')
    this.$store.commit('clientProformaStore/setLotId', this.$route.params.id)
    await this.initialize()
    if (this.history.length) {
      this.$store.commit('clientProformaStore/setContextFromHistory', this.history[0])
    } else {
      // if there is no history, then seed data
      this.error = 'Unable to load a Client Proforma'
    }
    this.loading = false
  }
}
</script>
