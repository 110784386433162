<template>
  <v-card>
    <v-card-subtitle>
      <strong>Designated Yards</strong>
    </v-card-subtitle>
    <v-data-table
      :headers="yardTableHeaders"
      :items="yards"
    />
    <v-btn
      color="primary"
      small
      fab
      absolute
      bottom
      right
      @click="editDialog = !editDialog"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-dialog
      v-model="editDialog"
      headline-text="Update Designated Yards"
      @accept="updateYards"
    >
      <v-autocomplete
        label="Search for Yard(s)"
        v-model="chipYards"
        :search-input.sync="search"
        :items="combinedItems"
        :item-text="x => `${x.id} - ${x.name} | ${x.full_address || ''} | ${x.status}`"
        chips
        return-object
        multiple
      >
        <template v-slot:selection="data">
          <v-chip
            close
            @click="data.select"
            @click:close="removeChip(data.item)"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
      </v-autocomplete>
    </edit-dialog>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DesignatedYards',
  data: () => ({
    editDialog: false,
    designatedYards: [],
    chipYards: [],
    selectableYards: [],
    search: null,
    expanded: [],
    timeout: null,
    yardTableHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Address', value: 'full_address' }
    ]
  }),
  props: {
    yards: { type: Array, required: false, default: () => ([]) },
    url: { type: String, required: true }
  },
  watch: {
    // watches for input changes on edit Designated Yard
    search (val) {
      if (!val) {
        this.selectableYards = []
        return
      }
      this.fetchResults(val)
    },
    editDialog (opened) {
      // reset the chips everytime the edit dialog is opened
      if (opened) this.chipYards = this.yards.slice()
    }
  },
  computed: {
    // Adds the chips to the list of selectableYards so they dont disappear if the query doesnt match a chip
    combinedItems () {
      return this.selectableYards.concat(this.chipYards)
    }
  },
  methods: {
    // patch request to add or remove designated yards for a lot
    updateYards () {
      axios.patch(this.url, { yards: this.chipYards.map(h => h.id) }).then(() => {
        this.$emit('fetchLot')
      })
    },
    // removes a chip when clicking the close button on it
    removeChip (item) {
      this.chipYards = this.chipYards.filter(chip => chip.id !== item.id)
    },
    // fetches new search results based on the value passed into search on a 3ms delay
    fetchResults (val) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const params = { status: 'GS', search: val, fields: 'id,name,full_address' }
        const response = await axios.get('/api/yards/', { params })
        this.selectableYards = response.data.results
      }, 300)
    }
  }
}
</script>
