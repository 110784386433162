<template>
  <div>
    <h2>
      Finance/Amount Due
    </h2>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <ValidationProvider
          immediate
          v-slot="{ errors }"
        >
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                dense
                :value="bs.expected_pay_date"
                @input="$emit('setProp', ['expected_pay_date', $event])"
                label="Expected Payment Date"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                :error-messages="errors"
              />
            </template>
            <v-date-picker
              v-model="bs.expected_pay_date"
              @input="$emit('setProp', ['expected_pay_date', $event])"
            />
          </v-menu>
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|min_value:0|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            :readonly="!bs.finance_charge_manual"
            :class="{'dense-filled': !bs.finance_charge_manual}"
            dense
            type="number"
            :value="bs.finance_charge"
            @input="$emit('setProp', ['finance_charge', $event])"
            label="Finance Charge"
            prepend-icon="mdi-currency-usd"
            :hint="'Net Value x 5% / 360 x (difference between received date ' + config.receivedDate + ' and Expected Payment Date)'"
            :append-icon="bs.finance_charge_manual ? 'mdi-circle-outline' : 'mdi-alpha-a-circle-outline'"
            :error-messages="errors"
            @click:append="$emit('setProp', ['finance_charge_manual', !bs.finance_charge_manual])"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|min_value:0|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            dense
            type="number"
            :value="bs.advances"
            @input="$emit('setProp', ['advances', $event])"
            label="Advances"
            prepend-icon="mdi-currency-usd"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <ValidationProvider
          immediate
          rules="required|max_value:99999999|min_value:0|decimal:2"
          v-slot="{ errors }"
        >
          <v-text-field
            class="dense-filled"
            readonly
            dense
            type="number"
            :value="bs.amount_due"
            prepend-icon="mdi-currency-usd"
            label="Settlement Amount Due"
            hint="(readonly) net value - advance + unpaid value"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BSDueForm',
  props: {
    bs: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
}
</script>
