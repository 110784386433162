<template>
  <unauthorized
    :authorized="$store.getters.can('view-assay-result')"
    message="Unauthorized to view assay results"
  >
    <v-container>
      <base-title>
        Assay Comparison by Lot
      </base-title>
      <v-form>
        <v-row>
          <v-col>
            <rules-date
              v-model="startDate"
              :max="endDate || today"
              label="Lot Creation Date Start"
            />
          </v-col>
          <v-col>
            <rules-date
              v-model="endDate"
              :min="startDate"
              :max="today"
              label="Lot Creation Date End"
            />
          </v-col>
          <v-col
            cols="1"
            class="text-right mt-3"
          >
            <v-menu
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <download-excel
                  v-if="results.length"
                  :data="results"
                  :name="`assay-comparison-between-${startDate}-${endDate}.csv`"
                  type="csv"
                >
                  <v-btn text>
                    Download Results
                  </v-btn>
                </download-excel>
                <v-btn
                  v-else
                  disabled
                  text
                >
                  Download Results
                </v-btn>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
      <v-card>
        <v-card-subtitle>
          Results: {{ results.length }}
          <info-tooltip>
            Lots are filtered by Created Date. Only lots with both Internal and Smelter assays will be displayed.
          </info-tooltip>
        </v-card-subtitle>
        <v-skeleton-loader
          v-if="loading"
          type="table-tbody"
        />
        <v-data-table
          v-else-if="results.length"
          :headers="headers"
          :items="results"
          hide-default-footer
          disable-pagination
          :item-class="rowClasses"
        >
          <template v-slot:item.pt="{ item }">
            <span :class="{ 'red--text': item.pt < 0 }">
              {{ item.pt | toFixed(2) | addCommas }}
            </span>
          </template>
          <template v-slot:item.pd="{ item }">
            <span :class="{ 'red--text' : item.pd < 0}">
              {{ item.pd | toFixed(2) | addCommas }}
            </span>
          </template>
          <template v-slot:item.rh="{ item }">
            <span :class="{ 'red--text' : item.rh < 0}">
              {{ item.rh | toFixed(2) | addCommas }}
            </span>
          </template>
        </v-data-table>
        <div v-else>
          <v-card-text>
            No Results.
          </v-card-text>
        </div>
      </v-card>
    </v-container>
  </unauthorized>
</template>

<script>

import { getAssayResultComparisons } from '@/requests/assayResult'
import RulesDate from '@/components/ValidationFields/RulesDate.vue'
import { fetchLotIdsInDateRange } from '@/requests/lot'
import moment from 'moment'

const today = moment().format('YYYY-MM-DD')

export default {
  name: 'AssayComparisonByLot',
  components: { RulesDate },
  data: () => ({
    startDate: null,
    endDate: today,
    today: today,
    loading: false,
    headers: [
      { text: 'Smelter', value: 'smelter__name' },
      { text: 'Lot', value: 'lot__name' },
      { text: 'Yard', value: 'yard__name' },
      { text: 'Origin', value: 'origin' },
      { text: 'Date', value: 'date' },
      { text: 'Type', value: 'type' },
      { text: 'Pt PPM', value: 'pt', align: 'right' },
      { text: 'Pd PPM', value: 'pd', align: 'right' },
      { text: 'Rh PPM', value: 'rh', align: 'right' }
    ],
    results: []
  }),
  watch: {
    startDate (val) {
      if (val !== this.getStartDate()) this.updateRoute()
    },
    endDate (val) {
      if (val !== this.getEndDate()) this.updateRoute()
    }
  },
  methods: {
    rowClasses (row) {
      if (!row.origin && !row.origin) {
        return 'blue lighten-5 font-weight-medium'
      }
    },
    getStartDate () {
      const query = this.$route.query
      return query.startDate
    },
    getEndDate () {
      const query = this.$route.query
      return query.endDate
    },
    updateRoute () {
      if (!this.startDate || !this.endDate) return
      this.$router.replace({
        name: this.$route.name,
        query: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      })
    },
    formatDate (date) {
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    setResults (data) {
      let results = []
      const fmtResult = ar => ({
        origin: ar.origin,
        date: this.$options.filters.formatDate(ar.date),
        type: ar.type,
        pt: ar.pt,
        pd: ar.pd,
        rh: ar.rh
      })
      data.forEach(row => {
        results.push({
          smelter__name: row.smelter__name,
          lot__name: row.lot__name,
          yard__name: row.yard__name,
          ...fmtResult(row.assay_result)
        })
        results.push(fmtResult(row.smelter_result))
        results.push({
          pt: row.difference.pt,
          pd: row.difference.pd,
          rh: row.difference.rh
        })
      })
      this.results = results
    },
    addOneDay (dateString) {
      return moment(dateString, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    async fetchResults () {
      if (!(this.startDate && this.endDate)) return
      this.loading = true
      const ids = await fetchLotIdsInDateRange(this.startDate, this.addOneDay(this.endDate))
      const rows = await getAssayResultComparisons(...ids)
      this.setResults(rows)
      this.loading = false
    }
  },
  mounted () {
    // Page changes based on start date and end date set, this causes reload and data fetch
    const sd = this.getStartDate()
    const ed = this.getEndDate()
    if (sd) this.startDate = sd
    if (ed) this.endDate = ed
    this.fetchResults()
  }
}
</script>
