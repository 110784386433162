<!--
Attempts to load the manifest by the lot id, if does not exist prompt to create or attach.
-->
<template>
  <unauthorized
    :authorized="$store.getters.can('create-lot-manifests')"
    message="Unauthorized to view lab entry"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          offset-xl="2"
          xl="8"
        >
          <base-title>
            Create Lot Manifest
          </base-title>
          <div class="mb-4" />
          <v-form @submit.prevent="createManifest">
            <ValidationObserver>
              <v-card flat>
                <v-card-text style="min-height: 500px">
                  Select the lots to be included in manifest. Only lots that do not have a manifest may be added.
                  <v-row>
                    <v-col>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          v-model="selected"
                          :items="selectable"
                          :loading="isLoading"
                          :search-input.sync="searchText"
                          item-text="name"
                          label="Select Lots"
                          chips
                          deletable-chips
                          multiple
                          placeholder="Start typing to Search lots w/o manifests"
                          return-object
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <lot-manifest-table
                        :manifest-lines="lines"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    :disabled="!selected.length"
                    color="primary"
                    type="submit"
                  >
                    Create
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import LotManifestTable from '@/views/Lots/components/LotManifest/LotManifestTable.vue'

export default {
  name: 'CreateLotManifest',
  components: { LotManifestTable },
  data: () => ({
    selected: [],
    results: [],
    isLoading: false,
    searchText: '',
    searchTimeout: null,
    lines: []
  }),
  computed: {
    selectable () {
      let s = new Map()
      for (const x of this.selected) {
        s.set(x.url, x)
      }
      for (const x of this.results) {
        s.set(x.url, x)
      }
      return [...s.values()]
    }
  },
  watch: {
    selected (selected) {
      const lotIds = selected.map(x => x.id)
      this.fetchLines(lotIds)
    },
    searchText (val) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout)

      // Lazily load input items
      const params = {
        fields: 'id,url,name',
        manifest__isnull: true,
        search: val,
        ordering: '-id'
      }

      this.searchTimeout = setTimeout(() => {
        this.isLoading = true
        axios.get('/api/lots/', { params }).then(res => {
          this.results = res.data.results
        }).finally(() => {
          this.isLoading = false
        })
      }, 300)
    }
  },
  methods: {
    async fetchLines (lotIds) {
      this.lines = []
      if (!lotIds) return
      const requests = lotIds.map(id => axios.get(`/api/lots/${id}/?fields=id,name,inventory`))
      const responses = await Promise.all(requests)
      responses.forEach(response => {
        const l = response.data
        l.inventory.inventoryline_set.forEach(line => {
          const out = {
            catalog_name: line.catalog.name,
            container: line.container,
            gross: line.gross,
            id: line.id,
            lot: l.name,
            net: line.net,
            notes: '',
            tare: line.tare,
            url: line.url
          }
          this.lines.push(out)
        })
      })
    },
    getSelectedUrls () {
      return this.selected.map(x => x.url)
    },
    createManifest () {
      if (this.selected.length === 0) return
      axios.post('/api/lot_manifests/', {
        lots: this.getSelectedUrls()
      }).then(res => {
        if (res.status === 201) {
          this.$store.commit('setSnackbarSuccess', 'Manifest Created')
          this.$router.push({
            name: 'lot-manifest',
            params: { id: res.data.id },
            query: { request: true }
          })
        }
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Creation Failed')
      })
    },
    /* Fetch the lot - if lot id provided */
    async fetchLot (lotId) {
      const params = { fields: 'url,id,name,manifest_id' }
      const response = await axios.get(`/api/lots/${lotId}/`, { params })
      this.selected.push(response.data)
      return response.data
    }
  },
  async mounted () {
    if (this.$route.query && this.$route.query.lot) {
      this.fetchLot(this.$route.query.lot).then(lot => {
        // Force the user to the manifest page if it already exists
        if (lot.manifest_id) {
          this.$router.push({ name: 'lot-manifest', params: { id: lot.manifest_id } })
        }
      })
    }
  }
}
</script>
