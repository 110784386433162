<template>
  <v-card class="pb-4">
    <unauthorized
      :authorized="$store.getters.can('view-assay-result')"
      message="Sorry, not authorized to see PPM's"
    >
      <v-card-subtitle>
        <strong>
          Assay Results
        </strong>
      </v-card-subtitle>
      <v-card-text v-if="!loading">
        <v-data-table
          v-if="results"
          :headers="headers"
          :items="results"
          disable-pagination
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:item.source_display="{ item }">
            {{ item.source_display }}
            <br>
            <v-chip
              x-small
              :color="item.status === 'FN' ? 'success' : 'warning'"
            >
              {{ item.status_display }}
            </v-chip>
          </template>
          <template v-slot:item.date="{ item }">
            {{ item.date | formatDate }}
          </template>
          <template v-slot:item.pt_ppm="{ item }">
            <span class="pt-color">
              {{ item.pt_ppm | toFixed(3) | addCommas }}
            </span>
          </template>
          <template v-slot:item.pd_ppm="{ item }">
            <span class="pd-color">
              {{ item.pd_ppm | toFixed(3) | addCommas }}
            </span>
          </template>
          <template v-slot:item.rh_ppm="{ item }">
            <span class="rh-color">
              {{ item.rh_ppm | toFixed(3) | addCommas }}
            </span>
          </template>
          <template v-slot:item.percent_moisture="{ item }">
            {{ item.percent_moisture }}%
          </template>
          <template v-slot:item.percent_scrap="{ item }">
            {{ item.percent_scrap }}%
          </template>
          <template v-slot:item.pt_toz_estimated="{ item }">
            <span
              v-if="item.pt_toz_estimated"
              class="pt-color"
            >
              {{ item.pt_toz_estimated | toFixed(3) | addCommas }}
            </span>
          </template>
          <template v-slot:item.pd_toz_estimated="{ item }">
            <span
              v-if="item.pd_toz_estimated"
              class="pd-color"
            >
              {{ item.pd_toz_estimated | toFixed(3) | addCommas }}
            </span>
          </template>
          <template v-slot:item.rh_toz_estimated="{ item }">
            <span
              v-if="item.rh_toz_estimated"
              class="rh-color"
            >
              {{ item.rh_toz_estimated | toFixed(3) | addCommas }}
            </span>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn
              small
              icon
              @click="editResult(item)"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div v-else>
          No Results Yet
        </div>
      </v-card-text>
    </unauthorized>
    <create-assay-result-form
      v-if="lot && lot.id"
      :lot-id="lot.id"
      @created="results.push($event)"
    />
    <edit-assay-result-dialog
      v-if="assayResultToEdit"
      v-model="editDialog"
      :assay-result="assayResultToEdit"
      @updated="results = results.map(r => r.id === $event.id ? $event : r)"
    />
  </v-card>
</template>

<script>
import { fetchResultsForLot } from '@/requests/assayResult'
import CreateAssayResultForm from '@/views/Lots/components/AssayResult/CreateAssayResultDialog.vue'
import EditAssayResultDialog from '@/views/Lots/components/AssayResult/EditAssayResultDialog.vue'

export default {
  name: 'AssayResults',
  components: { EditAssayResultDialog, CreateAssayResultForm },
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    editDialog: false,
    assayResultToEdit: null,
    loading: false,
    results: [],
    showEstimated: false // switch not implemented
  }),
  computed: {
    headers () {
      let he = []
      he.push({ text: 'Origin', value: 'source_display' })
      he.push({ text: 'Date', value: 'date' })
      he.push({ text: 'Type', value: 'assay_type_display' })
      he.push({ text: 'Pt', value: 'pt_ppm', align: 'right' })
      he.push({ text: 'Pd', value: 'pd_ppm', align: 'right' })
      he.push({ text: 'Rh', value: 'rh_ppm', align: 'right' })
      if (this.showEstimated) {
        he.push({ text: 'Pd Est toz', alue: 'pd_toz_estimated', align: 'right' })
        he.push({ text: 'Rh Est toz', value: 'rh_toz_estimated', align: 'right' })
        he.push({ text: 'Pt Est toz', value: 'pt_toz_estimated', align: 'right' })
      }
      he.push({ text: 'Moisture', value: 'percent_moisture', align: 'right' })
      he.push({ text: 'Scrap', value: 'percent_scrap', align: 'right' })
      if (this.$store.getters.can('create-assay-result')) {
        he.push({ text: '', value: 'edit', align: 'right' })
      }
      return he
    }
  },
  methods: {
    editResult (result) {
      this.assayResultToEdit = result
      this.editDialog = true
    },
    async fetchResults () {
      try {
        const response = await fetchResultsForLot(this.lot.id)
        this.results = response.data
      } catch (e) {
        this.$logger.error(e.message)
        this.$store.commit('setSnackbarError', 'failed to retrieve assay results')
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchResults()
    this.loading = false
  }
}
</script>
