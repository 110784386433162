<template>
  <v-container v-if="!loading">
    <url-breadcrumbs
      v-if="lot"
      :items="[
        ['lot list', { name: 'lot-list' }],
        [`Lot #${lot.id}`, { name: 'lot' }],
        ['Shipping Labels']
      ]"
    />
    <div
      class="pagebreak"
      v-for="(line, index) in lot.inventory.inventoryline_set"
      :key="index"
    >
      <v-img
        :src="config.logo"
        :alt="config.name"
        height="100px"
        contain
      />
      <div class="text-center font-weight-bold">
        3103 W Lincoln St Phoenix, AZ 85009
      </div>
      <v-divider />
      <div><b>Lot Number:</b> {{ lotNameFilter(lot.name) }}</div>
      <div><b>Customer Reference:</b> {{ lot.smelter_lot_id }} </div>
      <div><b>Material Type:</b> {{ line.catalog ? line.catalog.name : 'None' }}</div>
      <div><b>Gross:</b> {{ line.gross | appendLbs }}</div>
      <div><b>Tare:</b> {{ line.tare | appendLbs }}</div>
      <div><b>Net:</b> {{ (line.gross - line.tare) | toFixed | appendLbs }}</div>
      <div><b>Container:</b> {{ index+1 }} of {{ lot.inventory.inventoryline_set.length }}</div>
      <div><b>Notes:</b> {{ line.notes }} </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { removeParenthesesText } from '@/helpers/utils'
import config from '@/../izzi_config'

export default {
  name: 'ShippingLabels',
  data: () => ({
    config,
    loading: true
  }),
  methods: {
    ...mapActions({
      fetchLot: 'lotStore/fetchLot'
    }),
    lotNameFilter (name) {
      return removeParenthesesText(name)
    },
    initializePage () {
      if (this.$route.params.lot) this.loading = false
      else {
        this.fetchLot(this.$route.params.id).then(() => {
          this.loading = false
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      lot: 'lotStore/lot'
    })
  },
  mounted () {
    this.initializePage()
  }
}
</script>

<style scoped>
@media print {
  .pagebreak { page-break-after: always; font-size: 35px }
}
</style>
