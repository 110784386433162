var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(" Properties ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max:400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Lot Name","value":_vm.bs.lot_name,"error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['lot_name', $event])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Yard","value":_vm.bs.yard,"items":_vm.yardChoices,"item-text":function (x) { return ((x.id) + " - " + (x.name) + " " + (x.address || '') + ", " + (x.city || '') + " " + (x.state || '')); },"return-object":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['yard', $event])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.bs.shipped_date,"label":_vm.shippedLabel,"prepend-icon":"mdi-calendar","readonly":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['shipped_date', $event])}}},on))]}}],null,true)},[_c('v-date-picker',{attrs:{"value":_vm.bs.shipped_date},on:{"input":function($event){return _vm.$emit('setProp', ['shipped_date', $event])}}})],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:2147483647|min_value:0|decimal:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Converters Processed","type":"number","value":_vm.bs.converters_processed,"error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['converters_processed', $event])}}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required|max_value:999999999|min_value:0|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mixed Catalyst Weight/Received Lbs","type":"number","value":_vm.bs.received_lbs_total,"error-messages":errors},on:{"input":function($event){return _vm.$emit('setProp', ['received_lbs_total', $event])}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }